import React, { FC, useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import KeyUpload from 'containers/Authorization/components/KeyUpload';
import { FormValues } from 'store/DigitalSign';
import { CashierContext } from 'contexts/CashierContext';
import { actionCreators } from 'store/Cashiers';
import { States } from "../Table";
import analytics from 'services/analytics';
import { AnalyticsEvents } from 'shared/constants/analytics';

const ReKeyUpload: FC = () => {
  const { onCloseModal } = useContext(CashierContext);
  const dispatch = useDispatch();
  const uploadKeyError = useSelector(state => state.cashiers.errors.uploadKey);
  const cashierState = useSelector(state => state.cashiers.cashier.state);
  const methods = useForm<FormValues>();
  const watchFiles = methods.watch('files');

  useEffect(() => {
    if (uploadKeyError) {
      methods.setError('password', { message: uploadKeyError })
    }
    dispatch(actionCreators.removeErrors());
  }, [uploadKeyError]);

  const onKeyUpload = ({ files, password }: FormValues) => {
    if (cashierState == States.active) {
      dispatch(actionCreators.editDigitalKey({ files, password }, onCloseModal));
      analytics().sendEvent(AnalyticsEvents.cashiers_cashier_edit_upload_key);
    } else {
      dispatch(actionCreators.uploadDigitalKey({ files, password }, onCloseModal));
    }
  };

  return (
    <>
      <DialogContent dividers>
        <FormProvider {...methods}>
          <KeyUpload
            isShowDescription={false}
            subTitle='Будь ласка, завантажте ключ касира.'
          />
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onCloseModal();
            analytics().sendEvent(AnalyticsEvents.cashiers_cashier_edit_upload_key_cancel);
          }}
          size='large'
          variant='contained'
          color='inherit'>
          Відмінити
        </Button>
        <Button
          onClick={methods.handleSubmit(onKeyUpload)}
          disabled={!watchFiles || !watchFiles.length}
          size='large'
          variant='contained'>
          Завантажити
        </Button>
      </DialogActions>
    </>
  );
};

export default ReKeyUpload;
