import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Button from "@mui/material/Button";
import Select, { Value } from 'components/Select';
import TabPanel from 'components/TabPanel';
import DateRangePicker from 'components/DateRangePicker';
import ProductReport from './ProductReport';
import ReceiptReport from './ReceiptReport';
import WorkShiftReport from './WorkShiftReport';
import IncomeReport from './IncomeReport';
import OperationReport from './OperationReport';
import ReturnReport from './ReturnReport';
import * as PointOfSale from 'store/PointOfSale';
import { CashRegisterState, actionCreators } from 'store/CashRegister';
import * as CompanyPartnership from 'store/CompanyPartnership';
import * as Report from 'store/Report';
import { ReactComponent as IconFirm } from 'assets/images/icon_firm.svg';
import { ReactComponent as ReceiptRegisterIcon } from 'assets/images/receipt-register.svg';
import { ReportExportApi } from 'api';
import analytics from 'services/analytics';
import { AnalyticsEvents } from 'shared/constants/analytics';

type ReduxProps = 
  Pick<PointOfSale.PointOfSaleState, 'partnerPoints'> &
  Pick<CashRegisterState, 'registers'> &
  Pick<CompanyPartnership.CompanyPartnershipState, 'partners'> &
  RouteComponentProps &
  {
    getCashRegisters: (pointOfSaleId?: number) => void,
    getReports: (data: {
      reportType: string,
      startDate?: Date,
      endDate?: Date,
      pointOfSaleId?: number,
      cashRegisterId?: number,
      partnerUid?: string,
    }) => void,
    getCompanyPartnerships: () => Promise<void>
    getCompanyPartners: (partnerCompanyUid: string) => void
  };

const reportExportApi = new ReportExportApi();

const Reports: React.FC<ReduxProps> = ({
  partnerPoints,
  registers,
  partners,
  getCashRegisters,
  getReports,
  getCompanyPartners,
  getPointsOfSaleForPartner,
  location,
  history,
}) => {
  const searchParams = new URLSearchParams(location.search);
  const [company, setCompany] = useState(searchParams.get('companyId') || '');
  const [pointOfSale, setPointOfSale] = useState(searchParams.get('pointOfSaleId') || '');
  const [cashRegister, setCashRegister] = useState(searchParams.get('cashRegisterId') || '');
  const [ready, setReady] = useState(false);
  const [[startDateValue, endDateValue], setDateRange] = useState([new Date(), new Date()]);
  const [selectedTab, setSelectedTab] = useState('product');

  useEffect(() => {
    getCompanyPartners().then(() => setReady(true));
  }, []);

  useEffect(() => {
    const [firstPartner] = partners;
    let partner = partners.find(({ companyUid }) => companyUid == company);

    if (!company) {
      partner = firstPartner;
    }

    if (ready && partner) {
      setCompany(partner.companyUid);
      getPointsOfSaleForPartner(partner.companyUid);
    }
  }, [ready, partners]);

  useEffect(() => {
    const point = partnerPoints.find(({ pointOfSaleId }) => pointOfSaleId == Number(pointOfSale));

    if (ready && point) {
      setPointOfSale(point.pointOfSaleId);
      getCashRegisters(point.pointOfSaleId);
    }
  }, [partnerPoints]);

  useEffect(() => {
    const register = registers.find(({ cashRegisterId }) => cashRegisterId == Number(cashRegister));

    if (ready && register) {
      setQueryParams({ cashRegisterId: register.cashRegisterId });
      setCashRegister(register.cashRegisterId);
      getData({ cashRegisterId: register.cashRegisterId });
    }
  }, [registers]);

  const getData = ({ cashRegisterId = Number(cashRegister), reportType = selectedTab, startDate = startDateValue, endDate = endDateValue }: {
    cashRegisterId?: number,
    reportType?: string
    startDate?: Date,
    endDate?: Date,
  }) => {
    getReports({ reportType, startDate, endDate, pointOfSaleId: Number(pointOfSale), partnerUid: company, cashRegisterId });
  };

  const setQueryParams = ({ companyId = company, pointOfSaleId = pointOfSale, cashRegisterId = cashRegister }: {
    companyId?: Value, pointOfSaleId?: Value, cashRegisterId?: Value
  }) => {
    history.replace({
      search: `companyId=${companyId}&pointOfSaleId=${pointOfSaleId}&cashRegisterId=${cashRegisterId}`
    });
  };

  const handlePartnerChange = (value: Value) => {
    const partnerCompanyUid = String(value);

    setQueryParams({ companyId: value });
    setCompany(partnerCompanyUid);
    getPointsOfSaleForPartner(partnerCompanyUid);
    setPointOfSale('');
    setCashRegister('');
  };

  const handlePointOfSaleChange = (value: Value) => {
    setQueryParams({ pointOfSaleId: value });
    setPointOfSale(String(value));
    getCashRegisters(Number(value));
    setCashRegister('');
  };

  const handleCashRegisterChange = (value: Value) => {
    setQueryParams({ cashRegisterId: value });
    setCashRegister(String(value));
    getData({ cashRegisterId: value as number });
  };

  const handleTabChange = (e: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
    getData({ cashRegisterId: Number(cashRegister), reportType: newValue });
  };

  const onExport = () => {
    reportExportApi.apiReportExportGet(startDateValue, endDateValue, selectedTab, Number(pointOfSale), Number(cashRegister), company).then(({ data }) => {
      const blob = new Blob([String(data)], { type: 'text/csv;charset=utf-8;' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');

      a.href = url;
      a.download = selectedTab;
      a.click();

      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 0);
    });
    analytics().sendEvent(AnalyticsEvents.reports_on_export_csv);
  };

  return (
    <>
      <Typography variant='h4' mt={3} mb={3}>Звіти</Typography>
      <Box mb={3}>
        {!!partners.length && (
          <>
            <Typography variant='body2'>Бізнес:</Typography>
            <Box mb={.5}>
              <Select
                size='small'
                items={partners.map(({ companyName, companyUid }) => ({ value: companyUid, label: companyName }))}
                value={company}
                onChange={handlePartnerChange}
                sx={{
                  fontSize: 24
                }}
              />
            </Box>
          </>
        )}
        {!!partnerPoints.length && (
          <Select
            size='small'
            Icon={IconFirm}
            items={partnerPoints.map(({ pointOfSaleId, name }) => ({ value: pointOfSaleId, label: name }))}
            value={pointOfSale}
            onChange={handlePointOfSaleChange}
          />
        )}
        {!!registers.length && (
          <Box mt={.5}>
            <Select
              size='small'
              Icon={ReceiptRegisterIcon}
              items={registers.map(({ cashRegisterId, name }) => ({ value: cashRegisterId, label: name }))}
              value={cashRegister}
              onChange={handleCashRegisterChange}
            />
          </Box>
        )}
      </Box>
      <Box mb={3} display='flex'>
        <Tabs value={selectedTab} onChange={handleTabChange} variant='scrollable'>
          <Tab disableRipple value='product' label="Товар" />
          <Tab disableRipple value='receipt' label="Чеки" />
          <Tab disableRipple value='workShift' label="Зміни" />
          <Tab disableRipple value='income' label="Виручка" />
          <Tab disableRipple value='operation' label="Операції з касою" />
          <Tab disableRipple value='return' label="Повернення" />
        </Tabs>
        <Box ml={2} display='flex'>
          <DateRangePicker
            startDate={startDateValue}
            endDate={endDateValue}
            onClose={([startDate, endDate]) => getData({ startDate, endDate })}
            onChange={(dates) => setDateRange(dates)}
          />
          <Button onClick={onExport} sx={{ ml: 1, minWidth: 120 }} size='large'>Експорт в .CSV</Button>
        </Box>
      </Box>
      <TabPanel value={selectedTab} index='product'>
        <ProductReport />
      </TabPanel>
      <TabPanel value={selectedTab} index='receipt'>
        <ReceiptReport />
      </TabPanel>
      <TabPanel value={selectedTab} index='workShift'>
        <WorkShiftReport />
      </TabPanel>
      <TabPanel value={selectedTab} index='income'>
        <IncomeReport />
      </TabPanel>
      <TabPanel value={selectedTab} index='operation'>
        <OperationReport />
      </TabPanel>
      <TabPanel value={selectedTab} index='return'>
        <ReturnReport />
      </TabPanel>
    </>
  );
}

export default connect((state) => ({
  partnerPoints: state.pointOfSale.partnerPoints,
  registers: state.cashRegister.registers,
  partners: state.companyPartnership.partners,
}), {
  getCashRegisters: actionCreators.getCashRegisters,
  getReports: Report.actionCreators.getReports,
  getCompanyPartners: CompanyPartnership.actionCreators.getCompanyPartners,
  getPointsOfSaleForPartner: PointOfSale.actionCreators.getPointsOfSaleForPartner,
})(Reports);
