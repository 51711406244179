import React, { FC } from 'react';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

import Logo from 'assets/images/logo-white.svg';

interface FooterSection {
  title: string;
  link: string
}

const footerSections: Array<FooterSection> = [
  { title: 'Політика конфіденційності', link: '/terms-of-service' },
  { title: 'Публічна оферта', link: '/public-offer'},
  { title: 'Viber та Telegram: +380503675272', link: 'tel:+380503675272' },
  { title: 'E-mail: info@fopkasa.com', link: 'mailto:info@fopkasa.com' },
];

const Footer: FC = () => {
  return (
    <Box component='footer' sx={{ backgroundColor: ({ colors }) => colors.body }}>
      <Container sx={({ breakpoints }) => ({
          pt: 2,
          pb: 2,
          display: 'flex',
          flexDirection: 'column',
          [breakpoints.up('md')]: { alignItems: 'center', flexDirection: 'row' }
        })}
      >
        <Box component='img' width={40} height={40} mr={4} alt="Logo" src={Logo}/>
        <Box component='ul' sx={{ display: { md: 'flex' } }}>
          {footerSections.map(({ title, link }) =>
            <Box component='li' key={title} mt={{ xs: 2, md: 0 }}>
              <Link href={link} underline="none" color='white' mr={2}>
                {title}
              </Link>
            </Box>
          )}
        </Box>
        <Box component='span' ml={{ xs: 0, md: 'auto' }} mt={{ xs: 2, md: 0 }} color='white'>
          © ТзОВ &quot;ФОПкаса&quot; {new Date().getFullYear()}
        </Box>
      </Container>
    </Box>
  )
};

export default Footer;
