import { Action, Reducer } from 'redux';
import { replace, CallHistoryMethodAction } from 'connected-react-router'
import { UserApi, DigitalSignApi, AuthenticationApi } from '../api'
import { UserResponse } from '../api/models'
import { AppThunkAction } from './';
import authService from 'services/auth';
import analyticsService from 'services/analytics';

const userApi = new UserApi();
const digitalSignApi = new DigitalSignApi();
const authenticationApi = new AuthenticationApi();

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

type UserData = UserResponse & { hasDigitalSign: boolean }
export interface UserState {
  data: UserData;
  authorized: boolean;
}

export enum UserActionTypes {
  SET_USER = 'SET_USER',
  SET_AUTHORIZED = 'SET_AUTHORIZED',
  RESET_APP_STORE = 'RESET_APP_STORE',
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface SetUserAction { type: UserActionTypes.SET_USER, payload: UserData }
export interface SetAuthorizedAction { type: UserActionTypes.SET_AUTHORIZED, payload: boolean }
export interface ResetAppStoreAction { type: UserActionTypes.RESET_APP_STORE }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = SetUserAction | SetAuthorizedAction | CallHistoryMethodAction | ResetAppStoreAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    setUser: (payload: UserData): SetUserAction => ({ type: UserActionTypes.SET_USER, payload }),
    setAuthorized: (payload: boolean): SetAuthorizedAction => ({ type: UserActionTypes.SET_AUTHORIZED, payload }),
    resetAppStore: (): ResetAppStoreAction => ({ type: UserActionTypes.RESET_APP_STORE }),
    getUser: (): AppThunkAction<KnownAction> => async (dispatch) => {
      const [{ data }, { data: digitalSignActive }] = await Promise.all([
        userApi.apiUserGet(),
        digitalSignApi.apiDigitalSignActiveGet(),
      ]);

      dispatch(actionCreators.setUser({ ...data, hasDigitalSign: !!digitalSignActive }));
    },
    getActiveDigitalSign: (): AppThunkAction<KnownAction> => async (dispatch) => {
      const { data } = await digitalSignApi.apiDigitalSignActiveGet();

      dispatch(actionCreators.setUser({ hasDigitalSign: !!data }));
    },
    logout: (): AppThunkAction<KnownAction | AppThunkAction<KnownAction>> => async (dispatch) => {
      await authenticationApi.apiAuthenticationLogoutPost();

      dispatch(actionCreators.clearAppData('/'));
    },
    clearAppData: (pathToRedirect: string): AppThunkAction<KnownAction> => async (dispatch) => {
      authService().clearSession();
      analyticsService().removeUserId();
      dispatch(actionCreators.resetAppStore());
      dispatch(actionCreators.setAuthorized(false));
      dispatch(replace(pathToRedirect));
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const defaultState: UserState = { data: { hasDigitalSign: true }, authorized: !!authService().getAccessToken() };

export const reducer: Reducer<UserState> = (state: UserState = defaultState, incomingAction: Action): UserState => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case UserActionTypes.SET_USER:
          return { ...state, data: { ...state.data, ...action.payload } };
        case UserActionTypes.SET_AUTHORIZED:
          return { ...state, authorized: action.payload };
        default:
          return state;
    }
};
