import * as React from 'react';
import {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import dayjs from 'dayjs';
import { WorkShiftApi } from 'api';
import { WorkShiftReportResponse } from 'api/models';
import Loader from 'components/Loader';
import './index.scss';
import { getPriceFormat } from 'shared/functions';
import Logo from 'assets/images/logo-black.png';

interface Params {
    uid: string;
}

const receiptApi = new WorkShiftApi();

const WorkSiftReportDoc: React.FC = () => {
    const {uid} = useParams<Params>();
    const [report, setReceipt] = useState<WorkShiftReportResponse | null>(null);
    const [isReceiptLoading, setIsReceiptLoading] = useState<boolean>(false);

    useEffect(() => {
        setIsReceiptLoading(true);
        receiptApi.apiWorkShiftReportGet(uid)
            .then(response => {
                setReceipt(response.data);
                setIsReceiptLoading(false);
            })
            .catch(() => {
                setIsReceiptLoading(false);
            });
    }, [uid]);

    if (isReceiptLoading) {
        return <Loader isLoadingPage />;
    }

    if (report) {
        const {
            companyName,
            companyAddress,
            companyTin,
            pointOfSaleName,
            cashierName,
            cashRegisterFiscalNumber,
            isOffline,
            fiscalNumber,
            orderNumber,
            createdTimeUtc,        
            lastReceiptDateTime,
            lastReceiptNumber,
            cashReceiptsAmount,
            cashReceiptsCount,
            cardReceiptsAmount,
            cardReceiptsCount,
            cashReturnAmount,
            cardReturnAmount,
            cardReturnReceiptsCount,
            cashReturnReceiptsCount,
            cashOpenAmount,
            cashReplenishmentAmount,
            cashCashWithdrawalAmount,
            cashTotalAmount,
            openDateTime,
            cashRegisterNumber
        } = report;

        return (
            <div>
                <section className="receipt">
                    <div className="receipt__wrapper">
                        <p className="receipt__text">
                            {pointOfSaleName}<br/>
                            {companyName}
                        </p>
                        <p className="receipt__text">
                            {companyAddress}
                        </p>

                        {cashRegisterFiscalNumber !== null && (<p className="receipt__text">
                            ІД: {companyTin} &nbsp;&nbsp; ФН: {cashRegisterFiscalNumber}<br/>
                            Касир: {cashierName}
                        </p>
                        )}                        

                    </div>
                    <div className="receipt__section">
                        <p className="receipt__header">Z-ЗВІТ № {orderNumber} за {dayjs.utc(openDateTime).local().format('DD.MM.YYYY')}</p>          
                        {cashRegisterNumber !=0 && (
                            <p className="receipt__header">Каса № {cashRegisterNumber}</p>          
                        )}                        
                        <div className="receipt__text-wrapper">
                            <p className="receipt__text">Зміна відкрита:</p>
                            <p className="receipt__text"><span className="receipt__price">{dayjs.utc(openDateTime).local().format('DD.MM.YYYY HH:mm:ss')}</span></p>
                        </div>

                        {lastReceiptDateTime !== null && (
                        <div className="receipt__text-wrapper">
                            <p className="receipt__text">Останній чек:</p>
                            <p className="receipt__text"><span className="receipt__price">{dayjs.utc(lastReceiptDateTime).local().format('DD.MM.YYYY HH:mm:ss')}</span></p>
                        </div>
                        )}                        

                        {lastReceiptNumber != 0 && (
                        <div className="receipt__text-wrapper">
                            <p className="receipt__text">Номер останнього чеку:</p>
                            <p className="receipt__text"><span className="receipt__price">{lastReceiptNumber}</span></p>
                        </div>
                        )}                        

                        <div className="receipt__text-wrapper">
                            <p className="receipt__text">Валюта звіту:</p>
                            <p className="receipt__text"><span className="receipt__price">ГРН.</span></p>
                        </div>
                    </div>

                    <div className="receipt__section">
                        <p className="receipt__header">Реалізація</p>          
                        <div className="receipt__text-wrapper">
                            <p className="receipt__text">Кількість чеків: </p>
                            <p className="receipt__text"><span className="receipt__price">{cashReceiptsCount + cardReceiptsCount}</span></p>
                        </div>
                        <div className="receipt__text-wrapper">
                            <p className="receipt__text">Готівка:</p>
                            <p className="receipt__text"><span className="receipt__price">{getPriceFormat(cashReceiptsAmount)}</span></p>
                        </div>
                        <div className="receipt__text-wrapper">
                            <p className="receipt__text">Безготівка:</p>
                            <p className="receipt__text"><span className="receipt__price">{getPriceFormat(cardReceiptsAmount)}</span></p>
                        </div>
                        <div className="receipt__text-wrapper">
                            <p className="receipt__text">Загальна сума:</p>
                            <p className="receipt__text"><span className="receipt__price">{getPriceFormat(cashReceiptsAmount + cardReceiptsAmount)}</span></p>
                        </div>
                    </div>

                    {(cardReturnReceiptsCount != 0 || cashReturnReceiptsCount != 0) && (
                            <div className="receipt__section">
                                <p className="receipt__header">Поверненя</p>          
                                <div className="receipt__text-wrapper">
                                    <p className="receipt__text">Кількість чеків: </p>
                                    <p className="receipt__text"><span className="receipt__price">{cardReturnReceiptsCount + cashReturnReceiptsCount}</span></p>
                                </div>
                                <div className="receipt__text-wrapper">
                                    <p className="receipt__text">Готівка:</p>
                                    <p className="receipt__text"><span className="receipt__price">{getPriceFormat(cashReturnAmount)}</span></p>
                                </div>
                                <div className="receipt__text-wrapper">
                                    <p className="receipt__text">Безготівка:</p>
                                    <p className="receipt__text"><span className="receipt__price">{getPriceFormat(cardReturnAmount)}</span></p>
                                </div>
                                <div className="receipt__text-wrapper">
                                    <p className="receipt__text">Загальна сума:</p>
                                    <p className="receipt__text"><span className="receipt__price">{getPriceFormat(cashReturnAmount + cardReturnAmount)}</span></p>
                                </div>
                            </div>
                    )}

                    <div className="receipt__section">
                        <p className="receipt__header">Готівкові кошти в касі</p>          
                        <div className="receipt__text-wrapper">
                            <p className="receipt__text">Початковий залишок:</p>
                            <p className="receipt__text"><span className="receipt__price">{getPriceFormat(cashOpenAmount)}</span></p>
                        </div>
                        <div className="receipt__text-wrapper">
                            <p className="receipt__text">Службове внесення:</p>
                            <p className="receipt__text"><span className="receipt__price">{getPriceFormat(cashReplenishmentAmount)}</span></p>
                        </div>
                        <div className="receipt__text-wrapper">
                            <p className="receipt__text">Службове вилучення:</p>
                            <p className="receipt__text"><span className="receipt__price">{getPriceFormat(cashCashWithdrawalAmount)}</span></p>
                        </div>
                        <div className="receipt__text-wrapper">
                            <p className="receipt__text">Кінцевий залишок:</p>
                            <p className="receipt__text"><span className="receipt__price">{getPriceFormat(cashTotalAmount)}</span></p>
                        </div>
                    </div>

                <div className="receipt__text-wrapper">
                    <img className='receipt__logo-wrapper'
                            alt="Logo"
                            src={Logo}/>

                    <div className="receipt__wrapper-section">
                        {fiscalNumber && (
                            <>
                                <div className="receipt__text-wrapper">
                                    <p className="receipt__text">ФН:</p>
                                    <p className="receipt__text"><span className="receipt__price">{fiscalNumber}</span></p>
                                </div>
                                <div className="receipt__text-wrapper">
                                    <p className="receipt__text">Режим роботи:</p>
                                    <p className="receipt__text"><span className="receipt__price">{isOffline ? 'офлайн' : 'онлайн'}</span></p>
                                </div>
                            </>
                        )}
                        <div className="receipt__text-wrapper">
                            <p className="receipt__text">Дата&nbsp;звіту:&nbsp;</p>
                            <p className="receipt__text"><span className="receipt__price">{dayjs.utc(createdTimeUtc).local().format('DD.MM.YYYY HH:mm:ss')}</span></p>
                        </div>
                    </div>
                </div>
                <div className="receipt__wrapper">
                    {fiscalNumber && (
                        <p className="receipt__text">ФІСКАЛЬНИЙ ЗВІТНІЙ ЧЕК</p>
                    )}
                </div>
                </section>
            </div>
        );
    }

    return null;
};

export default WorkSiftReportDoc;
