import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { alpha } from '@mui/material/styles';
import { styled } from '@mui/material/styles';

export const TariffContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  marginBottom: '16px',
  overflow: 'overlay',
  borderRadius: '16px',
  border: `2px solid ${theme.palette.common.white}`,
  '&:hover': {
    boxShadow: `${alpha('rgba(95, 98, 115, 0.32)', 0.25)} 0px 8px 16px`,
    transition: theme.transitions.create([
      'border-color',
      'box-shadow',
    ]),
  },
}));

export const TariffTitleContainer = styled(Box)(() => ({
  padding: '24px 32px',
}));

export const TariffFeatureContainer = styled(Box)(({ theme }) => ({
  padding: '24px 32px',
  background: theme.colors.lightBlue,
  display: 'flex',
}));

export const TariffOpportunitiesContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  justifyContent: 'space-between',
  flexDirection: 'column',
  padding: '24px 32px',
  background: theme.palette.common.white,
}));

export const TariffButton = styled(Button)(() => ({
  marginTop: '38px',
  padding: '16px',
  fontSize: '16px',
  fontWeight: 300
}));
