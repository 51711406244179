import React, { FC } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import TextField  from '@mui/material/TextField';
import { TextInputProps } from './types';

const FormInput: FC<TextInputProps> = props => {
  const {
    name, rules, defaultValue = '', ...inputProps
  } = props;

  const formContext = useFormContext();
  const {
    control,
    formState: {
      errors: {
        [name]: { message = '' } = {}
      } = {}
    }
  } = formContext;

  const { field } = useController({
    name, control, rules, defaultValue,
  });

  return (
    <TextField
      {...inputProps}
      error={!!message}
      onChange={field.onChange}
      value={field.value}
      helperText={message}
    />
  );
};

export default FormInput;
