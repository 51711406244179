import * as React from 'react';
import { Switch, Route as DefaultRoute, RouteProps, Redirect } from 'react-router';

import DefaultLayout from 'containers/Layout';
import AuthorizationLayout from 'containers/AuthorizationLayout';

import Home from 'containers/Home';
import Receipt from 'containers/Receipt';
import Applications from 'containers/Applications'

import Reports from 'containers/Reports'
import BusinessStructure from 'containers/BusinessStructure'
import Cashiers from 'containers/Cashiers'
import Products from 'containers/Products'
import Channels from 'containers/Channels'

import PhoneNumber from 'containers/Authorization/Login/PhoneNumber';
import Verification from 'containers/Authorization/Login/Verification';
import Registration from 'containers/Authorization/Registration';

import PrivacyPolicy from 'containers/PrivacyPolicy';
import TermsOfService from 'containers/TermsOfService';

import InstructionForRegistration from 'containers/Instruction';

import Subscription from 'containers/Subscription';
import WorkSiftReportDoc from 'containers/WorkSiftReportDoc';

import Settings from 'containers/Settings';

import Blog from 'containers/Blog';
import BlogSingle from 'containers/Blog/BlogSingle';

import NotFound from 'components/NotFound';

import authService from 'services/auth';
import useAnalytics from 'hooks/useAnalytics';

type CustomRouteProps = RouteProps & { layout?: RouteProps['component'], isPrivate?: boolean, restricted?: boolean };

const Route: React.FC<CustomRouteProps> = (props) => {
  useAnalytics();

  const {
    component,
    layout,
    isPrivate,
    restricted,
    ...rest
  } = props;
  const token = authService().getAccessToken();

  if (isPrivate && !token) {
    return <Redirect to="/login" />;
  }

  if (restricted && token) {
    return <Redirect to="/" />;
  }

  if (layout && component) {
    return (
      <DefaultRoute
        {...rest}
        render={props => React.createElement(layout, props, React.createElement(component, props))}
      />
    )
  }

  return <DefaultRoute {...props} />
};

const Router: React.FC = () => (
  <Switch>
    <Route exact path='/' component={Home} />
    <Route exact path='/receipt/:id' component={Receipt} />
    <Route exact path='/receipt/cr/:cruid' component={Receipt} />
    <Route path='/app' component={Applications} />
    <Route path='/public-offer' component={PrivacyPolicy} layout={DefaultLayout} />
    <Route path='/terms-of-service' component={TermsOfService} layout={DefaultLayout} />
    <Route path='/instruction-for-registration-prro' component={InstructionForRegistration} layout={DefaultLayout} />
    <Route restricted path='/login' component={PhoneNumber} layout={AuthorizationLayout} />
    <Route restricted path='/verification' component={Verification} layout={AuthorizationLayout} />
    <Route restricted path='/registration' component={Registration} layout={AuthorizationLayout} />
    <Route isPrivate path='/reports' component={Reports} layout={DefaultLayout} />
    <Route isPrivate path='/business-structure' component={BusinessStructure} layout={DefaultLayout} />
    <Route isPrivate path='/cashiers' component={Cashiers} layout={DefaultLayout} />
    <Route isPrivate path='/products' component={Products} layout={DefaultLayout} />
    <Route isPrivate path='/channels' component={Channels} layout={DefaultLayout} />
    <Route isPrivate path='/settings' component={Settings} layout={DefaultLayout} />
    <Route isPrivate path='/subscription' component={Subscription} layout={DefaultLayout} />
    <Route path='/blog/article/:id' component={BlogSingle} layout={DefaultLayout} />
    <Route path='/blog' component={Blog} layout={DefaultLayout} />
    <Route exact path='/work-shift-report/:uid' component={WorkSiftReportDoc} />
    <Route component={NotFound} />
  </Switch>
);

export default Router;
