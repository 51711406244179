import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Container from '@mui/material/Container';
import Header from 'components/Header';
import Footer from 'components/Footer';
import 'assets/styles/index.scss';

import * as PointOfSale from 'store/PointOfSale';
import * as User from 'store/User';
import * as Company from 'store/Company';
import authService from 'services/auth';
import 'assets/styles/index.scss';

interface LayoutProps {
    children?: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
    const dispatch = useDispatch();
    
    useEffect(() => {
        if (authService().getAccessToken()) {
            dispatch(Company.actionCreators.getCompany());
            dispatch(PointOfSale.actionCreators.getPointsOfSale());
            dispatch(User.actionCreators.getUser());
        }
    }, []);

    return (
        <>
            <Header />
            <Container sx={{ flex: 1 }}>
                {children}
            </Container>
            <Footer />
        </>
    );
};

export default Layout;
