import * as React from 'react';
import Box from '@mui/material/Box';

interface AuthorizationLayoutProps {
    children?: React.ReactNode;
}

const styles = {
    authorization: {
        height: '100vh',
        width: '100%',
        paddingTop: '80px',
        background: 'linear-gradient(292.37deg, #FF5375 0%, #478AFF 100%)',
    },
    authorizationLayout: {
        display: 'flex',
        justifyContent: 'center',
    },
};

const AuthorizationLayout: React.FC<AuthorizationLayoutProps> = ({ children }) => (
    <Box sx={styles.authorization}>
        <Box sx={styles.authorizationLayout}>
            {children}
        </Box>
    </Box>
);

export default AuthorizationLayout;
