import React, { FC, useContext, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';

import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import CardActions from 'containers/Authorization/components/Card/CardActions';
import CardHeader from 'containers/Authorization/components/Card/CardHeader';

import { FormPhoneInput } from 'components/FormInput';

import { ReactComponent as DeleteInput } from 'assets/images/icon_delete_input.svg';

import {
    removeSpacesFormString,
} from 'shared/functions';

import { RegistrationContext, Steps } from './Registration';

import { actionCreators } from 'store/Registration';
import { SendCodeRequest } from 'api/models';
import { ApplicationState } from 'store';
import analytics from 'services/analytics';
import { AnalyticsEvents } from 'shared/constants/analytics';

type FormValues = {
    phoneNumber: string;
};

interface ReduxProps {
    getRegistrationCode: (data: SendCodeRequest, callBack: () => void) => void,
    removeErrors: () => void,
    phoneNumberError: string
}

const ConfiguredLink = ({ href, children }: { href: string, children: React.ReactNode }) => (
    <Link color={({ colors }) => colors.secondaryGrey} fontWeight={400} underline='none' href={href} target='_blank'>{children}</Link>
);

const PhoneNumber: FC<ReduxProps> = props => {
    const { phoneNumberError } = props;
    const history = useHistory();
    const { setStep } = useContext(RegistrationContext);

    const methods = useForm<FormValues>({
        mode: 'onChange'
    });

    useEffect(() => {
        if (phoneNumberError) {
            methods.setError('phoneNumber', { message: phoneNumberError })
        }
        props.removeErrors();
    }, [phoneNumberError]);

    const onSubmit = (data: FormValues) => {
        props.getRegistrationCode(
            { phone: removeSpacesFormString(data.phoneNumber) },
            () => setStep(Steps.verificationCode));
        analytics().sendEvent(AnalyticsEvents.registration_phone_verification_send_phone);
    };

    const clearValue = () => {
        methods.setValue('phoneNumber', '+380');
        methods.clearErrors();
    };

    return (
        <>
            <CardHeader title='Реєстрація'/>
            <CardContent>
                <FormProvider {...methods}>
                    <Typography
                        ml={2}
                        mb={.5}
                        color={({ colors }) => colors.secondaryGrey}
                        fontSize='0.8571428571428571rem'>
                        МОБІЛЬНИЙ НОМЕР ТЕЛЕФОНУ:
                    </Typography>
                    <FormPhoneInput
                        fullWidth
                        name='phoneNumber'
                        InputProps={{
                            endAdornment: <DeleteInput style={{ cursor: 'pointer' }} onClick={clearValue} />
                        }}
                        autoFocus={true}
                    />
                </FormProvider>
                <Typography mt={1} color={({ colors }) => colors.secondaryGrey} fontWeight='lighter'>
                    Натискаючи &#34;Продовжити&quot;, ви погоджуєтесь з умовами <ConfiguredLink href='/public-offer'>Публічної оферти</ConfiguredLink><br />та <ConfiguredLink href='/terms-of-service'>Політики конфіденційності</ConfiguredLink>
                </Typography>
            </CardContent>
            <CardActions>
                <Button
                    onClick={() => {
                        history.goBack();
                        analytics().sendEvent(AnalyticsEvents.registration_phone_verification_send_phone_go_back);
                    }}
                    size='large'
                    variant='contained'
                    color='inherit'
                >
                    Назад
                </Button>
                <Box marginLeft='auto'>
                    <Button
                        onClick={methods.handleSubmit(onSubmit)}
                        disabled={
                            !methods.getValues('phoneNumber') ||
                            methods.getValues('phoneNumber') === '+380' ||
                            !!Object.keys(methods.formState.errors).length
                        }
                        size='large'
                        variant='contained'
                    >
                        Продовжити
                    </Button>
                </Box>
            </CardActions>
        </>
    );
};

const mapStateToProps = ({ registration: { errors } }: ApplicationState) => ({
    phoneNumberError: errors.phoneNumber
});

const mapDispatchToProps = {
    getRegistrationCode: actionCreators.getRegistrationCode,
    removeErrors: actionCreators.removeErrors
};

export default connect(mapStateToProps, mapDispatchToProps)(PhoneNumber);
