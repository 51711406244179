import React, {
    FC, useState, createContext, Dispatch, SetStateAction
} from 'react';
import Card from '@mui/material/Card';

import PeKeyUpload from './PeKeyUpload';
import PhoneNumber from './PhoneNumber';
import Verification from './Verification';
import Terms from './Terms';
import CreateCompany from './CreateCompany';
import KeysData from './KeysData'

export enum Steps {
    phoneNumber,
    verificationCode,
    terms,
    uploadDocument,
    createCompany,
    keysData,
}

interface RegistrationContextInterface {
    setStep: Dispatch<SetStateAction<Steps>>
}

export const RegistrationContext = createContext<RegistrationContextInterface>({ setStep: () => null });

const Registration: FC = () => {
    const [step, setStep] = useState(Steps.phoneNumber);

    const renderStep = () => {
        switch (step) {
            case Steps.phoneNumber:
                return <PhoneNumber/>
            case Steps.verificationCode:
                return <Verification/>
            case Steps.terms:
                return <Terms/>
            case Steps.uploadDocument:
                return <PeKeyUpload/>
            case Steps.createCompany:
                return <CreateCompany/>
            case Steps.keysData:
                return <KeysData/>
        }
    }

    return (
        <Card sx={{ width: 648, margin: '0 20px' }}>
            <RegistrationContext.Provider value={{ setStep }}>
                {renderStep()}
            </RegistrationContext.Provider>
        </Card>
    );
};

export default Registration;
