import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Table from 'containers/Channels/Table';
import AddChannelModal from 'containers/Channels/AddChannelModal';
import { actionCreators } from 'store/Company';
import analytics from 'services/analytics';
import { AnalyticsEvents } from 'shared/constants/analytics';

const Channels: React.FC = () => {
  const dispatch = useDispatch();
  const [addChannelModalOpen, setAddChannelModalOpen] = useState(false);
  const { isLoading, company } = useSelector((store) => store.company);

  return (
    <>
      <AddChannelModal open={addChannelModalOpen} onClose={() => setAddChannelModalOpen(false)} />
      <Box
        display='flex'
        flexDirection='row'
        justifyContent='space-between'
        alignItems='center'
        mt={3}
        mb={2}
      >
        <Typography variant='h4' component='h1'>Telegram канали</Typography>
        <Box display='flex' alignItems='center'>
          <FormControlLabel
            disabled={isLoading}
            control={
              <Switch
                checked={company.telegramIntegrationEnabled}
                onChange={(e) => dispatch(actionCreators.updateTelegramIntegration({ enabled: e.target.checked }))}
              />
            }
            label='Увімкнути всі канали'
          />
          <Button
            size='large'
            component='span'
            onClick={() => {
              setAddChannelModalOpen(true);
              analytics().sendEvent(AnalyticsEvents.channels_add_channel);
            }}
          >
            Додати канал
          </Button>
        </Box>
      </Box>
      <Table />
    </>
  )
}

export default Channels;
