import * as React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { QRCodeSVG } from 'qrcode.react';
import dayjs from 'dayjs';
import { ReceiptApi } from 'api';
import { ReceiptResponse } from 'api/models';
import { getPriceFormat } from 'shared/functions';
import Loader from 'components/Loader';
import Logo from 'assets/images/logo-black.png';
import './index.scss';

interface Params {
  id: string;
  cruid: string
}

const receiptApi = new ReceiptApi();

const Receipt: React.FC = () => {
  const { id, cruid } = useParams<Params>();
  const [receipt, setReceipt] = useState<ReceiptResponse | null>(null);
  const [isReceiptLoading, setIsReceiptLoading] = useState<boolean>(false);


  useEffect(() => {
    (async () => {
      try {
        setIsReceiptLoading(true);
        const { data } = id ? await receiptApi.receiptGet(id) : await receiptApi.receiptCrGet(cruid);
        setReceipt(data);
      } finally {
        setIsReceiptLoading(false);
      }
    })();
  }, [id, cruid]);

  if (isReceiptLoading) {
    return <Loader isLoadingPage />;
  }

  if (receipt) {
    const {
      companyName,
      companyAddress,
      localNumber,
      receiptProducts,
      totalAmount,
      providedCashAmount,
      changeAmount,
      receiptTimeUtc,
      pointOfSaleName,
      isCashPayment,
      receiptUrl,
      discountAmount,
      discountPercentage,
      isFiscalReceipt,
      companyTin,
      cashierName,
      fiscalNumber,
      cashRegisterFiscalNumber,
      isOffline,
    } = receipt;

    return (
      <div>
        <section className="receipt">
          <div className="receipt__wrapper">
            <p className="receipt__text">
              {pointOfSaleName}<br/>
              {companyName}
            </p>
            <p className="receipt__text">
              {companyAddress}
            </p>
            {isFiscalReceipt && (
              <p className="receipt__text">
                ІД: {companyTin} &nbsp;&nbsp; ФН: {cashRegisterFiscalNumber}<br/>
                Касир: {cashierName}
              </p>
            )}
          </div>
          <div className="receipt__wrapper">
            {receiptProducts && receiptProducts.map(({ name, price, quantity, productProperties, unit }) => (
              <div key={name} className="receipt__text-wrapper">
                <p className="receipt__text">
                  {name}<br/>
                  {!!productProperties && productProperties}
                  {!!productProperties && <br/>}
                  {getPriceFormat(price)} x {unit.isDiscrete ? Number(quantity).toFixed(2) : Math.round(quantity)} {unit.symbol}. =
                </p>
                <p className="receipt__text">
                                <span className="receipt__price">
                                    {getPriceFormat(price * quantity)}
                                </span>
                </p>
              </div>
            ))}
          </div>
          <div className="receipt__wrapper">
            <div className="receipt__text-wrapper">
              <p className="receipt-text receipt__text">
                СУМА:
              </p>
              <p className="receipt-text receipt__text">
                {getPriceFormat(totalAmount)}
              </p>
            </div>
            {!!discountAmount && (
              <div className="receipt__text-wrapper">
                <p className="receipt__text">
                  {`Знижка${discountPercentage ? ` ${discountPercentage}%` : ''}, грн`}
                </p>
                <p className="receipt__text">
                  {getPriceFormat(discountAmount)}
                </p>
              </div>
            )}
            <div className="receipt__text-wrapper">
              <p className="receipt__text">
                {isCashPayment ? 'Готівка' : 'Картка'}:<br/>
                {isCashPayment ? 'Решта:' : ''}
              </p>
              <div className="receipt__price-wrapper">
                <p className="receipt__text">
                  {getPriceFormat(providedCashAmount)}<br/>
                  {isCashPayment ? getPriceFormat(changeAmount) : ''}
                </p>
              </div>
            </div>
          </div>
          <div className="receipt__wrapper">
            <div className="receipt__text-wrapper">
              <div>
                <p className="receipt__text">Без ПДВ</p>
              </div>
            </div>
          </div>
          <div className="receipt__wrapper">
            <div className="receipt__text-wrapper">
              <div>
                <p className="receipt__text">Номер чеку: {localNumber}</p>
                <p className="receipt__text">Дата: {dayjs.utc(receiptTimeUtc).local().format('DD.MM.YYYY, HH:mm:ss')}</p>
                {isFiscalReceipt && (
                  <>
                    <p className="receipt__text">Фіскальний номер чеку: {fiscalNumber}</p>
                    <p className="receipt__text">Режим роботи: {isOffline ? 'офлайн' : 'онлайн'}</p>
                  </>
                )}
              </div>
            </div>
          </div>
          <p className="receipt__text">
            {isFiscalReceipt ? 'ФІСКАЛЬНИЙ ЧЕК' : 'ТОВАРНИЙ ЧЕК'}
          </p>
          <div className="receipt__qrcode-wrapper">
            <QRCodeSVG
              value={receiptUrl}
              size={220}
              level="Q"
              imageSettings={{
                src: Logo,
                width: 80,
                height: 80,
                excavate: true
              }} />
          </div>
        </section>
      </div>
    );
  }

  return null;
};

export default Receipt;
