import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Link from '@mui/material/Link';
import Chip from '@mui/material/Chip';
import Checkbox from '@mui/material/Checkbox';
import { ReportTable, HeadCell } from 'components/Table';
import { CashierResponse } from 'api/models';
import { actionCreators } from 'store/Cashiers';

interface Props {
  onOpenModal: () => void
}

export enum States {
  active = 1,
  inactive = 2,
  new = 3,
}

const Table: FC<Props> = (props) => {
  const dispatch = useDispatch();
  const cashiers = useSelector(state => state.cashiers.cashiers);

  const columns: HeadCell[] = [
    {
      id: 'fullName',
      label: 'Ім’я касира',
      cell: (cell) => cell || '–',
    },
    {
      id: 'phone',
      label: 'Телефон касира',
      cell: (cell) => cell || '–',
    },
    {
      id: 'tin',
      label: 'ЕДПОУ',
      cell: (cell) => cell || '–',
    },
    {
      id: 'keyId',
      label: 'Ідентифікатор ключа',
      cell: (cell) => cell || '–',
    },
  
    {
      id: 'isChiefCashier',
      label: 'Старший касир',
      cell: (cell, row: CashierResponse) => (
        <Checkbox checked = {row.isChiefCashier == 1}  color='success' disabled = {true} />
      ),
    },    
    {
      id: 'state',
      label: 'Статус',
      cell: (state) => {
        switch (state) {
          case States.active:
            return <Chip label='Активний' color='success' size='small' />

          case States.inactive:
            return <Chip label='Heaктивний' size='small' />

          case States.new:
            return <Chip label='Новий' color='primary' size='small' />

          default:
            return '-'
        }
      },
    },
    {
      id: 'settings',
      label: '',
      cell: (cell, row: CashierResponse) => (
        <Link onClick={() => {
          dispatch(actionCreators.setCashier(row));
          props.onOpenModal();
        }} component='button'>
          {row.state == States.new ? 'Налаштувати' : 'Редагувати'}
        </Link>
      ),
    }
  ];

  return (
    <ReportTable
      idName='userUid'
      enablePagination={false}
      rows={cashiers}
      columns={columns}
    />
  )
};

export default Table;
