export type ReactAppConfig = {
    REACT_APP_GTM_CONTAINER_ID: string;
    REACT_APP_GTM_ENV_AUTH: string;
    REACT_APP_GTM_ENV_PREVIEW: string;
    REACT_APP_GA_ID: string;
};

const getReactAppConfig = (): ReactAppConfig => {
    const host = window.location.hostname;

    const commonConfig = {
        REACT_APP_GTM_CONTAINER_ID: "GTM-NX5LPM8"
    };

    const configDev = {
        ...commonConfig,
        REACT_APP_GTM_ENV_AUTH: 'TXiljBIH2OGpbhewewpJEA',
        REACT_APP_GTM_ENV_PREVIEW: 'env-2',
        REACT_APP_GA_ID: 'G-DTVBKQH962',
    };

    const configStg = {
        ...commonConfig,
        REACT_APP_GTM_ENV_AUTH: '23cW4OdixO7ilb82nIF6Nw',
        REACT_APP_GTM_ENV_PREVIEW: 'env-4',
        REACT_APP_GA_ID: 'G-NZ1D8TJCJZ',
    };

    const configProd = {
        ...commonConfig,
        REACT_APP_GTM_ENV_AUTH: 'hzKETM165iVV1dNEjR1nMg',
        REACT_APP_GTM_ENV_PREVIEW: 'env-1',
        REACT_APP_GA_ID: 'G-111V6D7E0Y',
    };

    // Check if the host matches the predefined list
    if (host.includes('fopkasa-web-dev.azurewebsites.net') || host.includes('localhost')) {
        return configDev;
    } else if (host.includes('fopkasa-web-stg.azurewebsites.net')) {
        return configStg;
    } else {
        return configProd;
    }
}

export default getReactAppConfig;
