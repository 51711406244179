import CardMui from '@mui/material/Card';
import { styled } from '@mui/material/styles';

export default styled(CardMui)({
    paddingTop: '48px',
    paddingLeft: '24px',
    paddingRight: '24px',
    paddingBottom: '24px',
    boxShadow: '0px 8px 16px rgba(95, 98, 115, 0.32)',
    borderRadius: '16px',
    maxWidth: 320,
});
