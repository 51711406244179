import React from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { ReportTable, HeadCell } from '../../components/Table';
import { useReportColumns } from '../../hooks';
import Link from '@mui/material/Link';

const headers: HeadCell[] = [
  {
    id: 'openDateTimeUtc',
    label: 'Дата відкриття',
    cell: (value) => dayjs.utc(value).local().format('DD/MM/YYYY HH:mm'),
  },
  {
    id: 'closeDateTimeUtc',
    label: 'Дата закриття',
    cell: (value) => dayjs.utc(value).isValid() ? dayjs.utc(value).local().format('DD/MM/YYYY HH:mm') : '-',
  },
  {
    id: 'duration',
    label: 'Кількість годин',
  },
  {
    id: 'amount',
    label: 'Сума, ₴',
    align: 'right',
  },
  {
    id: 'cashierName',
    label: 'Касир',
  },
  {
    id: 'workShiftUid',
    label: '',
    cell: (cell, row) => (
      <Link
        underline='none'
        target='_blank'
        href={`/work-shift-report/${row.workShiftUid}`}
      >
        З-звіт
      </Link>
      ),
  }
];

const WorkShiftReport: React.FC = () => {
  const rows = useSelector(state => state.reports.workShiftReport);
  const columns = useReportColumns(headers, rows);

  return (
    <ReportTable
      idName='order'
      rows={rows}
      columns={columns}
    />
  )
};

export default WorkShiftReport;
