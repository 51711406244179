import React, { FC, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';

import Menu from '@mui/material/Menu';
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem';

import { ReactComponent as MenuIcon } from 'assets/images/navigation-menu.svg'

import { actionCreators } from 'store/User';
import analytics from 'services/analytics';
import { AnalyticsEvents } from 'shared/constants/analytics';

const ResponsiveMenu: FC<RouteComponentProps> = props => {
  const [isOpenedMenu, setIsOpenedMenu] = useState( false);
  const anchorEl = useRef(null);
  const dispatch = useDispatch();
  const authorized = useSelector(state => state.user.authorized);

  const landingPage = props.location.pathname == '/';

  const renderMenuItems = () => {
    if (!landingPage && authorized) {
      return (
        <Box>
          <MenuItem onClick={() => props.history.push('/reports')}>
            Звіти
          </MenuItem>
          <MenuItem onClick={() => props.history.push('/business-structure')}>
            Мій Бізнес
          </MenuItem>
          <MenuItem onClick={() => props.history.push('/cashiers')}>
            Касири
          </MenuItem>
          <MenuItem onClick={() => props.history.push('/channels')}>
            Канали
          </MenuItem>
          <MenuItem onClick={() => props.history.push('/settings')}>
            Налаштування
          </MenuItem>
          <MenuItem onClick={() => props.history.push('/subscription')}>
            Мій тариф
          </MenuItem>
          <MenuItem
            sx={{ color: (theme) => theme.colors.secondaryRed }}
            onClick={() => dispatch(actionCreators.logout())}>
            Вийти
          </MenuItem>
        </Box>
      )
    }

    return (
      <Box>
        <MenuItem onClick={() => location.href = '#about'}>
          Що таке ФОПкаса
        </MenuItem>
        <MenuItem onClick={() => location.href = '#organization'}>
          Переваги
        </MenuItem>
        <MenuItem onClick={() => props.history.push('/blog')}>
          Блог
        </MenuItem>
        { authorized ?
          <MenuItem
            onClick={() => {props.history.push('/business-structure');                    
                     analytics().sendEvent(AnalyticsEvents.menu_login_to_myaccount);}}
          >
            Увійти в кабінет
          </MenuItem> :
          <>
            <MenuItem onClick={() => {props.history.push('/login');                    
                                      analytics().sendEvent(AnalyticsEvents.menu_login);}}>
              Увійти в кабінет
            </MenuItem>
            <MenuItem onClick={() => {props.history.push('/registration');                    
                     analytics().sendEvent(AnalyticsEvents.menu_registration);}}>
              Зареєструватися
            </MenuItem>
          </>
        }
      </Box>
    );
  };

  return (
    <>
      <MenuIcon
        ref={anchorEl}
        onClick={() => setIsOpenedMenu(true)}
      >
        Відкрити меню
      </MenuIcon>
      <Menu
        anchorEl={anchorEl.current}
        open={isOpenedMenu}
        onClick={() => setIsOpenedMenu(false)}
        onClose={() => setIsOpenedMenu(false)}
      >
        {renderMenuItems()}
      </Menu>
    </>
  );
};

export default withRouter(ResponsiveMenu);
