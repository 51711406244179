import * as React from 'react';
import Modal from '@mui/material/Modal';
import Button from '../../../../../../components/Button';
import CloseIcon from '../../../../../../assets/images/icon-close.svg';
import './index.scss';

interface CallFormModalProps {
    isOpen: boolean;
    phone: string;
    closeModal: () => void;
}

const CallModal: React.FC<CallFormModalProps> = ({isOpen, closeModal, phone}) => {
    return (
        <Modal open={isOpen} onClose={closeModal}>
            <div className="call-modal">
                <div className="call-modal__wrapper">
                    <Button
                        className="call-modal__button-close"
                        onClick={closeModal}
                    >
                        <img src={CloseIcon} alt="Close button"/>
                    </Button>
                    <h2 className="call-modal__header">Дякуємо!</h2>
                    <p className="call-modal__text">
                        Наш менеджер зателефонує Вам на номер: +{phone}
                    </p>
                    <Button
                        className="button--blue call-modal__button"
                        onClick={closeModal}
                    >
                        Супер
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default CallModal;
