import React, { FC, useContext } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from "@mui/material/Button";

import { SubscriptionContext } from "contexts/SubscriptionContext";

const CurrentTariff: FC = () => {
  const { onOpenModal } = useContext(SubscriptionContext);
  const endDate = useSelector(state => state.subscription.subscription.endDate);

  return (
    <Box alignItems='center' display='flex' flexDirection='column'>
      <Typography marginTop={2} fontSize={16}>
        Ваш поточний тариф
      </Typography>
      <Typography marginTop={1} marginBottom={1} fontSize={14}>
        Наступний платіж {dayjs.utc(endDate).local().format('DD.MM.YYYY')}
      </Typography>
      <Button
        onClick={onOpenModal}
        size='large'
        variant='contained'
        color='inherit'>
        Скасувати передплату
      </Button>
    </Box>
  );
};

export default CurrentTariff;
