import ReactGA from 'react-ga4';
import { getReactAppConfig } from 'shared/functions';

type Analytics = {
  initialize: () => void
  setUserId: (userId: string) => void
  sendEvent: (event: string) => void
  sendPageview: (path: string) => void
  removeUserId: () => void
}

const analytics = (): Analytics => {
  const config = getReactAppConfig();

  const initialize = () => ReactGA.initialize(config.REACT_APP_GA_ID);

  const removeUserId = () => ReactGA.set({ userId: null });

  const setUserId = (userId: string) => ReactGA.set({ userId });

  const sendEvent = (event: string) => ReactGA.event(event);

  const sendPageview = (path: string) => ReactGA.send({
    hitType: 'pageview',
    page: path
  });

  return {
    initialize,
    setUserId,
    removeUserId,
    sendEvent,
    sendPageview
  }
}

export default analytics;