import * as React from 'react';
import Phone from 'assets/images/phone-green.png';
import './index.scss';

const Reporting: React.FC = () => (
  <div className="reporting">
    <div className="reporting__text-wrapper">
      <h2 className="header-normal reporting__header">
        Продавайте більше з РРО в смартфоні
      </h2>
      <span className="text-normal reporting__item">
        Надсилаючи електронний чек, зберігайте контакти та формуйте базу клієнтів.
        Використовуйте її, щоб інформувати покупців про спеціальні пропозиції та розпродажі.
        Додавайте коментарі до чеків, які бачитимете лише ви, пропонуйте супровідні товари та продавайте ще більше.
      </span>
    </div>
    <img className="reporting__image" alt="Phone" src={Phone}/>
  </div>
);

export default Reporting;
