import { Action, Reducer } from 'redux';
import { SettingsApi } from 'api'
import { CreateOrUpdateWorkShiftSettingsRequestDto, WorkShiftSettingsResponseDto } from 'api/models'
import { AppThunkAction } from './';
import { ApiError } from "../types";
import { actionCreators as actionNotification, SetNotificationAction } from 'store/Notification';

const settingsApi = new SettingsApi();

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
// ----------------

export interface SettingsState {
  workShiftSettings: WorkShiftSettingsResponseDto
}

enum SettingsActionTypes {
  SET_WORK_SHIFT_SETTINGS = 'SET_WORK_SHIFT_SETTINGS',
}

// -----------------
// ACTIONS
// ----------------

export interface SetWorkShiftSettings { type: SettingsActionTypes.SET_WORK_SHIFT_SETTINGS, payload: WorkShiftSettingsResponseDto }

export type KnownAction = SetWorkShiftSettings | SetNotificationAction;

// ----------------
// ACTION CREATORS
// ----------------

export const actionCreators = {
  createOrUpdateWorkShiftSettings: (settings: CreateOrUpdateWorkShiftSettingsRequestDto): AppThunkAction<KnownAction> => async (dispatch) => {
    try {
      await settingsApi.apiSettingsWorkShiftSettingsPost(settings);
      dispatch(actionNotification.setNotification({
        message: 'Зміни до Налаштування збережено.',
        severity: 'success'
      }));

    } catch ({ response }) {
      const { data } = response as ApiError
      dispatch(actionNotification.setNotification({
        message: data.title,
        severity: 'error'
      }));
    }
  },
  setWorkShiftSettings: (payload: WorkShiftSettingsResponseDto): SetWorkShiftSettings => ({ type: SettingsActionTypes.SET_WORK_SHIFT_SETTINGS, payload }),
  getWorkShiftSettings: (): AppThunkAction<KnownAction> => async (dispatch) => {
    try {
      const { data } = await settingsApi.apiSettingsWorkShiftSettingsGet();
      dispatch(actionCreators.setWorkShiftSettings(data));
    } catch ({ response }) {
      const { data } = response as ApiError
      dispatch(actionNotification.setNotification({
        message: data.title,
        severity: 'error'
      }));
    }
  },
};

// ----------------
// REDUCER
// ----------------

const defaultState: SettingsState = { workShiftSettings: {} };

export const reducer: Reducer<SettingsState> = (state: SettingsState = defaultState, incomingAction: Action): SettingsState => {
  const action = incomingAction as KnownAction;
  switch (action.type) {
    case SettingsActionTypes.SET_WORK_SHIFT_SETTINGS:
      return {
        ...state,
        workShiftSettings: action.payload
      };
    default:
      return state;
  }
};
