import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ListItemAvatar from '@mui/material/ListItemAvatar';

import TariffFeatureItem from './TariffFeatureItem';

import {
  TariffContainer,
  TariffFeatureContainer,
  TariffOpportunitiesContainer,
  TariffTitleContainer
} from './styles';

import { ReactComponent as MarkIcon } from 'assets/images/icon_mark.svg';
import { ThemeOptions } from '@mui/material/styles';

const tariffFeatures = [
  'Формування аналітичних звітів для бізнесу.',
  'Необмежена кількість торгових точок та кас.',
  'Поділ товарів на категорії та фільтри.',
  'Зручна база товарів.',
  'Можливість залишати коментарі до чеків.',
  'Можливість автоматичного надсилання замовлень через telegram.',
  'Видача чеків, коли немає з\'єднання з серверами податкової.',
  'Професійна служба підтримки.'
];

const styles = ({ colors }: ThemeOptions) => ({
  tariffContainer: {
    minWidth: '390px',
    border: `1px solid ${colors.primaryGrey}`,
  },
  tariffFeatureContainer: {
    background: 'transparent',
    borderTop: `0.5px solid ${colors.primaryGrey}`,
    borderBottom: `0.5px solid ${colors.primaryGrey}`
  }
});

const Free: FC = () => {
  const endDate = useSelector(state => state.subscription.subscription.endDate);

  return (
    <TariffContainer sx={theme => styles(theme).tariffContainer}>
      <TariffTitleContainer sx={{
        background: 'transparent',
      }}>
        <Typography marginBottom={3} fontSize={32}>
        Знайомство
        </Typography>
        <Typography fontSize={18} fontWeight={600}>Безкоштовно перші 2 місяці</Typography>
      </TariffTitleContainer>
      <TariffFeatureContainer sx={theme => styles(theme).tariffFeatureContainer}>
        <ListItemAvatar sx={{ minWidth: '15px' }}>
          <MarkIcon/>
        </ListItemAvatar>
        <Box ml={1}>
          <Typography display='inline' fontSize={18} fontWeight={600}>
            Необмежена кількість фіскальних чеків на день.
          </Typography>
        </Box>
      </TariffFeatureContainer>
      <TariffOpportunitiesContainer sx={{ background: 'transparent' }}>
        {tariffFeatures.map((tariffFeature) => (
          <TariffFeatureItem
            tariffFeature={tariffFeature}
            key={tariffFeature}
          />
        ))}
        <Box alignItems='center' display='flex' flexDirection='column'>
          <Typography marginTop={2} fontSize={16}>
            Ваш поточний тариф
          </Typography>
          <Typography marginTop={1} marginBottom={1} fontSize={14}>
            Термін дії тарифу до {dayjs.utc(endDate).local().format('DD.MM.YYYY')}
          </Typography>
        </Box>
      </TariffOpportunitiesContainer>
    </TariffContainer>
  );
};

export default Free;