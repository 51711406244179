export const AnalyticsEvents = {
  // components //
  cp_key_upload_choose_digital_key: 'cp_key_upload_choose_digital_key',
  // homepage //
  hp_try_for_free: 'hp_try_for_free',
  hp_call_me_back: 'hp_call_me_back',
  hp_login: 'hp_login',
  hp_registration: 'hp_registration',
  menu_login_to_myaccount: 'menu_login_to_myaccount',
  menu_registration: 'menu_registration',
  menu_login: 'menu_login',
  menu_about: 'menu_about',
  menu_organization: 'menu_organization',
  // login events //
  login_phone_verification_send_phone: 'login_phone_verif_send_phone',
  login_phone_verification_redirect_to_registration: 'login_phone_verif_redirect_to_reg',
  login_code_verification_send_code: 'login_code_verif_send_code',
  login_code_verification_redirect_to_registration: 'login_code_verif_redirect_to_reg',
  // registration events //
  registration_phone_verification_send_phone: 'registr_phone_verif_send_phone',
  registration_phone_verification_send_phone_go_back: 'registr_phone_verif_send_phone_back',
  registration_code_verification_send_code: 'registr_code_verif_send_code',
  registration_code_verification_go_back: 'registr_code_verif_go_back',
  registration_terms_of_services_go_back: 'registr_terms_of_services_go_back',
  registration_terms_of_services_accept: 'registr_terms_of_services_accept',
  registration_terms_of_services_reject: 'registr_terms_of_services_reject',
  registration_key_upload: 'registr_key_upload',
  registration_key_go_back: 'registr_key_go_back',
  registration_key_upload_later: 'registr_key_upload_later',
  registration_key_data_go_back: 'registr_key_data_go_back',
  registration_key_data_register_company: 'registr_key_data_register_company',
  registration_key_data_create_company: 'registr_key_data_create_company',
  registration_company_data_go_back: 'registr_company_data_go_back',
  registration_company_data_create_company: 'registr_company_data_create_company',
  // reports events //
  reports_on_export_csv: 'reports_on_export_csv',
  // business structure events //
  business_structure_upload_key: 'bs_upload_key',
  business_structure_add_partner: 'bs_add_partner',
  business_structure_add_partner_modal_send_phone: 'bs_modal_add_partner_send_phone',
  business_structure_partner_info: 'bs_partner_info',
  business_structure_partner_info_modal_remove_partner: 'bs_partner_info_modal_remove',
  business_structure_partner_info_modal_cancel: 'bs_partner_info_modal_cancel',
  business_structure_modal_add_partner_send_phone_cancel: 'bs_modal_add_partner_send_phone_cnl',
  business_structure_modal_add_partner_code_verification: 'bs_modal_add_partner_code_verif',
  business_structure_modal_add_partner_code_verification_cancel: 'bs_modal_add_partner_code_verif_cnl',
  business_structure_welcome_modal_app_link_click: 'bs_modal_welcome_app_link_click',
  business_structure_welcome_modal_app_btn_click: 'bs_modal_welcome_app_btn_click',
  // cashiers //
  cashiers_cashier_edit_upload_key: 'cashiers_edit_upload_key',
  cashiers_cashier_edit_upload_key_cancel: 'cashiers_edit_upload_key_cnl',
  cashiers_cashier_edit_phone_verification_send_phone: 'cashiers_edit_phone_verif_send_phone',
  cashiers_cashier_edit_phone_verification_send_phone_cancel: 'cashiers_edit_phone_verif_send_phone_cnl',
  cashiers_cashier_edit_code_verification_send_code: 'cashiers_edit_code_verif_send_code',
  cashiers_cashier_edit_code_verification_send_code_cancel: 'cashiers_edit_code_verif_send_code_cnl',
  cashiers_cashier_edit_code_verification_send_code_go_back: 'cashiers_edit_code_verif_send_code_back',
  // products events //
  products_export_products: 'products_export_products',
  products_import_products: 'products_import_products',
  // channels //
  channels_add_channel: 'tgch_add_channel',
  channels_add_channel_modal_add_channel: 'tgch_add_channel_modal_add_channel',
  channels_add_channel_modal_cancel: 'tgch_add_channel_modal_cancel',
  channels_edit_channel_modal_save_channel: 'tgch_edit_channel_modal_save_channel',
  channels_edit_channel_modal_cancel: 'tgch_edit_channel_modal_cancel',
  channels_edit_channel_modal_delete_channel: 'tgch_edit_channel_modal_delete_channel',
  // subscription events //
  subscription_payment_success: 'subscr_payment_success',
  subscription_payment_fail: 'subscr_payment_fail',
  subscription_payment_plan_free: 'subscription_payment_plan_free',
  subscription_payment_plan_start: 'subscription_payment_plan_start',
  subscription_payment_plan_success: 'subscription_payment_plan_success',
  subscription_payment_plan_successful_year: 'subscription_payment_plan_successful_yr',
  // settings //
  settings_work_shift_save_btn: 'settings_work_shift_save_btn'
};
