import React from 'react';
import { History } from 'history';

import Header from 'components/Header';
import Footer from 'components/Footer';
import Main from './components/Main';
import About from './components/About';
import Description from './components/Description';
import Works from './components/Works';
import CallForm from './components/CallForm';
import Facts from "./components/Facts";
import Prices from './components/Prices/Prices';

import './index.scss';
import Container from "@mui/material/Container";

interface Props {
  history: History
}

const Home: React.FC<Props> = props => (
  <>
    <Header/>
    <div className="home__header-wrapper">
      <Container>
        <Main history={props.history}/>
      </Container>
    </div>
    <Container>
      <main className="home">
        <About/>
        <Description history={props.history}/>
        <Works/>
        <Prices/>
        <div className="home__wrapper">
          <CallForm/>
          <Facts/>
        </div>
      </main>
    </Container>
    <Footer/>
  </>
);

export default Home;
