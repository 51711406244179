import { Action, Reducer } from 'redux';
import { SubscriptionApi } from '../api'
import { SubscriptionResponse, SubscriptionPaymentRequest } from '../api/models'
import { AppThunkAction } from './';

const subscriptionApi = new SubscriptionApi();

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface SubscriptionState {
  subscription: SubscriptionResponse
}

enum SubscriptionActionTypes {
  SET_SUBSCRIPTION = 'SET_SUBSCRIPTION',
  CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION',
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface SetSubscriptionAction { type: SubscriptionActionTypes.SET_SUBSCRIPTION, payload: SubscriptionResponse }
export interface CancelSubscriptionAction { type: SubscriptionActionTypes.CANCEL_SUBSCRIPTION }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = SetSubscriptionAction | CancelSubscriptionAction ;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  setSubscription: (payload: SubscriptionResponse): SetSubscriptionAction => ({ type: SubscriptionActionTypes.SET_SUBSCRIPTION, payload }),
  getSubscription: (): AppThunkAction<KnownAction> => async (dispatch) => {
    const { data } = await subscriptionApi.apiSubscriptionGet();
    dispatch(actionCreators.setSubscription((data)));
  },
  createSubscriptionPayment: (payload: SubscriptionPaymentRequest,  callBack: (url: string) => void): AppThunkAction<KnownAction> => async () => {
    const { data: { url } } = await subscriptionApi.apiSubscriptionPaymentPost(payload);
    callBack(url || '')
  },
  cancelsSubscription: (): AppThunkAction<KnownAction> => async (dispatch) => {
    await subscriptionApi.apiSubscriptionCancelPost();
    const { data } = await subscriptionApi.apiSubscriptionGet();

    dispatch(actionCreators.setSubscription(data));
  }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const defaultState: SubscriptionState = { subscription: {} };

export const reducer: Reducer<SubscriptionState> = (state: SubscriptionState = defaultState, incomingAction: Action): SubscriptionState => {
  const action = incomingAction as KnownAction;
  switch (action.type) {
    case SubscriptionActionTypes.SET_SUBSCRIPTION:
      return { ...state, subscription: action.payload };
    default:
      return state;
  }
};
