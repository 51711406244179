import React from 'react';
import { History } from 'history';

import PhoneUpload from 'assets/images/law.png';
import Optimization from 'assets/images/optimization.png';
import Chart from 'assets/images/chart.png';

import './index.scss';

interface Props {
    history: History
}

const Description: React.FC<Props> = props => {
    return (
        <section className="description">
            <h2 className="header-normal description__header">Легко встановити та почати користуватись</h2>
            <ul className="description__list">
                <li onClick={() => props.history.push('/app')} className="description__item pointer">
                    <div className="description__image-wrapper">
                        <img className="description__image" alt="Icon" src={PhoneUpload} />
                    </div>
                    <p className="text-normal description__item-text">
                        Дотримання вимог ДПС
                    </p>
                </li>
                <div className="description__list-line" />
                <li className="description__item">
                    <div className="description__image-wrapper">
                        <img className="description__image" alt="Icon" src={Optimization} />
                    </div>
                    <p className="text-normal description__item-text">Оптимізація бізнес-процесів</p>
                </li>
                <div className="description__list-line" />
                <li className="description__item">
                    <div className="description__image-wrapper">
                        <img className="description__image" alt="Icon" src={Chart} />
                    </div>
                    <p className="text-normal description__item-text">Додаткові канали продажів</p>
                </li>
            </ul>
        </section>
    );
};

export default Description;
