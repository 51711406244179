import { Action, Reducer } from 'redux';
import { AlertProps } from '@mui/material/Alert'

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface NotificationState {
  open: boolean
  message: string
  severity: AlertProps['severity']
}

type ISetNotification = Partial<Pick<NotificationState, 'message' | 'severity'>>;

enum NotificationActionTypes {
  CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATION',
  SET_NOTIFICATION = 'SET_NOTIFICATION',
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface CloseNotificationAction { type: NotificationActionTypes.CLOSE_NOTIFICATION }
export interface SetNotificationAction { type: NotificationActionTypes.SET_NOTIFICATION, payload: ISetNotification & Pick<NotificationState, 'open'> }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = CloseNotificationAction | SetNotificationAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    closeNotification: (): CloseNotificationAction => ({ type: NotificationActionTypes.CLOSE_NOTIFICATION }),
    setNotification: (payload: ISetNotification): SetNotificationAction => ({ type: NotificationActionTypes.SET_NOTIFICATION, payload: { ...payload, open: true } }),
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const defaultState: NotificationState = { open: false, message: '', severity: 'info' };

export const reducer: Reducer<NotificationState> = (state: NotificationState = defaultState, incomingAction: Action): NotificationState => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case NotificationActionTypes.SET_NOTIFICATION:
          return { ...state, ...action.payload };
        case NotificationActionTypes.CLOSE_NOTIFICATION:
          return { ...state, open: false, severity: 'info' };
        default:
            return state;
    }
};
