import * as React from 'react';
import './index.scss';

interface Fact {
    text: string;
    href: string;
}

const factsList: Array<Fact> = [
    {
        text: 'Вимоги законодавства щодо реєстрації розрахункових операцій',
        href: 'https://zakon.rada.gov.ua/laws/show/128-20#Text'
    },
    {
        text: 'Міфи та фейки щодо фіскалізації та використання програмних РРО',
        href: 'https://eba.com.ua/mify-ta-fejky-shhodo-fiskalizatsiyi-ta-vykorystannya-programnyh-rro/?fbclid=IwAR1JHBg0EOOnbmeaaA-5Q0AnMvh5BNWIptoi_2qN-S9KPHAeu45mIN8_T3o'
    }
];

const Facts: React.FC = () => (
    <section className="facts">
        <h2 className="header-normal facts__header">Корисно знати</h2>
        <p className="text-large facts__text">
            З 1 серпня 2020 року в Україні офіційно дозволено користуватися ПРРО: програмним реєстратором розрахункових операцій або ж онлайн касовим апаратом у смартфоні.
        </p>
        <h3 className="text-normal facts__list-header">Більш детально читайте за посиланнями:</h3>
        <ul className="facts__list">
            {factsList.map(item =>
                <li className="facts__item" key={item.text}>
                    <a
                        className="text-normal facts__list-text"
                        rel="noopener noreferrer"
                        target="_blank"
                        href={item.href}
                    >
                        {item.text}
                    </a>
                </li>
            )}
        </ul>
    </section>
);

export default Facts;
