import React, { FC } from 'react';

import CardMui from './styles';

interface Props {
    children?: React.ReactNode;
}

const Card: FC<Props> = ({ children }) => {
    return (
        <CardMui>
            {children}
        </CardMui>
    );
}

export default Card;
