import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import './index.scss';

interface LoaderProps {
    isLoadingPage?: boolean;
}

const Loader: React.FC<LoaderProps> = ({isLoadingPage = false}) => (
    <div className={`loader${isLoadingPage ? ' loader--page' : ''}`}>
        <CircularProgress color='inherit' />
    </div>
);

export default Loader;
