import { createContext, Dispatch, SetStateAction } from 'react'

enum Steps {
  edit,
  uploadDocument,
  phoneNumber,
  verificationCode,
}

interface CashierContextInterface {
  setStep: Dispatch<SetStateAction<Steps>>
  onCloseModal: () => void
}

const CashierContext = createContext<CashierContextInterface>({ setStep: () => null, onCloseModal: () => null });

export {
  Steps, CashierContext
};
