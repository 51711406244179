import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { CreateTelegramChatRequest, CategoryResponse } from 'api/models'
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import { FormInput, FormAutocomplete } from 'components/FormInput';
import { actionCreators as categoryActions } from 'store/Category';
import { actionCreators as channelActions } from 'store/Channel';
import { ReactComponent as CloseIcon } from 'assets/images/icon_delete_input.svg';
import analytics from 'services/analytics';
import { AnalyticsEvents } from 'shared/constants/analytics';

interface IProps {
  open: boolean,
  onClose: () => void
}

const AddChannelModal: React.FC<IProps> = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const { categories } = useSelector(state => state.category);
  const { points } = useSelector(state => state.pointOfSale);
  const [isLoading, setIsLoading] = useState(false);
  const methods = useForm();
  const name = methods.watch('name');
  const identifier = methods.watch('identifier');
  const categoriesForm = methods.watch('categories');
  const pointOfSaleId = methods.watch('pointOfSaleId');
  const allSelected = categoriesForm && categories.length == categoriesForm.length;

  useEffect(() => {
    if (open) {
      dispatch(categoryActions.getCategories());
    }
  }, [open]);

  const onAddClick = async (body: CreateTelegramChatRequest) => {
    setIsLoading(true);
    dispatch(channelActions.addChannel(body, async (hasError) => {
      if (hasError) {
        return setIsLoading(false);
      }

      await dispatch(channelActions.getChannels());
      onClose();
      setIsLoading(false);
      methods.reset({ name: '', identifier: '', pointOfSaleId: '', categories: [] });
    }));
    analytics().sendEvent(AnalyticsEvents.channels_add_channel_modal_add_channel);
  };

  const onCancelClick = () => {
    onClose();
    methods.reset({ name: '', identifier: '', pointOfSaleId: '', categories: [] });
    analytics().sendEvent(AnalyticsEvents.channels_add_channel_modal_cancel);
  };

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>
        Додати новий Telegram канал
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 12,
          }}
        ><CloseIcon /></IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <FormProvider {...methods}>
          <Box>
            <Typography mb={.5} variant='body2'>
              НАЗВА КАНАЛУ:
            </Typography>
            <FormInput name='name' fullWidth />
          </Box>
          <Box mt={2}>
            <Typography mb={.5} variant='body2'>
              ІДЕНТИФІКАТОР КАНАЛУ:
            </Typography>
            <FormInput name='identifier' placeholder='-123456789' fullWidth />
          </Box>
          <Box mt={2}>
            <Typography mb={.5} variant='body2'>
              ТОРГОВА ТОЧКА:
            </Typography>
            <FormAutocomplete
              name='pointOfSaleId'
              placeholder='Оберіть торгову точку'
              options={points.map(({ pointOfSaleId }) => pointOfSaleId)}
              getOptionLabel={(pointOfSaleId) => {
                const option = points.find((point) => point.pointOfSaleId == pointOfSaleId)

                return option ? String(option.name) : '-'
              }}
            />
          </Box>
          <Box mt={2}>
            <Typography mb={.5} variant='body2'>
              КАТЕГОРІЇ:
            </Typography>
            <FormAutocomplete
              multiple
              disableCloseOnSelect
              name='categories'
              placeholder='Оберіть категорії'
              defaultValue={[]}
              options={categories}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.categoryId === value.categoryId}
              renderOption={(props, option, { selected }) => {
                const selectAllProps = option.categoryId == -1 ? { checked: allSelected } : {};

                return (
                  <li {...props} key={option.categoryId}>
                    <Checkbox
                      size='small'
                      checked={selected}
                      {...selectAllProps}
                    />
                    {option.name}
                  </li>
                );
              }}
              filterOptions={(options) => [{ name: 'Вибрати всі', categoryId: -1 }, ...options]}
              onChange={(event, value) => {
                if ((value as CategoryResponse[]).find(({ categoryId }) => categoryId == -1)) {
                  return methods.setValue('categories', allSelected ? [] : categories);
                }

                methods.setValue('categories', value);
              }}
            />
          </Box>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancelClick} size='large' variant='contained' color='inherit'>Відмінити</Button>
        <LoadingButton
          size='large'
          onClick={methods.handleSubmit(onAddClick)}
          disabled={!name || !identifier || !String(pointOfSaleId)}
          loading={isLoading}
        >
          Додати
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddChannelModal;
