import * as React from 'react';
import Organization from './components/Organization';
import Reporting from './components/Reporting';
import Confidentiality from './components/Confidentiality';
import './index.scss';

const Works: React.FC = () => (
    <section className="works">
        <Organization />
        <div className="works__wrapper">
            <Reporting />
            <Confidentiality />
        </div>
    </section>
);

export default Works;
