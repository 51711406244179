import React, { useEffect, useMemo, useState } from 'react';
import Alert from '@mui/lab/Alert'
import Link from '@mui/material/Link';
import Chip from '@mui/material/Chip';
import { ReportTable, HeadCell } from 'components/Table';
import { ReportApi } from 'api'
import { ReportByCurrentState } from 'api/models';
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { roundNumber } from 'shared/functions';

export enum PartnershipTypes {
  allBusiness = 0,
  partner = 1,
  ownCompany = 2,
}

interface IProps {
  pointOfSaleId: number
  companyUid: string
  partnershipType: PartnershipTypes
}

const reportApi = new ReportApi();

const Table: React.FC<IProps> = ({ pointOfSaleId, companyUid, partnershipType }) => {
  const isAllBusiness = partnershipType == PartnershipTypes.allBusiness;

  let columns: HeadCell[] = [
    {
      id: 'cashRegisterName',
      label: 'Назва каси',
      width: '15%',
    },
    {
      id: 'totalAmount',
      label: 'Виторг за сьогодні, ₴',
      width: '20%',
      align: 'right',
    },
    {
      id: 'cashAmount',
      label: 'Готівка, ₴',
      width: '10%',
      align: 'right',
    },
    {
      id: 'cardAmount',
      label: 'Картка, ₴',
      width: '10%',
      align: 'right',
    },
    {
      id: 'returnTotalAmount',
      label: 'Повернення, ₴',
      width: '15%',
      align: 'right',
    },
    {
      id: 'isClosed',
      label: 'Статус',
      width: '5%',
      cell: (isClosed) => isClosed ? <Chip label='Закрита' size='small' /> : <Chip label='Відкрита' color='success' size='small' />
    },
    {
      id: 'report',
      label: '',
      align: 'right',
      width: '5%',
      cell: (cell, row) => (
        <Link
          underline='none'
          target='_blank'
          href={`/reports?companyId=${companyUid}&pointOfSaleId=${pointOfSaleId}&cashRegisterId=${row.cashRegisterId}`}
        >
          ЗВІТ
        </Link>
      ),
    }
  ];

  if (isAllBusiness) {
    columns = [
      { id: 'partnerCompanyName', label: 'Бізнес', width: '15%' },
      { id: 'pointOfSaleName', label: 'Точка', width: '15%'},
      ...columns.slice(0, columns.length - 1)
    ];
  }

  const [error, setError] = useState('');
  const [rows, setRows] = useState<ReportByCurrentState[]>([]);

  const { totalAmount, cashAmount, returnTotalAmount, cardAmount } = useMemo(() => ({
    totalAmount: rows.length && roundNumber(rows.reduce((sum, { totalAmount = 0 }) => sum + totalAmount, 0)),
    cashAmount: rows.length && roundNumber(rows.reduce((sum, { cashAmount = 0 }) => sum + cashAmount, 0)),
    returnTotalAmount: rows.length && roundNumber(rows.reduce((sum, { returnTotalAmount = 0 }) => sum + returnTotalAmount, 0)),
    cardAmount: rows.length && roundNumber(rows.reduce((sum, { cardAmount = 0 }) => sum + cardAmount, 0)),
  }), [rows]);

  const colSpan = useMemo(() => columns.findIndex(({ id }) => id == 'totalAmount'), [columns]);

  useEffect(() => {
    reportApi.apiReportCurrentStateGet(pointOfSaleId, companyUid).then(({ data }) => {
      setRows(data);
    })
    .catch(({ response }) => {
      setError(response.data.title);
    });
  }, [pointOfSaleId]);

  if (error) {
    return (
      <Alert severity='error'>{error}</Alert>
    )
  }

  return (
    <ReportTable
      idName='order'
      enablePagination={false}
      rows={rows}
      columns={columns}
      {...(isAllBusiness ? { footer: () => (
          <TableRow>
            <TableCell colSpan={colSpan} />
            <TableCell align='right'>{totalAmount}</TableCell>
            <TableCell align='right'>{cashAmount}</TableCell>
            <TableCell align='right'>{cardAmount}</TableCell>
            <TableCell align='right'>{returnTotalAmount}</TableCell>
            <TableCell colSpan={columns.length - colSpan - 2} />
          </TableRow>
        )} : {})}
    />
  )
};

export default Table;
