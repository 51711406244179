import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import Box from '@mui/material/Box';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Typography from '@mui/material/Typography';

import TariffFeatureItem from './TariffFeatureItem';
import CurrentTariff from './CurrentTariff';

import { ReactComponent as MarkIcon } from 'assets/images/icon_mark.svg';
import { SubscriptionPaymentPlan } from 'containers/Subscription/Subscription';
import { useGetSubscriptionPlanButtonText } from 'hooks';

import {
  TariffContainer,
  TariffTitleContainer,
  TariffFeatureContainer,
  TariffOpportunitiesContainer,
  TariffButton
} from './styles';
import analytics from 'services/analytics';
import { AnalyticsEvents } from 'shared/constants/analytics';

const tariffFeatures = [
  'Налаштування автоматичного закриття зміни',
  'Формування аналітичних звітів для бізнесу.',
  'Необмежена кількість торгових точок та кас.',
  'Поділ товарів на категорії та фільтри.',
  'Зручна база товарів.',
  'Можливість залишати коментарі до чеків.',
  'Можливість автоматичного надсилання замовлень через telegram.',
  'Видача чеків, коли немає з\'єднання з серверами податкової.',
  'Професійна служба підтримки.'
];

interface IProps {
  createPayment: () => void
  isLanding: boolean
}

const Successful: FC<IProps> = props => {
  const paymentPlanId = useSelector(state => state.subscription.subscription.paymentPlanId);
  const isCancelled = useSelector(state => state.subscription.subscription.isCancelled);
  const authorized = useSelector(state => state.user.authorized);
  const history = useHistory();
  const buttonText = useGetSubscriptionPlanButtonText(props.isLanding, SubscriptionPaymentPlan.Success);
  const isCurrentPlanSelected = paymentPlanId === SubscriptionPaymentPlan.Success;

  return (
    <TariffContainer
      sx={({ colors }) => ({
          minWidth: props.isLanding ? 'none' : '390px',
          borderColor: colors.primaryBlue,
          marginLeft: { md: '24px' },
          marginRight: { md: '24px' }
        }
      )}
    >
      <TariffTitleContainer sx={({ colors }) => ({ background: colors.tariffSuccessfulGradient })}>
        <Typography
          fontSize={32}
          color={({ palette }) => palette.common.white }
        >
          Успішний
        </Typography>
        <Typography
          color={(Theme) => Theme.palette.common.white}
          display='inline'
          fontSize={32}>
          135
        </Typography>
        <Typography
          color={(Theme) => Theme.palette.common.white}
          display='inline'
          fontSize={18}
          fontWeight={600}> грн./місяць</Typography>
        { props.isLanding &&  <Typography 
            display='inline' 
            fontSize={12} 
            color={(Theme) => Theme.palette.common.white}>, безкоштовно перші 2 місяці</Typography>}

      </TariffTitleContainer>
      <TariffFeatureContainer>
        <ListItemAvatar sx={{ minWidth: '15px' }}>
          <MarkIcon/>
        </ListItemAvatar>
        <Box ml={1}>
          <Typography display='inline' fontSize={18} fontWeight={600}>
            Необмежена кількість фіскальних чеків на день.
          </Typography>
        </Box>
      </TariffFeatureContainer>
      <TariffOpportunitiesContainer>
        {tariffFeatures.map((tariffFeature) => (
          <TariffFeatureItem
            tariffFeature={tariffFeature}
            key={tariffFeature}
          />
        ))}
        {isCurrentPlanSelected && !isCancelled ?
          <CurrentTariff/> :
          <TariffButton
            onClick={() => {
              if (props.isLanding && !authorized) history.push('/registration?tariff=successful');
              else props.createPayment();
              analytics().sendEvent(AnalyticsEvents.subscription_payment_plan_success);
            }}
            fullWidth
            size='large'
            variant='contained'
          >
            {buttonText}
          </TariffButton>
        }
      </TariffOpportunitiesContainer>
    </TariffContainer>
  );
};

export default Successful;
