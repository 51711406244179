import React from 'react';
import { useSelector } from 'react-redux';
import { History } from 'history';

import Typography from '@mui/material/Typography';

import Logo from 'assets/images/logo-outlined.svg';
import Phones from 'assets/images/phones-full.png';
import Arrow from 'assets/images/arrow-right.svg';
import Button from 'components/Button';
import Rocket from 'assets/images/rocket.png';

import './index.scss';
import Box from "@mui/material/Box";
import analytics from 'services/analytics';
import { AnalyticsEvents } from 'shared/constants/analytics';

interface Props {
  history: History
}

const Main: React.FC<Props> = props => {
  const authorized = useSelector(state => state.user.authorized);

  return (
    <section className="main">
      <Box>
        <img className="main__logo" alt="Logo" src={Logo}/>
        <h1 className="main-large main__header">
          Зручний ПРРО для малого бізнесу {' '}
          <img className="main__rocket" alt="rocket" src={Rocket}/>
        </h1>
        <Typography mb={3} variant='h5' >
          Надсилайте електронні чеки, долайте паперову рутину та тримайте руку на пульсі вашого бізнесу зі смартфона
        </Typography>
        <a className="main__link">
          <Button onClick={() => {
            props.history.push(authorized ? '/business-structure' : '/registration');
            analytics().sendEvent(AnalyticsEvents.hp_try_for_free);
          }} className="main__button">
            Спробувати безкоштовно
            <img className="main__button-arrow" alt="Arrow" src={Arrow}/>
          </Button>
        </a>
      </Box>
      <div className="main__image-wrapper">
        <img className="main__image" alt="Phones" src={Phones}/>
      </div>
    </section>
  );
}

export default Main;
