import React, { FC, useMemo } from 'react';

import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';

import { ReactComponent as MarkIcon } from 'assets/images/icon_mark.svg';
import { ReactComponent as MarkIconQuadrate } from 'assets/images/icon_mark_quadrate.svg';


interface Props {
  tariffFeature: string
  isPremium?: boolean
}

const TariffFeatureItem: FC<Props> = props => {
  const { tariffFeature, isPremium } = props;

  const Icon = useMemo(() => isPremium ? MarkIconQuadrate : MarkIcon, [isPremium]);

  return (
    <ListItem sx={{ alignItems: 'flex-start' }} disablePadding>
      <ListItemAvatar sx={{ minWidth: '30px' }}>
        <Icon/>
      </ListItemAvatar>
      <ListItemText>
        {tariffFeature}
      </ListItemText>
    </ListItem>
  );
};

export default TariffFeatureItem;
