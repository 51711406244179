import React, { FC } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import Switch from '@mui/material/Switch';

import { SwitchInputProps } from './types';

const FormSwitch: FC<SwitchInputProps> = props => {
  const {
    name, rules, defaultValue = false, ...inputProps
  } = props;

  const formContext = useFormContext();
  const { control } = formContext;

  const { field } = useController({
    name, control, rules, defaultValue,
  });

  return (
    <Switch
      {...inputProps}
      checked={field.value}
      onChange={field.onChange}
    />
  );
};

export default FormSwitch;
