import React, { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TelegramChatResponse } from 'api/models'
import Link from '@mui/material/Link';
import { ReportTable, HeadCell } from 'components/Table';
import { actionCreators } from 'store/Channel';
import EditChannelModal from './EditChannelModal';

const Table: FC = () => {
  const [editChannelModalOpen, setEditChannelModalOpen] = useState(false);
  const [channel, setChannel] = useState({});
  const dispatch = useDispatch();
  const { channels } = useSelector(state => state.channel);
  const { points } = useSelector(state => state.pointOfSale);
  const columns: HeadCell[] = [
    {
      id: 'name',
      label: 'Назва каналу',
    },
    {
      id: 'pointOfSaleId',
      label: 'Торгова точка',
      cell: (cell) => {
        const option = points.find(({ pointOfSaleId }) => pointOfSaleId == cell)

        return option ? option.name : '-'
      }
    },
    {
      id: 'identifier',
      label: 'Ідентифікатор каналу',
      width: 160,
    },
    {
      id: 'settings',
      label: '',
      width: 100,
      cell: (cell, row) => (
        <Link onClick={() => {
          setChannel(row as TelegramChatResponse);
          setEditChannelModalOpen(true);
        }} component='button'>
          Редагувати
        </Link>
      ),
    }
  ];

  useEffect(() => {
    dispatch(actionCreators.getChannels());
  }, []);

  const onEditChannelModalClose = () => {
    setChannel({});
    setEditChannelModalOpen(false);
  };

  return (
    <>
      {!!Object.keys(channel).length && <EditChannelModal open={editChannelModalOpen} onClose={onEditChannelModalClose} channel={channel} />}
      <ReportTable
        idName='telegramChatId'
        rows={channels}
        columns={columns}
      />
    </>
  )
};

export default Table;
