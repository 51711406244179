import React, { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';

import Edit from './Edit';
import KeyUpload from './KeyUpload';
import PhoneNumber from './PhoneNumber';
import Verification from './Verification';
import { ReactComponent as CloseIcon } from 'assets/images/icon_delete_input.svg';
import { States } from '../Table';

import { CashierContext, Steps } from 'contexts/CashierContext'
import { actionCreators } from 'store/Cashiers';

interface Props {
  onCloseModal: () => void
  isOpenModal: boolean
}

const Cashier: FC<Props> = props => {
  const { isOpenModal, onCloseModal } = props;
  const dispatch = useDispatch();
  const cashier = useSelector(state => state.cashiers.cashier);
  const [step, setStep] = useState(Steps.phoneNumber);
  const isStateActive = cashier.state == States.active;

  useEffect(() => {
    isStateActive ? setStep(Steps.edit) : setStep(Steps.phoneNumber)
    if (!isOpenModal) {
      dispatch(actionCreators.removeCashier());
    }
  }, [isOpenModal]);

  const renderStep = () => {
    switch (step) {
      case Steps.phoneNumber:
        return <PhoneNumber/>
      case Steps.verificationCode:
        return <Verification/>
      case Steps.edit:
        return <Edit/>
      case Steps.uploadDocument:
        return <KeyUpload/>
    }
  };

  return (
    <Dialog
      open={isOpenModal}
      fullWidth
      PaperProps={{ sx: { minHeight: 420 } }}
    >
      <DialogTitle>
        {isStateActive ? 'Редагувати касира' : 'Налаштувати касира'}
        <IconButton
          aria-label='close'
          onClick={onCloseModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 12,
          }}
        ><CloseIcon /></IconButton>
      </DialogTitle>
      <CashierContext.Provider value={{ setStep, onCloseModal }}>
        {renderStep()}
      </CashierContext.Provider>
    </Dialog>
  );
};

export default Cashier;
