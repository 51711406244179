import React, { FC, useContext, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import Button from "@mui/material/Button";
import Box from '@mui/material/Box';

import { actionCreators } from 'store/CompanyPartnership';

import DialogActions from '@mui/material/DialogActions';
import { PartnerContext } from 'contexts/PartnerContext';
import DeleteButton from 'components/DeleteButton';
import analytics from 'services/analytics';
import { AnalyticsEvents } from 'shared/constants/analytics';

const Info: FC = () => {
  const emptyValue = 'Немає інформації';

  const [isDeleting, setIsDeleting] = useState(false);
  const {
    companyName,
    ownerPhone,
    companyUid: partnerCompanyUid
  } = useSelector(state => state.companyPartnership.partner);
  const dispatch = useDispatch();
  const { onCloseModal } = useContext(PartnerContext);

  const onDeletePartner = () => {
    setIsDeleting(true);
    dispatch(actionCreators.deletePartner({ partnerCompanyUid }, () => {
      setIsDeleting(false);
      onCloseModal();
    }));
    analytics().sendEvent(AnalyticsEvents.business_structure_partner_info_modal_remove_partner);
  }

  return (
    <>
      <DialogContent dividers>
        <Typography
          mb={1.7}
          color={({ colors }) => colors.secondaryGrey}
          fontSize='0.8571428571428571rem'>
          ІМ&apos;Я ПАРТНЕРА:
        </Typography>
        <Typography fontWeight={300}>{companyName || emptyValue}</Typography>
        <Typography
          mt={2}
          mb={1.7}
          color={({ colors }) => colors.secondaryGrey}
          fontSize='0.8571428571428571rem'>
          ТЕЛЕФОН ПАРТНЕРА:
        </Typography>
        <Typography fontWeight={300}>
          {ownerPhone}
        </Typography>
      </DialogContent>
      <DialogActions>
        <DeleteButton loading={isDeleting} onClick={onDeletePartner}>
          Видалити партнера
        </DeleteButton>
        <Box marginLeft='auto'>
          <Button onClick={() => {
            onCloseModal();
            analytics().sendEvent(AnalyticsEvents.business_structure_partner_info_modal_cancel);
          }} size='large' variant='contained' color='inherit'>
            Закрити
          </Button>
        </Box>
      </DialogActions>
    </>
  );
};

export default Info;
