import React, { FC, useEffect, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';

import PhoneNumber from './PhoneNumber';
import Verification from './Verification';
import Info from './Info';
import { ReactComponent as CloseIcon } from 'assets/images/icon_delete_input.svg';

import { PartnerContext, Steps } from 'contexts/PartnerContext'
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators } from 'store/CompanyPartnership';

interface Props {
  onCloseModal: () => void
  isOpenModal: boolean
}

const Cashier: FC<Props> = props => {
  const { isOpenModal, onCloseModal } = props;
  const { companyName } = useSelector(state => state.companyPartnership.partner);
  const [step, setStep] = useState(Steps.phoneNumber);
  const dispatch = useDispatch();

  useEffect(() => {
    companyName ? setStep(Steps.info) : setStep(Steps.phoneNumber)
    if (!isOpenModal) {
      dispatch(actionCreators.removePartnerFromStore());
    }
  }, [isOpenModal]);

  const renderStep = () => {
    switch (step) {
      case Steps.phoneNumber:
        return <PhoneNumber/>
      case Steps.verificationCode:
        return <Verification/>
      case Steps.info:
        return <Info/>
    }
  };

  return (
    <Dialog
      open={isOpenModal}
      fullWidth
      PaperProps={{ sx: { minHeight: 420 } }}
    >
      <DialogTitle>
        {companyName ? 'Інформація про партнера' : 'Додати нового партнера' }
        <IconButton
          aria-label='close'
          onClick={onCloseModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 12,
          }}
        ><CloseIcon /></IconButton>
      </DialogTitle>
      <PartnerContext.Provider value={{ setStep, onCloseModal }}>
        {renderStep()}
      </PartnerContext.Provider>
    </Dialog>
  );
};

export default Cashier;
