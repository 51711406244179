import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { CategoryResponse, TelegramChatResponse } from 'api/models'

import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import { FormInput, FormAutocomplete } from 'components/FormInput';
import DeleteButton from 'components/DeleteButton';
import { actionCreators as categoryActions } from 'store/Category';
import { actionCreators as channelActions } from 'store/Channel';
import { ReactComponent as CloseIcon } from 'assets/images/icon_delete_input.svg';
import analytics from 'services/analytics';
import { AnalyticsEvents } from 'shared/constants/analytics';

interface IProps {
  open: boolean,
  onClose: () => void
  channel: TelegramChatResponse | Record<string, never>
}

const EditChannelModal: React.FC<IProps> = ({ open, onClose, channel }) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const dispatch = useDispatch();
  const { categories, isLoading } = useSelector(state => state.category);
  const { points } = useSelector(state => state.pointOfSale);
  const methods = useForm();
  const name = methods.watch('name');
  const identifier = methods.watch('identifier');
  const categoriesForm = methods.watch('categories');
  const pointOfSaleId = methods.watch('pointOfSaleId');
  const formWasNotChanged = JSON.stringify({
    name: channel.name,
    identifier: channel.identifier,
    pointOfSaleId: channel.pointOfSaleId,
    categories: channel.categories,
  }) == JSON.stringify({
    name,
    identifier,
    pointOfSaleId,
    categories: categoriesForm,
  });
  const allSelected = categoriesForm && categories.length == categoriesForm.length;
  

  useEffect(() => {
    if (open) {
      dispatch(categoryActions.getCategories());
    }
  }, [open]);

  const onEditClick = async (body: Omit<TelegramChatResponse, 'telegramChatId'>) => {
    setIsUpdating(true);
    dispatch(channelActions.updateChannel({ ...body, telegramChatId: channel.telegramChatId }, async (hasError) => {
      if (hasError) {
        return setIsUpdating(false);
      }

      await dispatch(channelActions.getChannels());
      onClose();
    }));
    analytics().sendEvent(AnalyticsEvents.channels_edit_channel_modal_save_channel);
  };

  const onDeleteClick = async () => {
    setIsDeleting(true);
    dispatch(channelActions.deleteChannel({ telegramChatId: channel.telegramChatId }, async (hasError) => {
      if (hasError) {
        return setIsDeleting(false);
      }

      await dispatch(channelActions.getChannels());
      onClose();
    }));
    analytics().sendEvent(AnalyticsEvents.channels_edit_channel_modal_delete_channel);
  }

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>
        Редагувати канал: {name || channel.name}
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 12,
          }}
        ><CloseIcon /></IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <FormProvider {...methods}>
          <Box>
            <Typography mb={.5} variant='body2'>
              НАЗВА КАНАЛУ:
            </Typography>
            <FormInput name='name' defaultValue={channel.name} fullWidth />
          </Box>
          <Box mt={2}>
            <Typography mb={.5} variant='body2'>
              ІДЕНТИФІКАТОР КАНАЛУ:
            </Typography>
            <FormInput name='identifier' placeholder='-123456789' defaultValue={channel.identifier} fullWidth />
          </Box>
          <Box mt={2}>
            <Typography mb={.5} variant='body2'>
              ТОРГОВА ТОЧКА:
            </Typography>
            <FormAutocomplete
              name='pointOfSaleId'
              placeholder='Оберіть торгову точку'
              defaultValue={channel.pointOfSaleId}
              options={points.map(({ pointOfSaleId }) => pointOfSaleId)}
              getOptionLabel={(pointOfSaleId) => {
                const option = points.find((point) => point.pointOfSaleId == pointOfSaleId)

                return option ? String(option.name) : '-'
              }}
            />
          </Box>
          <Box mt={2}>
            <Typography mb={.5} variant='body2'>
              КАТЕГОРІЇ:
            </Typography>
            <FormAutocomplete
              multiple
              disableCloseOnSelect
              loading={isLoading}
              name='categories'
              placeholder='Оберіть категорії'
              defaultValue={channel.categories}
              options={categories}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.categoryId === value.categoryId}
              renderOption={(props, option, { selected }) => {                
                const selectAllProps = option.categoryId == -1 ? { checked: allSelected } : {};

                return (
                  <li {...props} key={option.categoryId}>
                    <Checkbox
                      size='small'
                      checked={selected}
                      {...selectAllProps}
                    />
                    {option.name}
                  </li>
                );
              }}
              filterOptions={(options) => [{ name: 'Вибрати всі', categoryId: -1 }, ...options]}
              onChange={(event, value) => {
                if ((value as CategoryResponse[]).find(({ categoryId }) => categoryId == -1)) {
                  return methods.setValue('categories', allSelected ? [] : categories);
                }

                methods.setValue('categories', value);
              }}
            />
          </Box>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <DeleteButton size='large' onClick={onDeleteClick} loading={isDeleting}>Видалити</DeleteButton>
        <Button onClick={() => {
          onClose();
          analytics().sendEvent(AnalyticsEvents.channels_edit_channel_modal_cancel);
        }} size='large' variant='contained' color='inherit'>Відмінити</Button>
        <LoadingButton
          size='large'
          onClick={methods.handleSubmit(onEditClick)}
          disabled={!name || !identifier || !String(pointOfSaleId) || formWasNotChanged}
          loading={isUpdating}
        >
          Зберегти
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditChannelModal;
