import { applyMiddleware, combineReducers, compose, createStore, AnyAction, Store } from 'redux';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { ApplicationState, reducers } from 'store';
import { UserActionTypes } from 'store/User';

export default function configureStore(history: History, initialState?: ApplicationState): Store<ApplicationState> {
    const middleware = [
        thunk,
        routerMiddleware(history)
    ];

    const appReducer = combineReducers({
        ...reducers,
        router: connectRouter(history)
    });

    const rootReducer = (state: ApplicationState | undefined, action: AnyAction) => {
        if (action.type == UserActionTypes.RESET_APP_STORE) {
            return appReducer(undefined, action);
        }

        return appReducer(state, action);
    };

    const enhancers = [];
    const windowIfDefined = typeof window === 'undefined' ? null : window as any; // eslint-disable-line @typescript-eslint/no-explicit-any    
    if (process.env.NODE_ENV =='development' && windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
    }

    return createStore(
        rootReducer,
        initialState,
        compose(applyMiddleware(...middleware), ...enhancers)
    );
}
