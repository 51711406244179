import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import Router from 'Router';
import { actionCreators } from 'store/Notification';

const App: React.FC = () => {
  const dispatch = useDispatch();
  const { open, message, severity } = useSelector((state) => state.notification);

  const handleClose = () => {
    dispatch(actionCreators.closeNotification());
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Router />
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={open}
          autoHideDuration={20000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>{message}</Alert>
        </Snackbar>
      </LocalizationProvider>
    </>
  );
};

export default App;
