import { Action, Reducer } from 'redux';
import { CashRegisterApi } from '../api'
import { CashRegisterResponse } from '../api/models'
import { AppThunkAction } from './';

const cashRegisterApi = new CashRegisterApi();

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface CashRegisterState {
  registers: CashRegisterResponse[];
}

enum CashRegisterActionTypes {
  SET_CASH_REGISTERS = 'SET_CASH_REGISTERS'
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface SetCashRegisterAction { type: CashRegisterActionTypes.SET_CASH_REGISTERS, payload: CashRegisterResponse[] }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = SetCashRegisterAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    setCashRegisters: (payload: CashRegisterResponse[]): SetCashRegisterAction => ({ type: CashRegisterActionTypes.SET_CASH_REGISTERS, payload }),
    getCashRegisters: (pointOfSaleId?: number): AppThunkAction<KnownAction> => async (dispatch) => {
      const { data } = await cashRegisterApi.apiCashRegisterGet(pointOfSaleId);

      dispatch(actionCreators.setCashRegisters(data));
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const defaultState: CashRegisterState = { registers: [] };

export const reducer: Reducer<CashRegisterState> = (state: CashRegisterState = defaultState, incomingAction: Action): CashRegisterState => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case CashRegisterActionTypes.SET_CASH_REGISTERS:
            return { ...state, registers: action.payload };
        default:
            return state;
    }
};
