import * as React  from 'react';
import Logo from 'assets/images/logo.png';

import './index.scss';

const About: React.FC = () => (
    <section className="about" id='about'>
        <div className="about__wrapper">
            <h2 className="header-normal about__header">
              ФОПкаса —
              це програмний РРО для підприємців в Україні
            </h2>
            <div className="about__list-wrapper">
                <h3 className="text-large about__list-header">Щоби малі ставали великими</h3>
                <span className="text-normal about__item">
                  Можливості нашого касового апарату в смартфоні не обмежуються надсиланням чеків без друку та інтеграцією з податковою.
                  Розвивайтеся в правильному напрямку, отримуючи звітність щодо всіх життєво важливих для вашого бізнесу показників.
                </span>
            </div>
        </div>
        <div className="about__logo-wrapper">
            <img className="about__logo" alt="Logo" src={Logo} />
        </div>
    </section>
);

export default About;
