import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import MuiSelect, { SelectChangeEvent } from '@mui/material/Select';
import { SxProps } from '@mui/system';
import Box from '@mui/material/Box';
import { Value } from './types';

interface IProps {
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  items: { value: Value, label: string }[]
  onChange: (value: Value) => void
  value: string,
  sx?: SxProps,
  size?: 'small' | 'medium'
}

const Select: React.FC<IProps> = ({ Icon, items, onChange, value, sx, size }) => {
  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value);
  };

  const renderMenuItems = () => (
    items.map(({ label, value }) => (
      <MenuItem key={`${label}-${value}`} value={value}>{label}</MenuItem>
    ))
  );

  return (
    <MuiSelect
      size={size || 'medium'}
      sx={{
        '.MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
        ':hover': {
          color: 'primary.main',
          bgcolor: 'common.white',
          '& .MuiOutlinedInput-notchedOutline': {
            border: (theme) => `1px solid ${theme.colors.primaryGrey}`,
            boxShadow: (theme) => `0px 4px 8px ${theme.colors.dropShadow}`,
          },
        },
        '&.Mui-focused': {
          color: 'primary.main',
          bgcolor: 'common.white',
          '& .MuiOutlinedInput-notchedOutline': {
            border: (theme) => `1px solid ${theme.colors.secondaryGrey}`,
            boxShadow: (theme) => `0px 4px 8px ${theme.colors.dropShadow}`,
            borderWidth: 1,
          },
        },
        ...sx,
      }}
      value={value}
      onChange={handleChange}
      {...Icon && items.length ? {
        renderValue: (value) => {
          const item = items[items.findIndex((item) => item.value == value)];

          if (!item) {
            return 'Нічого не вибрано';
          }

          return (
            <Box display='flex' alignItems='center'>
              <Box mr={1}>
                <Icon />
              </Box>
              {item.label}
            </Box>
          )
        }}:
        {}
      }
    >
      {renderMenuItems()}
    </MuiSelect>
  )
};

export default Select;
