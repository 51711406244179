import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const NotFount: React.FC = () => (
  <Box p={1} textAlign='center' display='flex' alignItems='center' justifyContent='center' height='100vh'>
    <Box>
      <Typography variant='h1'>404</Typography>
      <Typography>Сторінку не знайдено</Typography>
    </Box>
  </Box>
);

export default NotFount;
