import { createContext } from 'react'

interface SubscriptionContextInterface {
  onOpenModal: () => void
}

const SubscriptionContext = createContext<SubscriptionContextInterface>({ onOpenModal: () => null });

export {
  SubscriptionContext
};
