import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { FormAutocompleteProps } from './types';

function FormAutocomplete<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>({ name, rules, placeholder, defaultValue, ...restProps }: FormAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>): JSX.Element {
  const formContext = useFormContext();
  const {
     control,
     formState: {
        errors: {
           [name]: { message = '' } = {}
        } = {}
     }
  } = formContext;

  const { field } = useController({
      name, control, rules, defaultValue,
  });

  return (
    <Autocomplete
      {...restProps}
      size='small'
      value={field.value}
      onChange={(event, newValue, reason) => {
        if (restProps && restProps.onChange) {
          return restProps.onChange(event, newValue, reason)
        }

        field.onChange(newValue)
      }}
      renderInput={(params) => (
        <TextField {...params} placeholder={placeholder} error={!!message} helperText={message} />
      )}
    />
  )
}

export default FormAutocomplete;
