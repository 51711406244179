import React, { FC } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { TimeField } from '@mui/x-date-pickers/TimeField';

import { FormTimeInputProps } from './types';

const FormTimeField: FC<FormTimeInputProps> = props => {
  const {
    name, rules, defaultValue = '', ...inputProps
  } = props;

  const formContext = useFormContext();
  const {
    control,
    formState: {
      errors: {
        [name]: { message = '' } = {}
      } = {}
    }
  } = formContext;

  const { field } = useController({
    name, control, rules, defaultValue,
  });

  return (
    <TimeField
      {...inputProps}
      ampm={false}
      error={!!message}
      onChange={field.onChange}
      value={field.value}
      helperText={message}
    />
  );
};

export default FormTimeField;
