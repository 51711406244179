import { Action, Reducer } from 'redux';
import { PointOfSaleApi } from '../api'
import { PointOfSaleResponse } from '../api/models'
import { AppThunkAction } from './';

const pointOfSaleApi = new PointOfSaleApi();

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface PointOfSaleState {
  points: PointOfSaleResponse[];
  partnerPoints: PointOfSaleResponse[];
}

enum PointOfSaleActionTypes {
  SET_POINTS_OF_SALE = 'SET_POINTS_OF_SALE',
  SET_POINTS_OF_SALE_FOR_PARTNER = 'SET_POINTS_OF_SALE_FOR_PARTNER',
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface SetPointsOfSaleAction { type: PointOfSaleActionTypes.SET_POINTS_OF_SALE, payload: PointOfSaleResponse[] }
export interface SetPointsOfSaleForPartnerAction { type: PointOfSaleActionTypes.SET_POINTS_OF_SALE_FOR_PARTNER, payload: PointOfSaleResponse[] }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = SetPointsOfSaleAction | SetPointsOfSaleForPartnerAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    setPointsOfSale: (payload: PointOfSaleResponse[]): SetPointsOfSaleAction => ({ type: PointOfSaleActionTypes.SET_POINTS_OF_SALE, payload }),
    getPointsOfSale: (): AppThunkAction<KnownAction> => async (dispatch) => {
      const { data } = await pointOfSaleApi.apiPointOfSaleGet();

      dispatch(actionCreators.setPointsOfSale(data));
    },
    setPointsOfSaleForPartner: (payload: PointOfSaleResponse[]): SetPointsOfSaleForPartnerAction => ({
      type: PointOfSaleActionTypes.SET_POINTS_OF_SALE_FOR_PARTNER,
      payload
    }),
    getPointsOfSaleForPartner: (payload: string): AppThunkAction<KnownAction> => async (dispatch) => {
      const { data } = await pointOfSaleApi.apiPointOfSaleForPartnerGet(payload);

      dispatch(actionCreators.setPointsOfSaleForPartner(data));
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const defaultState: PointOfSaleState = { points: [], partnerPoints: [] };

export const reducer: Reducer<PointOfSaleState> = (state: PointOfSaleState = defaultState, incomingAction: Action): PointOfSaleState => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case PointOfSaleActionTypes.SET_POINTS_OF_SALE:
            return { ...state, points: action.payload };
        case PointOfSaleActionTypes.SET_POINTS_OF_SALE_FOR_PARTNER:
            return { ...state, partnerPoints: action.payload };
        default:
            return state;
    }
};
