import { Action, Reducer } from 'redux';
import { ApiError } from 'types';
import { ProductApi } from '../api'
import { ProductResponse } from '../api/models'
import { AppThunkAction } from './';
import { actionCreators as notificationActions, SetNotificationAction } from './Notification';

const productApi = new ProductApi();

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ProductState {
  products: ProductResponse[];
}

enum ProductActionTypes {
  SET_PRODUCTS = 'SET_PRODUCTS'
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface SetProductAction { type: ProductActionTypes.SET_PRODUCTS, payload: ProductResponse[] }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = SetProductAction | SetNotificationAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    setProducts: (payload: ProductResponse[]): SetProductAction => ({ type: ProductActionTypes.SET_PRODUCTS, payload }),
    getProducts: (): AppThunkAction<KnownAction> => async (dispatch) => {
      try {
        const { data } = await productApi.productGet();

        dispatch(actionCreators.setProducts(data));
      } catch ({ response }) {
        const { data } = response as ApiError

        dispatch(notificationActions.setNotification({ message: data.title, severity: 'error' }));
      }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const defaultState: ProductState = { products: [] };

export const reducer: Reducer<ProductState> = (state: ProductState = defaultState, incomingAction: Action): ProductState => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case ProductActionTypes.SET_PRODUCTS:
            return { ...state, products: action.payload };
        default:
            return state;
    }
};
