import React, { FC } from 'react';

import Typography from "@mui/material/Typography";
import Box from '@mui/material/Box';

import WorkShift from './WorkShift';

const Settings: FC = () => {
  return (
    <>
      <Box
        display='flex'
        flexDirection='row'
        justifyContent='space-between'
        alignItems='center'>
        <Typography variant='h4' mt={3} mb={2} component='h1'>Налаштування</Typography>
      </Box>
      <WorkShift/>
    </>
  );
}

export default Settings;
