import { useEffect, useState } from 'react';
import { HeadCell, Row } from '../components/Table';

const useReportColumns = (headers: HeadCell[], rows: Row[]): typeof headers => {
  const [columns, setColumns] = useState(headers);

  useEffect(() => {
    const headersCopy = [...headers];

    if (rows.find(({ cashRegisterName }) => cashRegisterName)) {
      headersCopy.unshift({ id: 'cashRegisterName', label: 'Каса' });
    }

    if (rows.find(({ pointOfSaleName }) => pointOfSaleName)) {
      headersCopy.unshift({ id: 'pointOfSaleName', label: 'Торгова точка' });
    }

    if (rows.find(({ partnerCompanyName }) => partnerCompanyName)) {
        headersCopy.unshift({ id: 'partnerCompanyName', label: 'Бізнес' });
    }

    setColumns(headersCopy);
  }, [rows]);

  return columns;
};

export default useReportColumns;
