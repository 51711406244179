import React, { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Chip from '@mui/material/Chip';
import { ReportTable, HeadCell } from 'components/Table';
import { actionCreators } from 'store/Product';

const Table: FC = () => {
  const dispatch = useDispatch();
  const { products } = useSelector(state => state.product);
  const columns: HeadCell[] = [
    {
      id: 'name',
      label: 'Назва',
    },
    {
      id: 'price',
      label: 'Ціна, ₴',
      width: 100,
    },
    {
      id: 'unit',
      label: 'Одиниці',
      cell: ({ symbol }) => symbol,
    },
    {
      id: 'code',
      label: 'Код',
    },
    {
      id: 'categoryName',
      label: 'Категорія',
    },
    {
      id: 'properties',
      label: 'Властивості',
      width: 500,
      cell: (properties: string[]) => properties.map((property) => (
        <Chip key={property} label={property} size='small' sx={{ mr: .5, mt: .25, mb: .25 }} />
      )),
    }
  ];

  useEffect(() => {
    dispatch(actionCreators.getProducts());
  }, []);

  return (
    <ReportTable
      idName='productUid'
      rows={products}
      columns={columns}
    />
  )
};

export default Table;
