import { styled } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";

const DeleteButton = styled(LoadingButton)(({ theme }) => ({
  backgroundColor: theme.colors.secondaryRed,
  '&:hover': {
    backgroundColor: theme.colors.backgroundLight,
    color: theme.colors.secondaryRed,
  },
  marginRight: 'auto',
}));

export default DeleteButton;
