import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { useForm, FormProvider } from "react-hook-form";

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { FormPhoneInput } from 'components/FormInput';

import Card from '../components/Card';

import { ReactComponent as LogoAuth } from 'assets/images/logo-auth.svg';
import { ReactComponent as DeleteInput } from 'assets/images/icon_delete_input.svg';

import {
    removeSpacesFormString
} from 'shared/functions';

import { SendCodeRequest } from 'api/models';

import { ApplicationState } from 'store';
import { actionCreators } from 'store/Authorization';
import analytics from 'services/analytics';
import { AnalyticsEvents } from 'shared/constants/analytics';

const styles = {
    button: {
        height: '40px',
        width: '100%',
        marginTop: '24px'
    },
    input: {
        width: '100%',
        marginTop: '16px'
    },
    deleteIcon: {
        cursor: 'pointer',
    }
};

type FormValues = {
    phoneNumber: string;
};

interface ReduxProps {
    getAuthCode: (data: SendCodeRequest, callBack: () => void) => void,
    removeErrors: () => void,
    phoneNumberError: string
}

const PhoneNumber: FC<ReduxProps> = (props) => {
    const history = useHistory();
    const methods = useForm<FormValues>({
        mode: 'onChange'
    });
    const {
        setValue,
        getValues,
        formState: {
            errors
        }
    } = methods;

    useEffect(() => {
        if (props.phoneNumberError) {
            methods.setError('phoneNumber', { message: props.phoneNumberError })
        }
        props.removeErrors();
    }, [props.phoneNumberError]);

    const onSubmit = (data: FormValues) => {
        props.getAuthCode(
            { phone: removeSpacesFormString(data.phoneNumber) },
            () => history.push('/verification'));
        analytics().sendEvent(AnalyticsEvents.login_phone_verification_send_phone);
    };

    const clearValue = () => {
        setValue('phoneNumber', '+380');
        methods.clearErrors();
    };

    return (
        <Card>
            <Box display='flex' justifyContent='center'>
                <LogoAuth />
            </Box>
            <Typography variant='h4' mt={5}>Введіть</Typography>
            <Typography variant='h4'>номер телефону:</Typography>
            <FormProvider {...methods}>
                <FormPhoneInput
                    name='phoneNumber'
                    sx={styles.input}
                    InputProps={{
                        endAdornment: <DeleteInput style={styles.deleteIcon} onClick={clearValue} />
                    }}
                    autoFocus={true}
                />
                <Button
                    onClick={methods.handleSubmit(onSubmit)}
                    disabled={
                        !getValues('phoneNumber') ||
                        getValues('phoneNumber') === '+380' ||
                        !!Object.keys(errors).length
                    }
                    sx={styles.button}
                >
                    <Typography fontSize={14}>Продовжити</Typography>
                </Button>
            </FormProvider>
            <Button
                onClick={() => {
                    analytics().sendEvent(AnalyticsEvents.login_phone_verification_redirect_to_registration);
                    history.push('/registration');
                }}
                sx={styles.button}
                variant='contained'
                color='inherit'>
                <Typography fontSize={14}>Зареєструватися</Typography>
            </Button>
        </Card>
    );
};

const mapStateToProps = ({ authorization: { errors } }: ApplicationState) => ({
    phoneNumberError: errors.phoneNumber
});

const mapDispatchToProps = {
    getAuthCode: actionCreators.getAuthCode,
    removeErrors:  actionCreators.removeErrors,
};

export default connect(mapStateToProps, mapDispatchToProps)(PhoneNumber);
