import * as React from 'react';
import {ChangeEvent, useState} from 'react';
import '../index.scss';

import getFormattedMobileNumber from "../../../shared/functions/getFormattedMobileNumber";


interface InputPhoneProps {
    className?: string;
    onChange: (value: string) => void;
}

const InputPhone: React.FC<InputPhoneProps> = ({
    className = '',
    onChange
}) => {
    const [value, setValue] = useState<string>('+380');
    const [isInvalidValue, setIsInvalidValue] = useState<boolean>(false);

    const onBlurInput = () => {
        if (value.length !== 16) {
            setIsInvalidValue(true);
        }
    };

    const onChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.replace(/[^0-9]/g, '').substring(0, 12);
        onChange(value);

        setValue(`+${getFormattedMobileNumber(value)}`);
    };

    return (
        <input
            className={`input${isInvalidValue ? ' input--invalid' : ''} ${className}`}
            type="tel"
            value={value}
            onChange={onChangeValue}
            onBlur={onBlurInput}
        />
    );
};

export default InputPhone;
