import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as CloseIcon } from 'assets/images/icon_delete_input.svg';
import KeyUpload from 'containers/Authorization/components/KeyUpload';
import { actionCreators, FormValues } from 'store/DigitalSign';
import * as User from 'store/User';
import * as PointOfSale from '../../store/PointOfSale';

interface IProps {
  open: boolean,
  onClose: () => void
}

const KeyUploadModal: React.FC<IProps> = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const uploadDigitalSignError = useSelector(state => state.digitalSign.errors.uploadDigitalSign);
  const methods = useForm();
  const watchFiles = methods.watch('files');

  useEffect(() => {
    if (uploadDigitalSignError) {
      methods.setError('password', { message: uploadDigitalSignError })
    }

    dispatch(actionCreators.removeErrors());
  }, [uploadDigitalSignError]);

  const onUploadClick = async ({ files, password }: FormValues) => {
    dispatch(actionCreators.uploadDigitalSignAndUpdateData({ files, password }, () => {
      dispatch(PointOfSale.actionCreators.getPointsOfSale());
      dispatch(User.actionCreators.getActiveDigitalSign());
      onClose();
      methods.reset({ files: null });
    }));
  };

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>
        Завантаження ключа
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 12,
          }}
        ><CloseIcon /></IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <FormProvider {...methods}>
          <KeyUpload />
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} size='large' variant='contained' color='inherit'>Відмінити</Button>
        <Button
          size='large'
          onClick={methods.handleSubmit(onUploadClick)}
          disabled={!watchFiles || !watchFiles.length}
        >
          Завантажити
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default KeyUploadModal;
