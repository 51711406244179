import { Action, Reducer } from 'redux';
import { CompanyPartnershipApi } from '../api'
import { PartnerResponseDto, SendCodeToPartnerRequestDto, AddPartnerRequestDto, DeletePartnerRequestDto } from 'api/models'
import { AppThunkAction } from './';

const companyPartnershipApi = new CompanyPartnershipApi();

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

enum ErrorFieldName {
  phoneNumber = 'phoneNumber',
  code = 'code',
}

interface ErrorPayload {
  field: ErrorFieldName,
  message: string
}

export interface CompanyPartnershipState {
  partner: PartnerResponseDto,
  partners: PartnerResponseDto[],
  errors: {
    phoneNumber: string,
    code: string,
  }
}

enum CompanyPartnershipTypes {
  SET_PARTNERS_COMPANY = 'SET_PARTNERS_COMPANY',
  SET_PARTNER_CODE_ERROR = 'SET_PARTNER_CODE_ERROR',
  SET_PARTNER_PHONE_NUMBER_ERROR = 'SET_PARTNER_PHONE_NUMBER_ERROR',
  SET_PARTNER = 'SET_PARTNER',
  REMOVE_PARTNER_ERRORS = 'REMOVE_PARTNER_ERRORS',
  REMOVE_PARTNER_FROM_STORE = 'REMOVE_PARTNER_FROM_STORE',
  REMOVE_PARTNER = 'REMOVE_PARTNER',
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

interface SetCompanyPartnersAction { type: CompanyPartnershipTypes.SET_PARTNERS_COMPANY, payload: PartnerResponseDto[] }
interface SetPartnerAction { type: CompanyPartnershipTypes.SET_PARTNER, payload: PartnerResponseDto }
interface SetPartnerCodeErrorAction { type: CompanyPartnershipTypes.SET_PARTNER_CODE_ERROR, payload: ErrorPayload }
interface SetPartnerPhoneNumberErrorAction { type: CompanyPartnershipTypes.SET_PARTNER_PHONE_NUMBER_ERROR, payload: ErrorPayload }
interface RemovePartnerRemoveErrors { type: CompanyPartnershipTypes.REMOVE_PARTNER_ERRORS }
interface RemovePartnerFromStore { type: CompanyPartnershipTypes.REMOVE_PARTNER_FROM_STORE }
interface RemovePartner { type: CompanyPartnershipTypes.REMOVE_PARTNER }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
  SetCompanyPartnersAction |
  SetPartnerAction |
  SetPartnerPhoneNumberErrorAction |
  SetPartnerCodeErrorAction |
  RemovePartnerRemoveErrors |
  RemovePartner |
  RemovePartnerFromStore;


// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  setCompanyPartners: (payload: PartnerResponseDto[]): SetCompanyPartnersAction => ({ type: CompanyPartnershipTypes.SET_PARTNERS_COMPANY, payload }),
  setPartnerPhoneNumberError: (payload: ErrorPayload): SetPartnerPhoneNumberErrorAction => ({ type: CompanyPartnershipTypes.SET_PARTNER_PHONE_NUMBER_ERROR, payload }),
  setPartnerCodeError: (payload: ErrorPayload): SetPartnerCodeErrorAction => ({ type: CompanyPartnershipTypes.SET_PARTNER_CODE_ERROR, payload }),
  setPartner: (payload: PartnerResponseDto): SetPartnerAction => ({ type: CompanyPartnershipTypes.SET_PARTNER, payload }),
  removePartnerFromStore: (): RemovePartnerFromStore => ({ type: CompanyPartnershipTypes.REMOVE_PARTNER_FROM_STORE }),
  removePartnerErrors: (): RemovePartnerRemoveErrors => ({ type: CompanyPartnershipTypes.REMOVE_PARTNER_ERRORS }),
  getCompanyPartners: (): AppThunkAction<KnownAction> => async dispatch => {
    const { data } = await companyPartnershipApi.apiCompanyPartnershipGet();

    dispatch(actionCreators.setCompanyPartners(data));
  },
  createCompanyPartnershipSendCode: (payload: SendCodeToPartnerRequestDto, callBack: () => void): AppThunkAction<KnownAction> => async dispatch => {
    try {
      await companyPartnershipApi.apiCompanyPartnershipSendCodePost(payload);
      dispatch(actionCreators.setPartner({ ownerPhone: payload.partnerPhone, companyUid: '', companyName: '', partnershipType: 0 }));

      const { data } = await companyPartnershipApi.apiCompanyPartnershipGet();
      dispatch(actionCreators.setCompanyPartners(data));

      callBack();
    } catch ({ response: { data } }) {
      dispatch(actionCreators.setPartnerPhoneNumberError({ message: data.title, field: ErrorFieldName.phoneNumber }));
    }
  },
  createCompanyPartnership: (payload: AddPartnerRequestDto, callBack: () => void): AppThunkAction<KnownAction> => async dispatch => {
    try {
      await companyPartnershipApi.apiCompanyPartnershipPost(payload);

      const { data } = await companyPartnershipApi.apiCompanyPartnershipGet();

      dispatch(actionCreators.setCompanyPartners(data));

      callBack();
    } catch ({ response: { data } }) {
      dispatch(actionCreators.setPartnerCodeError({ message: data.title, field: ErrorFieldName.code }));
    }
  },
  deletePartner: (payload: DeletePartnerRequestDto, callBack: () => void):  AppThunkAction<KnownAction> => async dispatch => {
    try {
      await companyPartnershipApi.apiCompanyPartnershipDelete(payload);

      const { data } = await companyPartnershipApi.apiCompanyPartnershipGet();

      dispatch(actionCreators.setCompanyPartners(data));

      callBack();
    } catch {
      callBack();
    }
  }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const defaultState: CompanyPartnershipState = {
  partner: {
    companyUid: '',
    companyName: '',
    ownerPhone: '',
    partnershipType: 0,
  },
  partners: [],
  errors: {
    phoneNumber: '',
    code: '',
  }
};

export const reducer: Reducer<CompanyPartnershipState> = (state: CompanyPartnershipState = defaultState, incomingAction: Action): CompanyPartnershipState => {
  const action = incomingAction as KnownAction;
  switch (action.type) {
    case CompanyPartnershipTypes.SET_PARTNERS_COMPANY:
      return { ...state, partners: action.payload };
    case CompanyPartnershipTypes.REMOVE_PARTNER_FROM_STORE:
      return { ...state, partner: defaultState.partner };
    case CompanyPartnershipTypes.SET_PARTNER:
      return { ...state, partner: action.payload}
    case CompanyPartnershipTypes.SET_PARTNER_CODE_ERROR:
    case CompanyPartnershipTypes.SET_PARTNER_PHONE_NUMBER_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.field]: action.payload.message
        }
      };
    case CompanyPartnershipTypes.REMOVE_PARTNER_ERRORS:
      return { ...state, errors: defaultState.errors };
    default:
      return state;
  }
};
