enum AuthKeys {
    ACCESS_TOKEN = 'ACCESS_TOKEN',
    REFRESH_TOKEN = 'REFRESH_TOKEN',
}

const authService = (): {
    setAccessToken: (accessToken: string) => void;
    setRefreshToken: (refreshToken: string) => void;
    clearSession: () => void;
    getAccessToken: () => string | null;
    getRefreshToken: () => string | null;
} => {
    const setAccessToken = (accessToken: string) => {
        localStorage.setItem(AuthKeys.ACCESS_TOKEN, accessToken);
    };

    const setRefreshToken =  (refreshToken: string) => {
        localStorage.setItem(AuthKeys.REFRESH_TOKEN, refreshToken);
    };

    const clearSession = () => {
        localStorage.removeItem(AuthKeys.ACCESS_TOKEN);
        localStorage.removeItem(AuthKeys.REFRESH_TOKEN);
    };

    const getAccessToken = () => localStorage.getItem(AuthKeys.ACCESS_TOKEN);

    const getRefreshToken = () => localStorage.getItem(AuthKeys.REFRESH_TOKEN);

    return {
        setAccessToken,
        setRefreshToken,
        clearSession,
        getAccessToken,
        getRefreshToken
    }
}

export default authService;
