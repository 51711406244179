import { Action, Reducer } from 'redux';
import { RegistrationApi } from 'api'
import { AppThunkAction } from './';
import {
    CheckCodeRequest, SendCodeRequest, CreateCompanyRequest
} from 'api/models';

import authService from 'services/auth';
import analyticsService from 'services/analytics';
import { actionCreators as userActionCreators, SetAuthorizedAction } from 'store/User';

const authenticationApi = new RegistrationApi();

enum ErrorFieldName {
    phoneNumber = 'phoneNumber',
    code = 'code',
}

interface ErrorPayload {
    field: ErrorFieldName,
    message: string
}

interface Token {
    accessToken: string,
    refreshToken: string,
}

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface RegistrationState {
    phoneNumber: string;
    accessToken: string,
    refreshToken: string,
    errors: {
        phoneNumber: string,
        code: string
    }
}

enum RegistrationActionTypes {
    REGISTRATION_SET_PHONE_NUMBER = 'REGISTRATION_SET_PHONE_NUMBER',
    REGISTRATION_GET_AUTH_CODE = 'REGISTRATION_GET_AUTH_CODE',
    REGISTRATION_SEND_AUTH_CODE = 'REGISTRATION_SEND_AUTH_CODE',
    REGISTRATION_CREATE_COMPANY = 'REGISTRATION_CREATE_COMPANY',
    REGISTRATION_REGISTER = 'REGISTRATION_REGISTER',
    REGISTRATION_SET_PHONE_NUMBER_ERROR = 'REGISTRATION_SET_PHONE_NUMBER_ERROR',
    REGISTRATION_SET_CODE_ERROR = 'REGISTRATION_SET_CODE_ERROR',
    REGISTRATION_REMOVE_ERRORS = 'REGISTRATION_REMOVE_ERRORS',
    REGISTRATION_SET_TOKEN = 'REGISTRATION_SET_TOKEN'
}

// -----------------
// ACTIONS - These are serializable (hence repayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface RegistrationSetPhoneNumberAction { type: RegistrationActionTypes.REGISTRATION_SET_PHONE_NUMBER, payload: SendCodeRequest }
export interface RegistrationGetRegistrationCodeAction { type: RegistrationActionTypes.REGISTRATION_GET_AUTH_CODE, payload: SendCodeRequest, callBack: () => void }
export interface RegistrationSendAuthCodeAction { type: RegistrationActionTypes.REGISTRATION_SEND_AUTH_CODE, payload: CheckCodeRequest, callBack: () => void }
export interface RegistrationCreateCompanyAction { type: RegistrationActionTypes.REGISTRATION_CREATE_COMPANY, payload: CreateCompanyRequest, callBack: () => void }
export interface RegistrationRegisterAction { type: RegistrationActionTypes.REGISTRATION_REGISTER, callBack: () => void }
export interface RegistrationSetPhoneNumberErrorAction { type: RegistrationActionTypes.REGISTRATION_SET_PHONE_NUMBER_ERROR, payload: ErrorPayload }
export interface RegistrationSetCodeErrorAction { type: RegistrationActionTypes.REGISTRATION_SET_CODE_ERROR, payload: ErrorPayload }
export interface RegistrationRemoveErrors { type: RegistrationActionTypes.REGISTRATION_REMOVE_ERRORS }
export interface RegistrationSetTokenAction { type: RegistrationActionTypes.REGISTRATION_SET_TOKEN, payload: Token }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
    RegistrationSetPhoneNumberAction |
    RegistrationGetRegistrationCodeAction |
    RegistrationSendAuthCodeAction |
    RegistrationCreateCompanyAction |
    RegistrationRegisterAction |
    RegistrationSetPhoneNumberErrorAction |
    RegistrationSetCodeErrorAction |
    RegistrationRemoveErrors |
    SetAuthorizedAction |
    RegistrationSetTokenAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    setPhoneNumber: (payload: SendCodeRequest): RegistrationSetPhoneNumberAction => ({ type: RegistrationActionTypes.REGISTRATION_SET_PHONE_NUMBER, payload }),
    setToken: (payload: Token): RegistrationSetTokenAction => ({ type: RegistrationActionTypes.REGISTRATION_SET_TOKEN, payload }),
    sendAuthCode: (payload: CheckCodeRequest, callBack: () => void): AppThunkAction<KnownAction> => async (dispatch) => {
        try {
            const { data: { accessToken, refreshToken, userUid = '' } } = await authenticationApi.apiRegistrationCheckCodePost(payload);

            dispatch(actionCreators.setToken({ accessToken, refreshToken }));
            analyticsService().setUserId(userUid)
            callBack();
        } catch ({ response: { data } }) {
            dispatch(actionCreators.setCodeError({ message: data.title, field: ErrorFieldName.code }));
        }
    },
    signUp: (payload: CheckCodeRequest, callBack: () => void): AppThunkAction<KnownAction> => async (dispatch) => {
        try {
            const { data: { accessToken, refreshToken, userUid = '' } } = await authenticationApi.apiRegistrationSignupPost(payload);

            authService().setAccessToken(accessToken);
            authService().setRefreshToken(refreshToken);
            analyticsService().setUserId(userUid);
            dispatch(userActionCreators.setAuthorized(true));
            callBack();
        } catch ({ response: { data } }) {
            dispatch(actionCreators.setCodeError({ message: data.title, field: ErrorFieldName.code }));
        }
    },
    getRegistrationCode: (payload: SendCodeRequest, callBack: () => void): AppThunkAction<KnownAction> => async (dispatch) => {
        try {
            await authenticationApi.apiRegistrationSendCodePost(payload);
            dispatch(actionCreators.setPhoneNumber(payload));
            callBack();
        } catch ({ response: { data } }) {
            dispatch(actionCreators.setPhoneNumberError({ message: data.title, field: ErrorFieldName.phoneNumber }));
        }
    },
    createCompany: (payload: CreateCompanyRequest, callBack: () => void): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        try {
            const { accessToken, refreshToken } = getState().registration;

            authService().setAccessToken(accessToken);
            authService().setRefreshToken(refreshToken);

            await authenticationApi.apiRegistrationCreatePost(payload);

            dispatch(userActionCreators.setAuthorized(true));
            callBack();
        } catch ({ response: { data } }) {
            authService().clearSession();
        }
    },
    register: (callBack: () => void): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        try {
            const { accessToken, refreshToken } = getState().registration;

            authService().setAccessToken(accessToken);
            authService().setRefreshToken(refreshToken);

            await authenticationApi.apiRegistrationRegisterPost();

            dispatch(userActionCreators.setAuthorized(true));
            callBack();
        } catch ({ response: { data } }) {
            authService().clearSession();
        }
    },
    setPhoneNumberError: (payload: ErrorPayload): RegistrationSetPhoneNumberErrorAction => ({ type: RegistrationActionTypes.REGISTRATION_SET_PHONE_NUMBER_ERROR, payload }),
    setCodeError: (payload: ErrorPayload): RegistrationSetCodeErrorAction => ({ type: RegistrationActionTypes.REGISTRATION_SET_CODE_ERROR, payload }),
    removeErrors: (): RegistrationRemoveErrors => ({ type: RegistrationActionTypes.REGISTRATION_REMOVE_ERRORS })
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const defaultState: RegistrationState = {
    phoneNumber: '',
    accessToken: '',
    refreshToken: '',
    errors: {
        phoneNumber: '',
        code: ''
    }
};

export const reducer: Reducer<RegistrationState> = (state: RegistrationState = defaultState, incomingAction: Action): RegistrationState => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case RegistrationActionTypes.REGISTRATION_SET_PHONE_NUMBER:
            return { ...state, phoneNumber: action.payload.phone };
        case RegistrationActionTypes.REGISTRATION_SET_TOKEN:
            return {
                ...state,
                accessToken: action.payload.accessToken,
                refreshToken: action.payload.refreshToken,
            }
        case RegistrationActionTypes.REGISTRATION_SET_CODE_ERROR:
        case RegistrationActionTypes.REGISTRATION_SET_PHONE_NUMBER_ERROR:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [action.payload.field]: action.payload.message
                }
            };
        case RegistrationActionTypes.REGISTRATION_REMOVE_ERRORS:
            return { ...state, errors: defaultState.errors };
        default:
            return state;
    }
};
