import React, { FC } from 'react';
import { useSelector } from "react-redux";

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import DialogContent from "@mui/material/DialogContent";

import { ReactComponent as CloseIcon } from 'assets/images/icon_delete_input.svg';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';

interface Props {
  onCloseModal: () => void
  isOpenModal: boolean
}

const CancelSubscription: FC<Props> = props => {
  const { isOpenModal, onCloseModal } = props;
  const endDate = useSelector(state => state.subscription.subscription.endDate);

  return (
    <Dialog
      open={isOpenModal}
      fullWidth
      PaperProps={{ sx: { maxHeight: 256, maxWidth: 424 } }}
    >
      <DialogTitle>
        Скасування передплати
        <IconButton
          aria-label='close'
          onClick={onCloseModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 12,
          }}
        ><CloseIcon /></IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Typography>Нам дуже шкода, що ви скасовуєте передплату.</Typography>
        <Typography marginTop={1} fontWeight={300}>
          Будь ласка, зверніться в Службу підтримки у Viber, Telegram чи Facebook для скасування передплати та коректного закриття профілю.
        </Typography>
        <Typography marginTop={1} fontWeight={300}>
          У випадку скасування, термін вашої передплати закінчиться {dayjs.utc(endDate).local().format('DD.MM.YYYY')}.
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default CancelSubscription;