import React, {FC, useContext, useEffect} from 'react';
import { connect } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router';

import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import CardActions from 'containers/Authorization/components/Card/CardActions';
import CardHeader from 'containers/Authorization/components/Card/CardHeader';

import { FormInput, InputEvent } from 'components/FormInput';

import getNumbersFormString from 'shared/functions/getNumbersFormString';
import Link from "@mui/material/Link";

import { RegistrationContext, Steps } from './Registration';

import { ApplicationState } from 'store';
import { actionCreators } from 'store/Registration';
import { CheckCodeRequest } from 'api/models';
import analytics from 'services/analytics';
import { AnalyticsEvents } from 'shared/constants/analytics';

type FormValues = {
    code: string;
};

interface ReduxProps {
    phoneNumber: string,
    codeError: string,
    signUp: (data: CheckCodeRequest, callBack: () => void) => void,
    removeErrors: () => void
}

const Verification: FC<ReduxProps> = props => {
    const { phoneNumber, codeError } = props;
    const history = useHistory();

    const { setStep } = useContext(RegistrationContext);
    const methods = useForm<FormValues>({
        mode: 'onChange'
    });

    useEffect(() => {
        if (codeError) {
            methods.setError('code', { message: codeError })
        }
        props.removeErrors();
    }, [codeError]);

    const onSubmit = (data: FormValues) => {
        props.signUp(
            { code: data.code, phone: phoneNumber },
            () => history.push('/business-structure', { isWelcomeDialogOpen: true }));
        analytics().sendEvent(AnalyticsEvents.registration_code_verification_send_code);
    };

    const onChange = ({ target }: InputEvent) => {
        methods.setValue('code', getNumbersFormString(target.value).substring(0, 4));
    };

    return (
        <>
            <CardHeader title='Реєстрація'/>
            <CardContent>
                <Typography
                    onClick={() => setStep(Steps.phoneNumber)}
                    fontWeight={300}
                    mb={3}
                    sx={{ cursor: 'pointer' }}
                    color={({ colors }) => colors.body}
                >
                    Код був надісланий: {phoneNumber}.
                    <Link ml={1} fontWeight={500} underline='none' mb={3}>Змінити номер телефону</Link>
                </Typography>
                <FormProvider {...methods}>
                    <Typography
                        ml={2}
                        mb={.5}
                        color={({ colors }) => colors.secondaryGrey}
                        fontSize='0.8571428571428571rem'>
                        КОД ПІДТВЕРДЖЕННЯ:
                    </Typography>
                    <FormInput
                        placeholder='4 цифри із СМС'
                        fullWidth
                        name='code'
                        rules={{
                            minLength: {
                                value: 4,
                                message: ''
                            },
                            onChange,
                        }}
                        autoFocus={true}
                    />
                </FormProvider>
            </CardContent>
            <CardActions>
                <Button onClick={() => {
                    setStep(Steps.phoneNumber);
                    analytics().sendEvent(AnalyticsEvents.registration_code_verification_go_back);
                }} size='large' variant='contained' color='inherit'>Назад</Button>
                <Box marginLeft='auto'>
                    <Button
                        onClick={methods.handleSubmit(onSubmit)}
                        disabled={
                            !methods.getValues('code') ||
                            !!Object.keys(methods.formState.errors).length
                        }
                        size='large'
                        variant='contained'
                    >
                        Зареєструватися
                    </Button>
                </Box>
            </CardActions>
        </>
    );
};

const mapStateToProps = ({ registration }: ApplicationState) => ({
    phoneNumber: registration.phoneNumber,
    codeError: registration.errors.code,
});

const mapDispatchToProps = {
    signUp: actionCreators.signUp,
    removeErrors: actionCreators.removeErrors,
};

export default connect(mapStateToProps, mapDispatchToProps)(Verification);
