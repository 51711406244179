import React, { FC, useContext } from 'react';
import { connect } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';

import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import CardActions from 'containers/Authorization/components/Card/CardActions';
import CardHeader from 'containers/Authorization/components/Card/CardHeader';
import { FormInput } from 'components/FormInput';

import { RegistrationContext, Steps } from './Registration';
import { actionCreators } from 'store/Registration';
import { CreateCompanyRequest } from 'api/models';
import analytics from 'services/analytics';
import { AnalyticsEvents } from 'shared/constants/analytics';


type FormValues = {
    pointOfSaleName: string,
    companyName: string,
    pointOfSaleAddress: string
};

interface ReduxProps {
    createCompany: (payload: CreateCompanyRequest, callBack: () => void) => void,
}

const CreateCompany: FC<ReduxProps> = props => {
    const history = useHistory();
    const { setStep } = useContext(RegistrationContext);
    const methods = useForm<FormValues>({
        mode: 'onChange'
    });

    const onSubmit = (data: FormValues) => {
        props.createCompany(data, () => history.push('/business-structure'));
        analytics().sendEvent(AnalyticsEvents.registration_company_data_create_company);
    };

    return (
        <>
            <CardHeader title='Реєстрація' />
            <CardContent>
                <FormProvider {...methods}>
                    <Typography
                        ml={2} mb={.5}
                        color={({ colors }) => colors.secondaryGrey}
                        fontSize='0.8571428571428571rem'>
                        НАЙМЕНУВАННЯ ФОП:
                    </Typography>
                    <FormInput
                        placeholder='Наприклад, прізвище ім’я по-батькові'
                        fullWidth
                        name='companyName'
                        rules={{ required: true }}
                        autoFocus={true}
                    />
                    <Typography
                        mt={2} ml={2} mb={.5}
                        color={({ colors }) => colors.secondaryGrey}
                        fontSize='0.8571428571428571rem'>
                        НАЗВА ТОРГОВОЇ ТОЧКИ
                    </Typography>
                    <FormInput
                        placeholder='Наприклад, магазин Роксолана'
                        fullWidth
                        name='pointOfSaleName'
                        rules={{ required: true }}
                    />
                    <Typography
                        mt={2} ml={2} mb={.5}
                        color={({ colors }) => colors.secondaryGrey}
                        fontSize='0.8571428571428571rem'>
                        АДРЕСА ТОРГОВОЇ ТОЧКИ
                    </Typography>
                    <FormInput
                        placeholder='Місто, вулиця, будинок'
                        fullWidth
                        name='pointOfSaleAddress'
                        rules={{ required: true }}
                    />
                </FormProvider>
            </CardContent>
            <CardActions sx={{ justifyContent: 'space-between' }}>
                <Button
                    onClick={() => {
                        setStep(Steps.uploadDocument);
                        analytics().sendEvent(AnalyticsEvents.registration_company_data_go_back);
                    }}
                    size='large'
                    variant='contained'
                    color='inherit'>
                    Назад
                </Button>
                <Box>
                    <Button
                        onClick={methods.handleSubmit(onSubmit)}
                        sx={{ ml: 2 }}
                        disabled={
                            !methods.getValues('companyName') ||
                            !methods.getValues('pointOfSaleName') ||
                            !methods.getValues('pointOfSaleAddress') ||
                            !!Object.keys(methods.formState.errors).length
                        }
                        size='large'
                        variant='contained'
                    >
                        Завершити
                    </Button>
                </Box>
            </CardActions>
        </>
    )
};

export default connect(null, {
    createCompany: actionCreators.createCompany
})(CreateCompany);
