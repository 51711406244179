export default (value: string): string => {
    if (!value.startsWith('380')) {
        value = value.replace(/^3?8?0?/, '380');
    }
    if (value.length > 3) {
        value = value.replace(/^([0-9]{3})/, '$& ');
    }
    if (value.length > 6) {
        value = value.replace(/^([0-9]{3})\s([0-9]{2})/, '$& ');
    }
    if (value.length > 10) {
        value = value.replace(/^([0-9]{3})\s([0-9]{2})\s([0-9]{3})/, '$& ');
    }
    if (value.length > 13) {
        value = value.replace(/^([+3?8?0?]{3})\s([0-9]{2})\s([0-9]{3})\s([0-9]{2})/, '$& ');
    }

    return value;
};
