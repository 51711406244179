import axios from 'axios';

import { getUrlWithoutDomain } from 'shared/functions';
import authService from 'services/auth';
import { actionCreators } from 'store/User';
import StateHelper from 'store/StateHelper';

const anonymousEndpoint = [
  'api/Authentication/Authentication',
  'api/Authentication/SendCode',
  'api/Registration/SendCode',
  'api/Registration/CheckCode',
];

axios.interceptors.request.use(config => {
  if (config && !anonymousEndpoint.includes(getUrlWithoutDomain(config.url || ''))) {
      const token = authService().getAccessToken() || StateHelper.getState().registration.accessToken;
      if (token) {
          config.headers.Authorization = `Bearer ${token}`;
      }
  }

  return config;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;

    if (response && response.status === 401) {
      StateHelper.dispatch(actionCreators.clearAppData('/login'));
    }

    return Promise.reject(error);
  });
