import React, { ChangeEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Table from 'containers/Products/Table';
import { ProductApi } from 'api'
import { actionCreators } from 'store/Notification';
import { actionCreators as productActions } from 'store/Product';
import analytics from 'services/analytics';
import { AnalyticsEvents } from 'shared/constants/analytics';

const productApi = new ProductApi();

const Input = styled('input')({
  display: 'none',
});

const Products: React.FC = () => {
  const [isImportingProducts, setIsImportingProducts] = useState(false);
  const dispatch = useDispatch();

  const onExportProducts = () => {
    productApi.productExportGet({responseType: 'blob'}).then(( {data} ) => {
          const blob = new Blob([data], { type: 'application/vnd.ms-excel' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');

          a.href = url;
          a.download = 'товари.xlsx';
          a.click();

          setTimeout(() => {
            window.URL.revokeObjectURL(url);
          }, 0);
        }
    ).catch(({ response }) => {
      dispatch(actionCreators.setNotification({ message: response.data.title, severity: 'error' }));
    });
    analytics().sendEvent(AnalyticsEvents.products_export_products);
  };

  const onImportProducts = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || !event.target.files.length) {
      return;
    }

    setIsImportingProducts(true);

    productApi.productImportPostForm(event.target.files[0]).then(() => {
      dispatch(actionCreators.setNotification({ message: 'Товари успішно імпортовано', severity: 'success' }));
      dispatch(productActions.getProducts());
    }).catch(({ response }) => {
      dispatch(actionCreators.setNotification({ message: response.data.title, severity: 'error' }));
    }).finally(() => setIsImportingProducts(false));
    analytics().sendEvent(AnalyticsEvents.products_import_products);
  };

  return (
    <>
      <Box
        display='flex'
        flexDirection='row'
        justifyContent='space-between'
        alignItems='center'
        mt={3}
        mb={2}
      >
        <Typography variant='h4' component='h1'>Товари</Typography>
        <Box display='flex' alignItems='center'>
          <Button
            size='large'
            variant='contained'
            color='secondary'
            onClick={onExportProducts}
          >
            Експорт товарів
          </Button>
          <Box display='inline-block' ml={2}>
            {isImportingProducts ? <CircularProgress size={20} sx={{ ml: 6.2, mr: 6.2 }} /> : (
              <label htmlFor='import-products-file'>
                <Input value='' accept='*' id='import-products-file' type='file' onChange={onImportProducts} />
                <Button
                  size='large'
                  component='span'
                >
                  Імпорт товарів
                </Button>
              </label>
            )}
          </Box>
        </Box>
      </Box>
      <Table />
    </>
  );
};

export default Products;
