import * as React from 'react';
import Box from '@mui/material/Box';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import EnhancedTableHead from './EnhancedTableHead'
import { Paper } from './styles'
import { Order, HeadCell, Row } from './types'
import { getComparator } from './utils'

interface IProps {
  idName?: string,
  rows: Row[],
  columns: HeadCell[],
  footer?: () => React.ReactElement,
  enablePagination?: boolean
}

const ReportTable: React.FC<IProps> = ({ idName, rows, columns, footer, enablePagination = true }) => {
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<string>('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    window.scrollTo(0, 0);

    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const renderRows = () => {
    let rowsToMap = rows.sort(getComparator(order, orderBy));

    if (enablePagination) {
      rowsToMap = rowsToMap.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    }

    return rowsToMap
      .map((row, index) => (
        <TableRow
          sx={{ bgcolor: 'common.white' }}
          key={row[idName || 'id']}
          hover
        >
          {columns.map(({ id, align, width, cell = (v) => v}) => (
            <TableCell sx={!enablePagination && index == rows.length - 1 ? { borderBottom: 'none' } : {}} align={align} key={id} width={width}>{cell(row[id], row)}</TableCell>
          ))}
        </TableRow>
      ))
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size="medium"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              headCells={columns}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {renderRows()}
              {footer && footer()}
            </TableBody>
          </Table>
        </TableContainer>
        {enablePagination && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}            
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </Box>
  );
}

export default ReportTable;
