import React, { useEffect } from 'react';
import { History } from 'history';

import { ApplicationsUrl } from '../../shared/constants/applications';

import Logo from '../../assets/images/logo-outlined.svg';
import DownloadAndroid  from '../../assets/images/download-android-app.svg';
import DownloadIOS  from '../../assets/images/download-ios-app.svg';

import './index.scss';

interface Props {
    history: History
}

const Applications: React.FC<Props> = props => {
    const { location } = window;

    useEffect(() => {
        const { userAgent } = navigator;

        const isIPhone = /iPhone/i.test(userAgent);
        const isAndroid = /Android/i.test(userAgent);

        if (isIPhone) location.href = ApplicationsUrl.ios;
        if (isAndroid) location.href = ApplicationsUrl.android;

    }, []);

    const redirectToApp = (appUrl: string) => () => location.href = appUrl;

    return (
        <div className='applications'>
            <div className='applications__content'>
                <img
                    onClick={() => props.history.push('')}
                    className='applications__content-logo pointer'
                    alt="Logo"
                    src={Logo}
                />
                <span className='applications__content-title'>
                    Завантажуйте безкоштовний додаток
                </span>
                <div className='applications__content-download'>
                    <img
                        className='pointer'
                        alt="DownloadAndroid"
                        src={DownloadAndroid}
                        onClick={redirectToApp(ApplicationsUrl.android)}
                    />
                    <img
                        className='applications__content-download-ios pointer'
                        alt="DownloadIOS"
                        src={DownloadIOS}
                        onClick={redirectToApp(ApplicationsUrl.ios)}
                    />
                </div>
            </div>
        </div>
    );
};

export default Applications;
