import { RouterState } from 'connected-react-router';
import * as PointOfSale from './PointOfSale';
import * as CashRegister from './CashRegister';
import * as Report from './Report';
import * as Authorization from './Authorization';
import * as Registration from './Registration';
import * as User from './User';
import * as DigitalSign from './DigitalSign';
import * as CashiersState from './Cashiers';
import * as Product from './Product';
import * as Channel from './Channel';
import * as Category from './Category';
import * as Company from './Company';
import * as Notification from './Notification';
import * as CompanyPartnership from './CompanyPartnership';
import * as Subscription from './Subscription';
import * as Settings from './Settings';

// The top-level state object
export interface ApplicationState {
    router: RouterState,
    pointOfSale: PointOfSale.PointOfSaleState;
    cashRegister: CashRegister.CashRegisterState;
    reports: Report.ReportState;
    authorization: Authorization.AuthenticationState;
    registration: Registration.RegistrationState
    user: User.UserState,
    digitalSign: DigitalSign.DigitalSignState,
    cashiers: CashiersState.CashiersState,
    product: Product.ProductState,
    channel: Channel.ChannelState,
    category: Category.CategoryState,
    company: Company.CompanyState,
    notification: Notification.NotificationState,
    companyPartnership: CompanyPartnership.CompanyPartnershipState,
    subscription: Subscription.SubscriptionState,
    settings: Settings.SettingsState
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    pointOfSale: PointOfSale.reducer,
    cashRegister: CashRegister.reducer,
    reports: Report.reducer,
    authorization: Authorization.reducer,
    registration: Registration.reducer,
    user: User.reducer,
    digitalSign: DigitalSign.reducer,
    cashiers: CashiersState.reducer,
    product: Product.reducer,
    channel: Channel.reducer,
    category: Category.reducer,
    company: Company.reducer,
    notification: Notification.reducer,
    companyPartnership: CompanyPartnership.reducer,
    subscription: Subscription.reducer,
    settings: Settings.reducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): Promise<void>;
}
