import React, { FC } from 'react';
import Box from '@mui/material/Box';

import articles from './blogMockData';
import BlogItem from './BlogItem';

const Blog: FC = () => {
  return (
    <Box flexWrap='wrap' display='flex' justifyContent='center'>
      {articles.map((item) => <BlogItem key={item.title} { ...item } /> )}
    </Box>
  );
};

export default Blog;
