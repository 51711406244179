import React, { FC, useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { connect } from 'react-redux';

import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Button from "@mui/material/Button";

import { FormPhoneInput } from 'components/FormInput';
import { ReactComponent as DeleteInput } from 'assets/images/icon_delete_input.svg';
import { actionCreators } from 'store/CompanyPartnership'
import { PartnerContext, Steps } from 'contexts/PartnerContext';
import { SendCodeToPartnerRequestDto } from 'api/models';
import { removeSpacesFormString } from 'shared/functions';
import { ApplicationState } from 'store';
import analytics from 'services/analytics';
import { AnalyticsEvents } from 'shared/constants/analytics';

type FormValues = {
  phoneNumber: string;
};

interface ReduxProps {
  createCompanyPartnershipSendCode: (data: SendCodeToPartnerRequestDto, callBack: () => void) => void,
  removePartnershipsErrors: () => void,
  phoneNumberError: string
}

const PhoneNumber: FC<ReduxProps> = props => {
  const { setStep, onCloseModal } = useContext(PartnerContext);
  const methods = useForm<FormValues>({
    mode: 'onChange'
  });

  useEffect(() => {
    if (props.phoneNumberError) {
      methods.setError('phoneNumber', { message: props.phoneNumberError })
    }
    props.removePartnershipsErrors();
  }, [props.phoneNumberError]);

  const onSubmit = (data: FormValues) => {
    props.createCompanyPartnershipSendCode(
      { partnerPhone: removeSpacesFormString(data.phoneNumber) },
      () => setStep(Steps.verificationCode));
    analytics().sendEvent(AnalyticsEvents.business_structure_add_partner_modal_send_phone);
  };

  const clearValue = () => {
    methods.setValue('phoneNumber', '+380');
    methods.clearErrors();
  };

  return (
    <>
      <DialogContent dividers>
        <FormProvider {...methods}>
          <Typography
            ml={2}
            mb={.5}
            color={({ colors }) => colors.secondaryGrey}
            fontSize='0.8571428571428571rem'>
            ВВЕДІТЬ НОМЕР МОБІЛЬНОГО ТЕЛЕФОНУ:
          </Typography>
          <FormPhoneInput
            fullWidth
            name='phoneNumber'
            InputProps={{
              endAdornment: <DeleteInput style={{ cursor: 'pointer' }} onClick={clearValue} />
            }}
            autoFocus={true}
          />
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          onCloseModal();
          analytics().sendEvent(AnalyticsEvents.business_structure_modal_add_partner_send_phone_cancel);
        }} size='large' variant='contained' color='inherit'>Відмінити</Button>
        <Button
          size='large'
          onClick={methods.handleSubmit(onSubmit)}
          disabled={
            !methods.getValues('phoneNumber') ||
            methods.getValues('phoneNumber') === '+380' ||
            !!Object.keys(methods.formState.errors).length
          }
        >
          Додати
        </Button>
      </DialogActions>
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  phoneNumberError: state.companyPartnership.errors.phoneNumber
})

const mapDispatchToProps = {
  createCompanyPartnershipSendCode: actionCreators.createCompanyPartnershipSendCode,
  removePartnershipsErrors: actionCreators.removePartnerErrors,
};

export default connect(mapStateToProps, mapDispatchToProps)(PhoneNumber);
