import { createContext, Dispatch, SetStateAction } from 'react'

enum Steps {
  phoneNumber,
  verificationCode,
  info
}

interface PartnerContextInterface {
  setStep: Dispatch<SetStateAction<Steps>>
  onCloseModal: () => void
}

const PartnerContext = createContext<PartnerContextInterface>({ setStep: () => null, onCloseModal: () => null });

export {
  Steps, PartnerContext
};
