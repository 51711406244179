import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

const PrivacyPolicy: React.FC = () => {
  const appLink = <Link target='_blank' underline='none' href='https://fopkasa.com/'>https://fopkasa.com/</Link>;

  return (
    <>
      <Typography component='h1' fontWeight={700} fontSize={{ xs: 32, md: 48 }} mt={4}>ПРАВИЛА КОРИСТУВАННЯ</Typography>
      <Typography component='h2' fontWeight={700} fontSize={{ xs: 24, md: 32 }} mt={4}>
        веб-сайтом/застосунком {appLink} (ПУБЛІЧНА ОФЕРТА)
      </Typography>
      <Typography mt={1} fontWeight='lighter'>Приймаючи публічну оферту, Ви (як Користувач) погоджуєтесь зі всіма викладеними нижче умовами і підтверджуєте, що Вам зрозумілі всі її положення та укладаєте на цих умовах договір про надання послуг з ТзОВ &#34;ФОПкаса&quot;.</Typography>
      
      <Typography mt={1} mb={1} fontWeight='bold'>ДОГОВІР ПУБЛІЧНОЇ ОФЕРТИ ПРО НАДАННЯ ПОСЛУГ ЩОДО КОРИСТУВАННЯ ЗАСТОСУНКОМ</Typography>
      <Typography mb={1} fontWeight='lighter'>
        Даний договір публічної оферти укладено між товариством з обмеженою відповідальністю «ФОПкаса» (далі - Виконавець) і користувачами веб-сайту {appLink} та застосунку FOPKASA (далі – Користувач), разом у тексті – Сторони, окремо Сторона, про надання послуг (далі – Договір) на визначених у ньому умовах користування Застосунком.
      </Typography>
      <Typography mb={1} fontWeight='lighter'>
        Ця публічна оферта містить ознаки публічного договору та договору приєднання – умови викладені в стандартній та незмінній для усіх Користувачів формі. Використовуючи веб-сайт {appLink} та застосунок FOPKASA, Користувачі підтверджують, що ознайомлені та погоджуються з усіма положеннями цієї публічної оферти. 
      </Typography>
      <Typography mb={1} fontWeight='lighter'>Користувачі не можуть пропонувати свої умови до цієї публічної оферти.</Typography>
      <Typography mb={1} fontWeight='lighter'>До відносин Користувачів і Виконавця застосовуються положення ст. 633 та ст. 634 Цивільного кодексу України щодо публічного договору та договору приєднання.</Typography>
      <Typography mb={1} fontWeight='lighter'>Приєднанням до цього Договору вважатимуться конклюдентні дії: проставлення галочки навпроти поля «Ознайомлений із публічною офертою» та/або скачування застосунку FOPKASA, та/або оплата вартості скачуваного застосунку та/або вчинення інших дій, передбачених цими правилами (публічною офертою), які трактуються як приєднання до цієї публічної оферти. У разі незгоди з умовами цього Договору укладення договору приєднання не є можливим.</Typography>
      <Typography mb={1} fontWeight='lighter'>Приєднання до цього Договору засвідчує повне визнання Користувачем Умов користування застосунком.</Typography>
      
      <Typography mb={1} fontWeight='bold'>УМОВИ КОРИСТУВАННЯ ЗАСТОСУНКОМ «FOPKASA»</Typography>
      <Typography mb={1} fontWeight='lighter'>Ці умови користування розроблено Правовласником для Користувачів з метою надання Користувачеві послуг щодо використання застосунку.</Typography>
      
      <Typography mb={1} fontWeight='bold'>ОСНОВНІ ПОНЯТТЯ</Typography>
      <Typography mb={1} fontWeight='lighter'>Денний звіт – документ встановленої форми, створений у електронній формі програмним реєстратором розрахункових операцій, що містить інформацію про денні підсумки розрахункових операцій, проведених з його застосуванням.</Typography>
      <Typography mb={1} fontWeight='lighter'>Електронна копія розрахункового документа – ідентична копія (у формі електронних даних) розрахункового документа, створена програмним реєстратором розрахункових операцій у електронній формі, що може використовуватися контролюючими органами як податкова інформація та під час проведення перевірок платників податків.</Typography>
      <Typography mb={1} fontWeight='lighter'>Електронна копія фіскального звітного чека – ідентична копія (у формі електронних даних) фіскального звітного чека, створеного програмним реєстратором розрахункових операцій, що може використовуватися контролюючими органами як податкова інформація та під час проведення перевірок платників податків.</Typography>
      <Typography mb={1} fontWeight='lighter'>Електронна копія фіскального звіту – ідентична копія (у формі електронних даних) фіскального звіту, створеного програмним реєстратором розрахункових операцій, що може використовуватися контролюючими органами як податкова інформація та під час проведення перевірок платників податків.</Typography>
      <Typography mb={1} fontWeight='lighter'>Електронний розрахунковий документ – це документ, створений програмним реєстратором розрахункових операцій у момент проведення розрахункової операції та зареєстрований фіскальним сервером контролюючого органу із присвоєнням йому фіскального номера фіскальним сервером контролюючого органу або присвоєнням йому фіскального номера із діапазону номерів, сформованих фіскальним сервером, інформація в якому зафіксована у вигляді електронних даних, включаючи обов’язкові реквізити розрахункового документа за встановленою формою.</Typography>
      <Typography mb={1} fontWeight='lighter'>Електронний фіскальний звіт – це документ, створений програмним реєстратором розрахункових операцій, що містить дані фіскального звіту, зареєстрований фіскальним сервером контролюючого органу, інформація в якому зафіксована у вигляді електронних даних, включаючи обов’язкові реквізити фіскального звіту за встановленою формою.</Typography>
      <Typography mb={1} fontWeight='lighter'>Електронний фіскальний звітний чек – це документ, створений програмним реєстратором розрахункових операцій, що містить дані денного звіту, зареєстрований фіскальним сервером контролюючого органу, інформація в якому зафіксована у вигляді електронних даних, включаючи обов’язкові реквізити фіскального звітного чека за встановленою формою.</Typography>
      <Typography mb={1} fontWeight='lighter'>Застосунок «FOPKASA» – набір інструкцій у вигляді слів, цифр, кодів, схем, символів чи у будь-якому іншому вигляді, виражених у формі, придатній для зчитування смартфоном, планшетом, комп’ютером, які приводять його у дію для досягнення визначеної цими Умовами користування мети.</Typography>
      <Typography mb={1} fontWeight='lighter'>Контрольна стрічка – це електронні копії розрахункових документів, а також електронні копії фіскальних звітних чеків, які сформовані послідовно із додаванням у кожен наступний розрахунковий документ або фіскальний звітний чек хешу попереднього розрахункового документа або фіскального звітного чека та зберігаються в електронній формі програмним реєстратором розрахункових операцій під час його роботи на період відсутності зв’язку між програмним реєстратором розрахункових операцій та фіскальним сервером контролюючого органу (в режимі офлайн) до моменту передачі розрахункових документів або фіскальних звітних чеків до фіскального сервера контролюючого органу.</Typography>
      <Typography mb={1} fontWeight='lighter'>Користувач – це фізична особа – підприємець, яка використовує застосунок як програмний реєстратор розрахункових операцій, прийнявши умови цієї публічної оферти.</Typography>
      <Typography mb={1} fontWeight='lighter'>Мета користування застосунком – використання застосунку з фіскальною функцією як програмного реєстратора розрахункових операцій у сфері торгівлі, громадського харчування та послуг.</Typography>
      <Typography mb={1} fontWeight='lighter'>Правовласник – це розробник застосунку як програмного забезпечення. Розробником є Товариство з обмеженою відповідальністю «ФОПкаса», зареєстроване відповідно до чинного законодавства України. Місцезнаходження товариства: 79041, Львівська обл., місто Львів, вул.Порохова, будинок 20 Д, секція 5.</Typography>
      <Typography mb={1} fontWeight='lighter'>Реєстрація електронних розрахункових документів, електронних фіскальних звітів та електронних фіскальних звітних чеків – це присвоєння програмним забезпеченням фіскального сервера контролюючого органу фіскального номера електронному розрахунковому документу, електронному фіскальному звіту та електронному фіскальному звітному чеку, що створені програмними реєстраторами розрахункових операцій, із збереженням даних щодо виконання розрахункової операції у системі обліку даних реєстраторів розрахункових операцій.</Typography>
      <Typography mb={1} fontWeight='lighter'>Розрахунковий документ – документ встановленої законом форми та змісту (касовий чек, товарний чек, видатковий чек тощо), що підтверджує факт продажу (повернення) товарів, надання послуг, інших операцій, передбачених законом, створений в електронній формі (електронний розрахунковий документ) у випадках, передбачених законом, зареєстрованим у встановленому порядку програмним реєстратором розрахункових операцій.</Typography>
      <Typography mb={1} fontWeight='lighter'>Сторони – Користувач і Правовласник.</Typography>
      <Typography mb={1} fontWeight='lighter'>Фіскальний звітний чек – документ встановленої форми, створений у електронній формі (електронний фіскальний звітний чек) програмним реєстратором розрахункових операцій, що містить дані денного звіту, під час створення якого інформація про обсяг виконаних розрахункових операцій заноситься відповідно до фіскальної пам’яті реєстратора розрахункових операцій або фіскального сервера контролюючого органу.</Typography>
      <Typography mb={1} fontWeight='lighter'>Фіскальний режим роботи – режим роботи програмного реєстратора розрахункових операцій, зареєстрованого у реєстрі таких реєстраторів, що забезпечує реєстрацію розрахункових документів на фіскальному сервері контролюючого органу відповідно до закону.</Typography>
      <Typography mb={1} fontWeight='lighter'>Фіскальний сервер контролюючого органу – програмно-технічний комплекс, через який реалізується фіскальна функція та який здійснює реєстрацію програмних реєстраторів розрахункових операцій, електронних розрахункових документів, електронних фіскальних звітів та електронних фіскальних звітних чеків, збереження даних щодо проведеної розрахункової операції у системі обліку даних реєстраторів розрахункових операцій, обробки та надання доступу до інформації про розрахункові операції, проведені з використанням програмних реєстраторів розрахункових операцій, а також інтеграцію з програмними реєстраторами розрахункових операцій через програмний інтерфейс (АРІ), розміщений у відкритому вільному доступі.</Typography>
      <Typography mb={1} fontWeight='lighter'>Фіскальні функції – здатність програмних реєстраторів розрахункових операцій забезпечувати одноразове занесення для довгострокового зберігання на фіскальному сервері контролюючого органу та багаторазове зчитування і неможливість зміни підсумкової інформації про обсяг розрахункових операцій, виконаних у готівковій та/або в безготівковій формі (із застосуванням електронних платіжних засобів, платіжних чеків, жетонів тощо).</Typography>
      <Typography mb={1} fontWeight='lighter'>Інші терміни та поняття застосовуються відповідно до положень чинного законодавства України.</Typography>
      
      <Typography mb={1} fontWeight='bold'>ЗАВАНТАЖЕННЯ ТА ВИКОРИСТАННЯ ЗАСТОСУНКУ КОРИСТУВАЧЕМ ЯК ПРОГРАМНОГО ЗАБЕЗПЕЧЕННЯ</Typography>
      <Typography mb={1} fontWeight='lighter'>Користувач, завантажуючи застосунок, погоджується на вказані Умови користування в цілому в редакції на момент завантаження. У подальшому Правовласник залишає за собою право оновлювати Умови користування в будь-який час без попередження Користувача. Актуальну редакцію Умов користування користувач може переглянути, натиснувши гіпертекстове посилання &#34;Умови користування&#34;, що розташоване на веб-сайті {appLink}. Змінені Правовласником Умови користування визнаються і виконуються Користувачем. Якщо Користувач продовжує користуватись застосунком після зміни Умов користування, це трактується як згода Користувача з новою редакцією Умов користування. У разі незгоди Користувача з новою редакцією Умов користування він зобов’язаний видалити застосунок і припинити користування ним.</Typography>
      <Typography mb={1} fontWeight='lighter'>Умови користування є договором приєднання. Користувач не може пропонувати свої зміни до Умов користування. До відносин Сторін застосовуються положення цивільного законодавства України щодо договору приєднання.</Typography>
      <Typography mb={1} fontWeight='lighter'>Користувач, який не погоджується з цими Умовами користування, не має права користуватися застосунком.</Typography>
      <Typography mb={1} fontWeight='lighter'>Правовласник гарантує:</Typography>
      <Box component='ul' ml={3} mb={1}>
        <Box component='li'><Typography fontWeight='lighter'>безперебійну роботу застосунку;</Typography></Box>
        <Box component='li'><Typography fontWeight='lighter'>роботу застосунку без помилок.</Typography></Box>
      </Box>
      <Typography mb={1} fontWeight='lighter'>У разі виявлення технічних збоїв у роботі застосунку Правовласник вживає заходів для їх усунення.</Typography>
      <Typography mb={1} fontWeight='lighter'>Користувач не має права змінювати (модифікувати) застосунок. У разі внесення Користувачем змін (модифікацій) до застосунку, Правовласник не несе відповідальності за коректну роботу застосунку, а Користувач несе відповідальність за порушення вимог законодавства щодо інтелектуальну власність відповідно до вимог закону.</Typography>
      <Typography mb={1} fontWeight='lighter'>Застосунок призначений для особистого використання Користувачем як програмного реєстратора розрахункових операцій.</Typography>
      <Typography mb={1} fontWeight='lighter'>Застосунок може надавати статистику залишків товарів, дозволяє створювати групи та каталоги товарів (послуг), дозволяє присвоювати ціни товарам (послугам) та змінювати такі ціни. Історія (щодо використання застосунку користувачем) та всі платіжні транзакції користувача зберігатимуться на ресурсах хмарного сервісу (public cloud services), доступ до якого матиме користувач.</Typography>
      <Typography mb={1} fontWeight='lighter'>Користувач має право додавати, змінювати касира, але повинен вчиняти такі дії з урахуванням вимог податкового законодавства.</Typography>
      <Typography mb={1} fontWeight='lighter'>Завантаження та використання застосунку можливе з використанням смартфону, планшету, іншого подібного пристрою.</Typography>
      <Typography mb={1} fontWeight='lighter'>Застосунок для виконання покладених на нього функцій вимагає доступу до Інтернету. Обов’язок забезпечити доступ до Інтернету під час використання застосунку покладається на Користувача. Застосунок може використовуватися без доступу до мережі Інтернет протягом певного часу (робота офлайн), шляхом ………, при цьому зібрані ним дані зберігаються. Користувач повинен дотримуватися вимог закону щодо створення граничної кількості розрахункових документів в режимі офлайн.</Typography>
      <Typography mb={1} fontWeight='lighter'>Застосунок має два режими роботи: тестовий (без надсилання інформації в органи державної фіскальної (податкової) служби) та робочий (звичайний). Переключення між режимами здійснює Користувач самостійно.</Typography>
      
      <Typography mb={1} fontWeight='bold'>ЗАСТОСУНОК ЯК ПРОГРАМНИЙ РЕЄСТРАТОР РОЗРАХУНКОВИХ ОПЕРАЦІЙ</Typography>
      <Typography mb={1} fontWeight='lighter'>Програмний реєстратор розрахункових операцій (яким є застосунок) внесено до реєстру програмних реєстраторів розрахункових операцій, який ведеться центральним органом виконавчої влади, що реалізує державну податкову політику, та має фіскальний номер програмного реєстратора розрахункових операцій (унікальний код, що формується фіскальним сервером контролюючого органу та присвоюється програмному реєстратору розрахункових операцій під час реєстрації для його ідентифікації).</Typography>
      <Typography mb={1} fontWeight='lighter'>Під програмним реєстратором розрахункових операцій слід розуміти програмний, програмно-апаратний або програмно-технічний комплекс у вигляді технологічного та/або програмного рішення, що використовується на будь-якому пристрої та в якому фіскальні функції реалізовані через фіскальний сервер контролюючого органу і який призначений для реєстрації розрахункових операцій при продажу товарів (наданні послуг), операцій з торгівлі валютними цінностями в готівковій формі та/або реєстрації кількості проданих товарів (наданих послуг), операцій з приймання готівки для подальшого переказу.</Typography>
      <Typography mb={1} fontWeight='lighter'>Порядок використання Користувачем застосунку як програмного реєстратора розрахункових операцій визначається відповідно до Податкового кодексу України, Закону України «Про застосування реєстраторів розрахункових операцій у сфері торгівлі, громадського харчування та послуг» та інших нормативно-правових актів у сфері оподаткування. Обов’язки Користувача як суб’єкта господарювання, який здійснює розрахункові операції, визначаються відповідно до закону. Користувач зобов’язаний зареєструвати в органах державної фіскальної (податкової) служби використовуваний програмний реєстратор розрахункових операцій.</Typography>
      <Typography mb={1} fontWeight='lighter'>Застосунок опрацьовує дані, які вводяться користувачами щодо здійснення ними щоденних платіжних операцій, зберігає інформацію щодо сум та кількості транзакцій, здійснених користувачами за день.</Typography>
      <Typography mb={1} fontWeight='lighter'>Застосунок має фіскальний режим роботи.</Typography>
      <Typography mb={1} fontWeight='lighter'>Застосунок забезпечує створення фіскальних звітних чеків, денних звітів, які в подальшому заносяться до фіскальної пам’яті реєстратора розрахункових операцій або фіскального сервера контролюючого органу.</Typography>
      <Typography mb={1} fontWeight='lighter'>Застосунок забезпечує формування денних звітів, які містять інформацію про денні підсумки розрахункових операцій, проведених з його застосуванням.</Typography>
      <Typography mb={1} fontWeight='lighter'>Застосунок формує розрахунковий документ в електронній формі, що підтверджує факт здійснення операцій, які підлягають податковому обліку.</Typography>
      <Typography mb={1} fontWeight='lighter'>Застосунок формує електронний розрахунковий документ у момент проведення розрахункової операції та забезпечує його передачу фіскальну серверу контролюючого органу.</Typography>
      <Typography mb={1} fontWeight='lighter'>Завдяки використанню застосунку відбувається реєстрація електронних розрахункових документів, електронних фіскальних звітів та електронних фіскальних звітних чеків (присвоєння програмним забезпеченням фіскального сервера контролюючого органу фіскального номера електронному розрахунковому документу, електронному фіскальному звіту та електронному фіскальному звітному чеку, що створені застосунком, із збереженням даних щодо виконання розрахункової операції у системі обліку даних реєстраторів розрахункових операцій).</Typography>

      <Typography mb={1} fontWeight='bold'>ОПЛАТА ПОСЛУГ</Typography>
      <Typography mb={1} fontWeight='lighter'>За використання застосунку Користувач сплачує плату в розмірі, що встановлений Правовласником на момент завантаження застосунку.</Typography>
      <Typography mb={1} fontWeight='lighter'>Оплата здійснюється Користувачем під час завантаження застосунку.</Typography>
      <Typography mb={1} fontWeight='lighter'>Форма оплати згідно публічної оферти, яка міститься на сайті Правовласника: _________________</Typography>

      <Typography mb={1} fontWeight='bold'>РЕКЛАМА</Typography>
      <Typography mb={1} fontWeight='lighter'>Користувач погоджується, що під час використання застосунку може мати місце показ рекламних оголошень. Види реклами визначаються відповідно до закону.</Typography>
      <Typography mb={1} fontWeight='lighter'>Користувач погоджується, що інформація, надана ним під час завантаження та користуванням застосунком, може використовуватися Правовласником для підбору та подальшої демонстрації (показу) Користувачам відповідних рекламних пропозицій.</Typography>

      <Typography mb={1} fontWeight='bold'>ВІДПОВІДАЛЬНІСТЬ</Typography>
      <Typography mb={1} fontWeight='lighter'>Користувачі погоджуються з тим, що використовують застосунок добровільно та на власний ризик.</Typography>
      <Typography mb={1} fontWeight='lighter'>Між Правовласником та Користувачами не виникають жодні правовідносини, крім тих, які пов’язані з використанням застосунку відповідно до передбачених умовами користування правил.</Typography>
      <Typography mb={1} fontWeight='lighter'>Правовласник не є стороною правовідносин, які виникають між Користувачами і органами державної фіскальної (податкової) служби України, між Користувачами та Покупцями товарів (послуг).</Typography>
      <Typography mb={1} fontWeight='lighter'>Правовласник  не несе жодної відповідальності за:</Typography>
      <Box component='ul' ml={3} mb={1}>
        <Box component='li'><Typography fontWeight='lighter'>достовірність введення Користувачами даних у застосунок як програмний реєстратор розрахункових операцій;</Typography></Box>
        <Box component='li'><Typography fontWeight='lighter'>заподіяння шкоди, прямих чи непрямих збитків, штрафів, тощо, які може нести Користувач у зв’язку із введенням даних у застосунок як програмний реєстратор розрахункових операцій;</Typography></Box>
        <Box component='li'><Typography fontWeight='lighter'>технічні перебої, помилки у роботі застосунку у зв’язку із відсутністю підключення до мережі Інтернет;</Typography></Box>
        <Box component='li'><Typography fontWeight='lighter'>за втрати даних та/або іншу заподіяну шкоду, що спричинена іншим програмним забезпеченням, яке встановлено Користувачем самостійно на пристрій, на якому встановлено застосунок;</Typography></Box>
        <Box component='li'><Typography fontWeight='lighter'>за втрату даних, зумовлену неправильним використанням застосунку Користувачем;</Typography></Box>
        <Box component='li'><Typography fontWeight='lighter'>за штрафи та/або інші грошові стягнення (як засіб відповідальності), пов’язані з втратою/пошкодженням пристрою, на якому було встановлено застосунок та/або при незбереженні даних користувачем, що виникли в тому числі і до втрати пристрою;</Typography></Box>
        <Box component='li'><Typography fontWeight='lighter'>за штрафи, інші види відповідальності, що застосовуються до Користувача у зв’язку із неправильним використанням застосунку самим Користувачем, іншими особами, які відповідно до закону та згодою Користувача отримали доступ до застосунку (касири, зокрема);</Typography></Box>
        <Box component='li'><Typography fontWeight='lighter'>надання Користувачем доступу до своїх ідентифікаційних даних (логін, пароль, електронний цифровий підпис, електронний ключ тощо) третім особам та за втрату даних, зумовлених доступом третіх осіб до паролю, електронного цифрового підпису тощо Користувача.</Typography></Box>
      </Box>
      <Typography mb={1} fontWeight='lighter'>Користувачі несуть повну відповідальність відповідно до закону за:</Typography>
      <Box component='ul' ml={3} mb={1}>
        <Box component='li'><Typography fontWeight='lighter'>точність, повноту та достовірність введення даних у застосунок як програмний реєстратор розрахункових операцій, за збереження цих даних;</Typography></Box>
        <Box component='li'><Typography fontWeight='lighter'>дотримання законодавства, в тому числі і податкового, яке підлягає застосуванню при використанні Користувачами застосунку як програмного реєстратора розрахункових операцій;</Typography></Box>
      </Box>
      <Typography mb={1} fontWeight='lighter'>Користувач зобов’язаний ознайомити з цими Умовами користування інших осіб, які відповідно до закону за згодою Користувача використовують застосунок (зокрема, касири, інші особи). Користувач зобов’язаний навчити таких осіб правильно користуватися застосунком. У разі заподіяння шкоди Користувачу цими особами внаслідок неправильного використання застосунку, Правовласник не відповідає за цю завдану шкоду, в тому числі штрафи, інші види відповідальності, застосовані контролюючими органами відповідно до закону.</Typography>
      
      <Typography mb={1} fontWeight='bold'>ПОДАТКИ ТА ІНШІ ЗАГАЛЬНООБОВЯЗКОВІ ПЛАТЕЖІ</Typography>
      <Typography mb={1} fontWeight='lighter'>Правовласник та застосунок не виступають податковим агентом, посередником чи іншим суб’єктом податкових правовідносин Користувача і держави.</Typography>
      <Typography mb={1} fontWeight='lighter'>Виключно Користувачі відповідальні за сплату усіх податків та зборів, які підлягають сплаті відповідно до чинного законодавства України, а також за правильність ведення податкового обліку.</Typography>

      <Typography mb={1} fontWeight='bold'>ПОРЯДОК ВИРІШЕННЯ СПОРІВ</Typography>
      <Typography mb={1} fontWeight='lighter'>Усі спори стосовно використання застосунку вирішуються шляхом проведення переговорів між Сторонами, а у разі недосягнення згоди – в судовому порядку.</Typography>

      <Typography mb={1} fontWeight='bold'>ПРАВА ІНТЕЛЕКТУАЛЬНОЇ ВЛАСНОСТІ</Typography>
      <Typography mb={1} fontWeight='lighter'>Виключні майнові права інтелектуальної власності на програмне забезпечення (застосунок) належать Правовласнику.</Typography>
      <Typography mb={1} fontWeight='lighter'>Використання застосунку (програмного забезпечення) Користувачами здійснюється лише в межах, що визначені цими Умовами, та чинним законодавством про інтелектуальну власність. Використання застосунку (програмного забезпечення) іншими особами без згоди Правовласника забороняється.</Typography>
      <Typography mb={1} fontWeight='lighter'>Користувачам та іншим особам без згоди Правовласника забороняється будь-яким способом (рекламування, модифікація тощо) та у будь-яких цілях використовувати застосунок та/або його назву - «FOPKASA» (крім способів використання Користувачами, що зумовлені функціональним призначенням застосунку, і які визначені цими Умовами користування). Будь-яке відтворення або розповсюдження застосунку Користувачами заборонено та карається притягненням Користувача до цивільної, кримінальної та іншої, передбаченої законом відповідальності.</Typography>
      <Typography mb={1} fontWeight='lighter'>Користувачі визнають та погоджуються, що виключне право на використання комерційного позначення (знака для товарів і послуг) «FOPKASA» належить Правовласнику.</Typography>

      <Typography mb={1} fontWeight='bold'>КОНФІДЕНЦІЙНІСТЬ</Typography>
      <Typography mb={5} fontWeight='lighter'>Відносини Користувача і Правовласника щодо дотримання умов конфіденційності врегульовані Умовами конфіденційності, які містяться на веб-сайті {appLink} та/або у самому Застосунку.</Typography>
    </>
  )
};

export default PrivacyPolicy;
