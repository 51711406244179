import blog1 from 'assets/images/blog1.png';
import blog2 from 'assets/images/blog2.png';
import blog3 from 'assets/images/blog3.png';
import blog4 from 'assets/images/blog4.png';
import blog5 from 'assets/images/blog5.png';
import blog7 from 'assets/images/blog7.png';

export type Article = {
  articleId: number;
  image: string;
  title: string;
  date: string;
  description: string;
  chips:  string[]
}

const articles: Article[] = [
  {
    articleId: 0,
    image: blog7,
    title: 'Додаток для IOS/Android та веб-сайт ФОПкаса. Як вони повʼязані?',
    date: '19 травня 2023',
    description: `
Чому крім встановленого на ваш мобільний пристрій додатку деякі функції доступні лише через веб-сайт?               

Назагал, програмний РРО ФОПкаса складається з двох основних частин — мобільного додатку для Android чи iPhone (iPad) та Особистого кабінету на сайті ФОПкаси https://fopkasa.com/.

Нагадаємо, що для реєстрації будь-якого ПРРО необхідно, щоб дані підприємця в додатку ФОПкаса синхронізувались  із даними в кабінеті платника податку на сайті ДПС. Для цього використовується Кваліфікований Електронний Підпис (КЕП). Через Особистий кабінет клієнти ФОПкаси - підприємці - найперше — завантажують свій КЕП для інтеграції з ДПС та подальшого миттєвого передавання даних з додатка на сервер податкової. Тобто, для роботи у фіскальному режимі.

Після  процедури реєстрації ПРРО в електронному кабінеті платника податку та встановлення додатку ФОПкаса на мобільний пристрій підприємець може розпочинати роботу з продажу товарів та послуг.  

Додаток необхідно встановити на пристрій, яким користуватиметься касир в місці здійснення продажу товарів або послуг. Певною мірою, це аналог апаратного реєстратора розрахункових операцій (касового апарата). Тобто за допомогою мобільного додатку підприємці можуть:
- видавати фіскальні чеки;
- додавати приватні коментарі до чеків;
- надавати знижку в чеку;
- змінювати ціну товару при формуванні чеку, не змінюючи ціни товару в базі;
- надсилати електронні чеки в смс та за QR кодом, та в месенджерах;
- відкривати та закривати робочі зміни;
- формувати та надсилати на сервер ДПС обов’язкові щоденні Z-звіти;
- додавати нові товари вручну, категорії та фільтри у базу товарів;
Nota Bene! імпортувати перелік товарів з excel документу можна через веб-сайт;
- переглядати звіти щодо важливих показників бізнесу. 

Загалом, мобільний додаток, дає можливість здійснювати всі необхідні операції, щоб повністю виконувати вимоги ДПС щодо фіскалізації розрахункових операцій. 

Для чого ж тоді потрібен Особистий кабінет окрім початкової реєстрації бізнесу? 

В Особистому кабінеті зручно переглядати та завантажувати для роздруку всю звітність по продажах для аналізу та подальшого застосування у маркетингових цілях. 
Загалом, робота із завантаження великої номенклатури товарів, зміни їхньої ціни чи рекатегоризації може відбуватись за допомогою вивантаження/редагування/завантаження excel файлів через веб-сайт.

Для структури бізнесу, в якого замовлення товару чи послуги опрацьовується у кілька етапів (напр., у випадку, кавʼярні - кухня-каса-бар), ФОПкаса надає можливість налаштувати  обмін інформацією стосовно замовлення через Telegram канал. Ця функція теж доступна в Особистому кабінеті.
В Особистому кабінеті також доступна функція консолідації фінансових показників одного бізнесу з відокремленими торговими точками. 
`,
    chips: ['Про наш ПРРО'],
  },
  {
    articleId: 1,
    image: blog1,
    title: 'Реєстрація ПРРО через Електронний кабінет платника податків',
    date: '05 травня 2023',
    description: `
Сьогодні ми розповімо про те, як зареєструвати програмний РРО ФОПкаса, не відвідуючи податкову (через Електронний кабінет платника податків). Насправді тут нічого складного. 

Спочатку ви повинні переконатись, що у вас є кваліфікований електронний підпис (особистий електронний ключ) як способ електронної ідентифікації. За його допомогою необхідно здійснити вхід до приватної частини Електронного кабінету, який розміщено за посиланням: https://cabinet.tax.gov.ua/login

Наступним кроком потрібно зареєструвати вашу господарську одиницю (об’єкт оподаткування), місце, де здійснюється продаж товарів чи надання послуг, тобто ваш магазин чи офіс. Реєстрація відбувається шляхом заповнення заяви J / F1312002.

Щодо деталей заповнення. В розділі 3 «Дані щодо господарської одиниці» зазначається:
- ідентифікатор об’єкта оподаткування та КОАТУУ. Для фізичних осіб – підприємців – платників єдиного податку, у яких відсутні господарські одиниці у поле ідентифікатор об’єкта оподаткування проставляється значення «00000000» та в полі КОАТУУ – значення населеного пункту місця реєстрації;
- назва господарської одиниці (ГО) – для відображення у розрахунковому документі (чекові);
- адреса розміщення ГО (програмно-технічного комплексу самообслуговування) – для відображення у розрахунковому документі;
- назва платіжної системи (для платіжної організації платіжної системи (учасника платіжної системи)) – для відображення у розрахунковому документі за умови якщо суб’єкт господарювання є учасником такої платіжної системи.

Важливо пам’ятати, що кожну окрему господарську одиницю треба реєструвати окремо, як і подавати заяви на реєстрацію ПРРО для них. Тобто, якщо у вас два магазини, потрібно заповнити дві заяви на реєстрацію господарських одиниць і дві заяви на реєстрацію ППРО. 

Тоді кожна окрема господарська одиниця (ГО) матиме зареєстрований на неї програмний РРО і у фіскальному чеку будуть вказуватися її назва та адреса, що є обов’язковим для фіскальних чеків.   

Заяву на реєстрацію ППРО можна знайти в особистому кабінеті за ідентифікатором J/F1316602 1-ПРРО «Заява про реєстрацію програмного реєстратора розрахункових операцій».  Вкажіть всі необхідні дані, натисніть перевірити та надішліть заяву. Упродовж двох робочих днів ви отримаєте  повідомлення про схвалення або відхилення реєстрації. 

Що вимагається при заповненні заяви за формою № 1-ПРРО? 
У розділі 1 «Дія» необхідно обирати комірку «Реєстрація». У розділі 2 «Дані щодо суб’єкта господарювання» зазначити:
- найменування або прізвище, ім’я, по батькові ФОП;
- податковий номер/серія (за наявності) та номер паспорта;
- ІПН (для платників ПДВ)
Роль касира ж полягає у тому, щоб здійснювати реєстрацію розрахункових операцій на ПРРО. Завдяки кваліфікованому електронному підпису (КЕП) касир може відкривати зміну, видавати фіскальні чеки та щоденні фіскальні звітні чеки (Z-звіти). Суб’єкт господарювання (підприємець) може проводити розрахункові операції на ПРРО самостійно або визначати уповноважених осіб (касирів).
Отже, якщо у підприємця є декілька господарських одиниць, йому необхідно реєструвати кожну господарську одиницю, та на кожну з них програмний реєстратор і окрему касу. 
`,
    chips: ['Корисно знати'],
  },
  {
    articleId: 2,
    image: blog2,
    title: 'Офіс вдома. Де реєструвати ПРРО?',
    date: '27 квітня 2023',
    description: `
    Ось ви вже визначилися з тим, що вам потрібен програмний РРО, встановили ФОПкасу та вже випробували її в тестовому режимі. Тепер прийшов час переводити додаток у фіскальний режим. Щоб здійснити це потрібно зареєструвати ПРРО через Електронний кабінет платника податків на сайті Державної податкової служби. І найпершим етапом реєстрації ПРРО є реєстрація Господарської одиниці, яка здійснюється шляхом заповнення Форми 20-ОПП. 

Якщо ж “головний офіс” вашого підприємства знаходиться за місцем вашого проживання можуть з’явитися питання — а чи треба заповнювати цю форму взагалі та що ж туди вписувати? 

Відповідь на “чи треба” на 100% ствердна. Відповідно до вимог пункту 63.3 статті 63 Податкового кодексу України, ФОП має подати повідомлення про об’єкти оподаткування або об’єкти, пов’язані з оподаткуванням (Форма 20-ОПП), повідомивши про місце проживання як про об’єкт оподаткування, після чого на такий об’єкт може бути зареєстроване РРО та/або ПРРО. 

Давайте розбиратися, що ж вписувати у Повідомлення у нашому випадку. 
У графі 3 Форми 20-ОПП потрібно вказати тип об’єкта оподаткування у кодовому форматі, який можна взяти з довідника на сайті ДПС. У випадку з житловими об’єктами це може бути: «682 квартира», «685 житловий будинок», «293 котедж» тощо. 
У полі 5 потрібно вказати Ідентифікатор об’єкта, який має складатися з 8 цифр. Перші три — код типу об’єкта оподаткування, який ви зазначили, а інших 5 — з вашого внутрішнього ідентифікатора об’єктів (наприклад 00001, якщо це ваш єдиний об’єкт оподаткування).
У графі 9 вкажіть фактичний стан — експлуатується, орендується, здається в оренду тощо (дивіться у Пам’ятку, яка знаходиться під формою). 
У 10 графі вкажіть право власності на житло, в якому здійснюєте підприємницьку діяльність. 
Це і все! Надішліть вашу форму та очікуйте квитанцію про успішне її прийняття. Далі можна переходити до наступних кроків реєстрації. 
`,
    chips: ['Про наш ПРРО'],
  },
  {
    articleId: 3,
    image: blog3,
    title: 'Чи потрібне РРО за наявності банківської «ключ-картки» у ФОПа?',
    date: '10 квітня 2023',
    description: `
    Насамперед хочеться зазначити, що реєстратор розрахункових операцій (РРО) потрібно застосовувати за умови наявності розрахункових операцій. Вони можуть бути у готівковій (за участі паперових грошей і монет) та безготівковій формі. Розрахункові операції у безготівковій формі — це всі продажі, за які клієнт розраховується карткою, готівкою або карткою через небанківський термінал, через платіжні системи, карткою через банківський термінал тощо. Всі (!) такі операції мають бути проведені через РРО. Після отримання оплати продавець має надіслати фіскальний чек.

Суть «ключ-картки» в тому, що вона прив’язується до рахунку підприємця і дозволяє приймати безготівкові платежі.
Здавалось би, що все однозначно. Платежі з використанням «ключ-картки» прирівнюються до безготівкових і не вимагають додаткової фіскалізіції. 
Але є ньюанс. Все впирається в технічну реалізацію отримання переказу банком. Деякі банки зразу скеровують клієнтські кошти із зарахуванням на рахунок за реквізитами IBAN. Як от «Ключ до рахунку» — сервіс «ПриватБанку», який надає прямий доступ до рахунку підприємства за IBAN кодом, і при цьому є повноцінною міжнародною банківською картою.
Якщо ж «ключ-картка» не має прив’язки до IBAN, то такі платежі будуть трактуватись як розрахунок готівкою і вимагають використання РРО при розрахунках з клієнтами.
Податкова не роз’яснює цих ньюансів, вона лише обмежується термінологією - готівковий і безготівковий платіж. Технічна ж реалізація прийому платежів - це відповідальність конкретного банку. Тому радимо підприємцям бути уважними до цих деталей, щоб уникнути штрафів за порушення законодавста щодо використання РРО.
`,
    chips: ['Новини від ДПС'],
  },
  {
    articleId: 4,
    image: blog4,
    title: 'Чи потрібен РРО для продажів в Інтернеті?',
    date: '4 березня 2023',
    description: `
    Таке питання виникло не з пустого місця, адже найчастіше за умов продажу онлайн оплата здійснюється також онлайн, тобто без участі паперових грошей, тобто безготівково. Тож, здавалося б, реєстратор не потрібний. 
Давайте розбиратися, чи це дійсно так. 

Насамперед хочеться зазначити, що реєстратор розрахункових операцій (РРО) потрібно застосовувати за умови наявності розрахункових операцій. Вони можуть бути у готівковій (за участі паперових грошей і монет) та безготівковій формі. Розрахункові операції у безготівковій формі — це всі продажі, за які клієнт розраховується карткою, готівкою або карткою через небанківський термінал, через платіжні системи, карткою через банківський термінал тощо. Всі (!) такі операції мають бути проведені через РРО. 
Зазвичай, інтернет-магазини проводять розрахунок на сайті з використанням платіжних систем як от LiqPay, Portmone, WayForPay або будь-якого іншого сервісу онлайн-оплати. Як ми вже зазначили вище, цей розрахунок не вважається безготівковим, відповідно, застосування РРО є обов’язковим.
Після отримання оплати продавець має надіслати фіскальний чек покупцю, не важливо електронний чи друкований (додати до посилки з товаром).
Якщо ж під час продажу клієнти сплачують вам на реквізити вашого поточного рахунку (у форматі ІВАN) зі свого рахунку, така операція не є розрахунковою, тож РРО можна не застосовувати.  Аналогічно, у випадку оплати товару згідно рахунку через касу банку, банківський термінал самообслуговування, такі операції вважаються безготівковими для продавця і ПРРО можна не застосовувати. 
Також, якщо ви здійснюєте онлайн-торгівлю з післяплатою на відділення поштового оператора (наприклад, Нова Пошта), то у разі наявності договору вашого ФОП з цим поштовим оператором, оплата за товар надходить з рахунку компанії перевізника на ваш поточний розрахунковий рахунок, тож операція не є розрахунковою для вас, тож застосування РРО не потрібне. 

Особливими випадками продажів через веб-сайти є продаж послуг або товарів, що передаються покупцю через Інтернет. Наприклад, продаж додатків, програмного забезпечення чи послуг розробки дизайн-проектів, у цих випадках підприємець також може не використовувати ПРРО.
`,
    chips: ['Реєстрація ПРРО'],
  },
  {
    articleId: 5,
    image: blog5,
    title: 'Ніколи не використовували РРО?',
    date: '05 лютого 2023',
    description: `
Як почати і не нашкодити собі? 

Отже, якщо згідно законодавства ви повинні використовувати РРО, то радимо не зволікати і розпочинати процес навчання  та впровадження якнайшвидше. Усі послаблення для бізнесу, що були запроваджені на час війни будуть скасовані вже в скорому часі. Але є й добрі новини. Закон дозволяє використовувати замість дорогих та незручних касових апаратів прості та дешеві в обслуговуванні програмні РРО, частина з яких мають тестовий режим. Зазвичай, тестовий режим є безкоштовним та не вимагає синхронізації з даними підприємця в кабінеті податкової.
Програмний РРО ФОПкаса у тестовому режимі теж встановлюється на телефон чи планшет, оновлюється автоматично та підтримує всі функції фіскального ПРРО за винятком надсилання інформації про проведені трансакції на сервер податкової.

Можливості тестового режиму ФОПкаса у смартфоні не обмежуються створенням чеків, ви також зможете покращувати показники, переглядаючи звітність щодо всіх життєво важливих для вашого бізнесу процесів, як от ціноутворення, статистики продажу товарів, середнього чеку, ефективності роботи касирів, відкриття та закриття змін та інше.
Що важливо, помилки, котрі виникають у початківців легко можна виправляти, в процесі навчання персоналу без шкоди для бізнесу. 
`,
    chips: ['Корисно знати'],
  },
];

export default articles;
