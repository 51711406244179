import React, { FC, useContext, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { connect } from 'react-redux';

import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

import CardActions from 'containers/Authorization/components/Card/CardActions';
import CardHeader from 'containers/Authorization/components/Card/CardHeader';
import KeyUpload from 'containers/Authorization/components/KeyUpload';

import { RegistrationContext, Steps } from './Registration';

import { ApplicationState } from 'store';
import { actionCreators, FormValues } from 'store/DigitalSign';
import analytics from 'services/analytics';
import { AnalyticsEvents } from 'shared/constants/analytics';

interface ReduxProps {
  uploadDigitalSign: (payload: FormValues, callBack: () => void) => void,
  removeErrors: () => void,
  uploadDigitalSignError: string
}

const PeKeyUpload: FC<ReduxProps> = props => {
  const { uploadDigitalSignError } = props;
  const { setStep } = useContext(RegistrationContext);
  const methods = useForm<FormValues>();
  const watchFiles = methods.watch('files');

  const onKeyUpload = ({ files, password }: FormValues) => {
    props.uploadDigitalSign({ files, password }, () => setStep(Steps.keysData));
    analytics().sendEvent(AnalyticsEvents.registration_key_upload);
  };

  useEffect(() => {
    if (uploadDigitalSignError) {
      methods.setError('password', { message: uploadDigitalSignError })
    }
    props.removeErrors();
  }, [uploadDigitalSignError]);

  return (
    <>
      <CardHeader title='Завантаження ключа КЕП/ЕЦП' />
      <CardContent>
        <FormProvider {...methods}>
          <KeyUpload />
        </FormProvider>
      </CardContent>
      <CardActions sx={{ justifyContent: 'space-between' }}>
        <Button
            onClick={() => {
              setStep(Steps.terms);
              analytics().sendEvent(AnalyticsEvents.registration_key_go_back);
            }}
            size='large'
            variant='contained'
            color='inherit'>
          Назад
        </Button>
        <Box>
          <Button
              onClick={() => {
                setStep(Steps.createCompany);
                analytics().sendEvent(AnalyticsEvents.registration_key_upload_later);
              }}
              size='large'
              variant='contained'
              color='inherit'>
            Завантажити пізніше
          </Button>
          <Button
            sx={{ ml: 2 }}
            onClick={methods.handleSubmit(onKeyUpload)}
            disabled={!watchFiles || !watchFiles.length}
            size='large'
            variant='contained'>
            Завантажити
          </Button>
        </Box>
      </CardActions>
    </>
  );
};

const mapStateToProps = ({ digitalSign: { errors } }: ApplicationState) => ({
  uploadDigitalSignError: errors.uploadDigitalSign,
});

const mapDispatchToProps = {
  uploadDigitalSign: actionCreators.uploadDigitalSign,
  removeErrors: actionCreators.removeErrors,
};

export default connect(mapStateToProps, mapDispatchToProps)(PeKeyUpload);
