import React, { FC, useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { connect } from 'react-redux';

import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Box from "@mui/material/Box";

import { FormInput, InputEvent } from 'components/FormInput'
import { CashierContext, Steps } from 'contexts/CashierContext';
import { ApplicationState } from 'store';
import { actionCreators } from 'store/Cashiers';
import getNumbersFormString from 'shared/functions/getNumbersFormString';
import { ChangeUserPhoneRequest, CheckCodeRequest } from 'api/models';
import { States } from "../Table";
import analytics from 'services/analytics';
import { AnalyticsEvents } from 'shared/constants/analytics';

type FormValues = {
  code: string;
};

interface ReduxProps {
  cashierPhone: string,
  cashierState: number,
  cashierUserUid: string,
  codeError: string,
  sendCashierCode: (data: CheckCodeRequest, callBack: () => void) => void,
  changeCashierPhoneNumber: (data: ChangeUserPhoneRequest, callBack: () => void) => void,
  removeErrors: () => void,
}

const Verification: FC<ReduxProps> = props => {
  const { cashierPhone, cashierState, cashierUserUid, codeError } = props;
  const { setStep, onCloseModal } = useContext(CashierContext);

  useEffect(() => {
    if (codeError) {
      methods.setError('code', { message: codeError })
    }
    props.removeErrors();
  }, [codeError]);

  const onSubmit = (data: FormValues) => {
    if (cashierState == States.active) {
      props.changeCashierPhoneNumber(
        {
          code: data.code,
          phone: cashierPhone,
          userUid: cashierUserUid
        },
        () => onCloseModal());
      analytics().sendEvent(AnalyticsEvents.cashiers_cashier_edit_code_verification_send_code);
    } else {
      props.sendCashierCode(
        { ...data, phone: cashierPhone },
        () => setStep(Steps.uploadDocument));
    }
  };

  const onChange = ({ target }: InputEvent) => {
    methods.setValue('code', getNumbersFormString(target.value).substring(0, 4));
  };

  const methods = useForm<FormValues>({
    mode: 'onChange'
  });

  return (
    <>
      <DialogContent dividers>
        <Typography
          onClick={() => setStep(Steps.phoneNumber)}
          fontWeight={300}
          mb={3}
          sx={{ cursor: 'pointer' }}
          color={({ colors }) => colors.body}
        >
          На номер мобільного телефону {cashierPhone} був надісланий код підтвердження.
          <Link ml={1} fontWeight={500} underline='none' mb={3}>Змінити номер телефону</Link>
        </Typography>
        <FormProvider {...methods}>
          <Typography
            ml={2}
            mb={.5}
            color={({ colors }) => colors.secondaryGrey}
            fontSize='0.8571428571428571rem'>
            ВВЕДІТЬ КОД ПІДТВЕРДЖЕННЯ:
          </Typography>
          <FormInput
            placeholder='4 цифри із СМС'
            fullWidth
            name='code'
            rules={{
              minLength: {
                value: 4,
                message: ''
              },
              onChange,
            }}
            autoFocus={true}
          />
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          setStep(Steps.phoneNumber);
          analytics().sendEvent(AnalyticsEvents.cashiers_cashier_edit_code_verification_send_code_go_back);
        }} size='large' variant='contained' color='inherit'>Повернутись</Button>
        <Box marginLeft='auto'>
          <Button onClick={() => {
            onCloseModal();
            analytics().sendEvent(AnalyticsEvents.cashiers_cashier_edit_code_verification_send_code_cancel);
          }} size='large' variant='contained' color='inherit'>Відмінити</Button>
          <Button
            sx={{ ml: 2 }}
            onClick={methods.handleSubmit(onSubmit)}
            disabled={
              !methods.getValues('code') ||
              !!Object.keys(methods.formState.errors).length
            }
            size='large'
            variant='contained'
          >
            Продовжити
          </Button>
        </Box>
      </DialogActions>
    </>
  );
};

const mapStateToProps = ({ cashiers: { cashier, errors } }: ApplicationState) => ({
  cashierPhone: cashier.phone,
  cashierState: cashier.state,
  cashierUserUid: cashier.userUid,
  codeError: errors.code
});

const mapDispatchToProps = {
  sendCashierCode: actionCreators.sendCashierCode,
  changeCashierPhoneNumber: actionCreators.changeCashierPhoneNumber,
  removeErrors: actionCreators.removeErrors,
};

export default connect(mapStateToProps, mapDispatchToProps)(Verification);
