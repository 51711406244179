import { Action, Reducer } from 'redux';
import { ReportApi } from '../api'
import {
  ReportByProduct, ReportByReceipt, ReportByWorkShift,
  ReportByIncome, ReportByOperation, ReportByReceiptAndProduct
} from '../api/models'
import { AppThunkAction } from './';

const reportApi = new ReportApi();

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ReportState {
  productReport: ReportByProduct[];
  receiptReport: ReportByReceipt[];
  workShiftReport: ReportByWorkShift[];
  incomeReport: ReportByIncome[];
  operationReport: ReportByOperation[];
  returnReport: ReportByReceiptAndProduct[];
}

enum ReportActionTypes {
  SET_PRODUCT_REPORTS = 'SET_PRODUCT_REPORTS',
  SET_RECEIPT_REPORTS = 'SET_RECEIPT_REPORTS',
  SET_WORK_SHIFT_REPORTS = 'SET_WORK_SHIFT_REPORTS',
  SET_INCOME_REPORTS = 'SET_INCOME_REPORTS',
  SET_OPERATION_REPORTS = 'SET_OPERATION_REPORTS',
  SET_RETURN_REPORTS = 'SET_RETURN_REPORTS',
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface SetProductReportsAction { type: ReportActionTypes.SET_PRODUCT_REPORTS, payload: ReportByProduct[] }
export interface SetReceiptReportsAction { type: ReportActionTypes.SET_RECEIPT_REPORTS, payload: ReportByReceipt[] }
export interface SetWorkShiftReportsAction { type: ReportActionTypes.SET_WORK_SHIFT_REPORTS, payload: ReportByWorkShift[] }
export interface SetIncomeReportsAction { type: ReportActionTypes.SET_INCOME_REPORTS, payload: ReportByIncome[] }
export interface SetOperationReportsAction { type: ReportActionTypes.SET_OPERATION_REPORTS, payload: ReportByOperation[] }
export interface SetReturnReportsAction { type: ReportActionTypes.SET_RETURN_REPORTS, payload: ReportByReceiptAndProduct[] }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = 
  SetProductReportsAction |
  SetReceiptReportsAction |
  SetWorkShiftReportsAction |
  SetIncomeReportsAction |
  SetOperationReportsAction |
  SetReturnReportsAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    setProductReports: (payload: ReportByProduct[]): SetProductReportsAction => ({ type: ReportActionTypes.SET_PRODUCT_REPORTS, payload }),
    setReceiptReports: (payload: ReportByReceipt[]): SetReceiptReportsAction => ({ type: ReportActionTypes.SET_RECEIPT_REPORTS, payload }),
    setWorkShiftReports: (payload: ReportByWorkShift[]): SetWorkShiftReportsAction => ({ type: ReportActionTypes.SET_WORK_SHIFT_REPORTS, payload }),
    setIncomeReports: (payload: ReportByIncome[]): SetIncomeReportsAction => ({ type: ReportActionTypes.SET_INCOME_REPORTS, payload }),
    setOperationReports: (payload: ReportByOperation[]): SetOperationReportsAction => ({ type: ReportActionTypes.SET_OPERATION_REPORTS, payload }),
    setReturnReport: (payload: ReportByReceiptAndProduct[]): SetReturnReportsAction => ({ type: ReportActionTypes.SET_RETURN_REPORTS, payload }),
    getReports: (
      { reportType, startDate, endDate, pointOfSaleId, cashRegisterId, partnerUid } : {
        reportType: string,
        startDate?: Date,
        endDate?: Date,
        pointOfSaleId?: number,
        cashRegisterId?: number
        partnerUid?: string
      }
    ): AppThunkAction<KnownAction> => async (dispatch) => {
      switch (reportType) {
        case 'product': {
          const { data } = await reportApi.apiReportProductGet(startDate, endDate, pointOfSaleId, cashRegisterId, partnerUid);

          dispatch(actionCreators.setProductReports(data));
          break;
        }

        case 'receipt': {
          const { data } = await reportApi.apiReportReceiptGet(startDate, endDate, pointOfSaleId, cashRegisterId, partnerUid);

          dispatch(actionCreators.setReceiptReports(data));
          break;
        }

        case 'workShift': {
          const { data } = await reportApi.apiReportWorkShiftGet(startDate, endDate, pointOfSaleId, cashRegisterId, partnerUid);
          
          dispatch(actionCreators.setWorkShiftReports(data));
          break;
        }

        case 'income': {
          const { data } = await reportApi.apiReportIncomeGet(startDate, endDate, pointOfSaleId, cashRegisterId, partnerUid);

          dispatch(actionCreators.setIncomeReports(data));
          break;
        }

        case 'operation': {
          const { data } = await reportApi.apiReportOperationGet(startDate, endDate, pointOfSaleId, cashRegisterId, partnerUid);

          dispatch(actionCreators.setOperationReports(data));
          break;
        }
        case 'return': {
          const { data } = await reportApi.apiReportReturnReceiptGet(startDate, endDate, pointOfSaleId, cashRegisterId, partnerUid);

          dispatch(actionCreators.setReturnReport(data));
          break;
        }
        default:
          break;
      }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const defaultState: ReportState = {
  productReport: [],
  receiptReport: [],
  workShiftReport: [],
  incomeReport: [],
  operationReport: [],
  returnReport: []
};

export const reducer: Reducer<ReportState> = (state: ReportState = defaultState, incomingAction: Action): ReportState => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case ReportActionTypes.SET_PRODUCT_REPORTS:
          return { ...state, productReport: action.payload };
        case ReportActionTypes.SET_RECEIPT_REPORTS:
          return { ...state, receiptReport: action.payload };
        case ReportActionTypes.SET_WORK_SHIFT_REPORTS:
          return { ...state, workShiftReport: action.payload };
        case ReportActionTypes.SET_INCOME_REPORTS:
          return { ...state, incomeReport: action.payload };
        case ReportActionTypes.SET_OPERATION_REPORTS:
          return { ...state, operationReport: action.payload };
      case ReportActionTypes.SET_RETURN_REPORTS:
        return { ...state, returnReport: action.payload };
        default:
          return state;
    }
};
