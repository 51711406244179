import React from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { ReportTable, HeadCell } from '../../components/Table';
import { useReportColumns } from '../../hooks';

const headers: HeadCell[] = [
  {
    id: 'dateTimeUtc',
    label: 'Дата',
    cell: (value) => dayjs.utc(value).local().format('DD/MM/YYYY HH:mm'),
  },
  {
    id: 'amount',
    label: 'Сума, ₴',
    align: 'right',
  },
  {
    id: 'comment',
    label: 'Коментар',
  },
];

const WorkShiftReport: React.FC = () => {
  const rows = useSelector(state => state.reports.operationReport);
  const columns = useReportColumns(headers, rows);

  return (
    <ReportTable
      idName='order'
      rows={rows}
      columns={columns}
    />
  )
};

export default WorkShiftReport;
