import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { styled, ThemeOptions } from '@mui/material/styles';
import Link from '@mui/material/Link';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import UserMenu from './UserMenu';
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import Container from '@mui/material/Container';

import ResponsiveMenu from './ResponsiveMenu';
import analytics from 'services/analytics';
import { AnalyticsEvents } from 'shared/constants/analytics';

const AppBarMui = styled(AppBar)(({ theme }) => ({
  position: 'sticky',
  height: 56,
  background: theme.palette.common.white,
  display: 'flex',
  boxShadow: '0 4px 8px rgba(95, 98, 115, 0.16)',
  flexDirection: 'row',
}));

const styles = {
  headerContainer: {
    alignItems: 'center',
    display: 'flex',
  },
  linksContainer: {
    marginLeft: '36px',
    display: { xs: 'none', md: 'block' }
  },
  buttonsContainer: {
    marginLeft: 'auto',
    display: { xs: 'none', md: 'block' }
  },
  responsiveMenuContainer: {
    marginLeft: 'auto',
    display: { xs: 'block', md: 'none' }
  }
};

const Header: FC<RouteComponentProps> = props => {
  const authorized = useSelector(state => state.user.authorized);
  const landingPage = props.location.pathname == '/';

  const renderLinks = () => {
    if (authorized && !landingPage) {
      const styles = (path: string) => ({ colors }: ThemeOptions) => (
        props.location.pathname == path ? {
          cursor: 'default',
          color: colors.secondaryBlue,
          marginRight: '40px'
        } : {
          cursor: 'pointer',
          marginRight: '40px'
        }
      );

      return (
        <>
          <Link
            sx={styles('/reports')}
            onClick={() => props.history.push('/reports')}
            color={(theme) => theme.colors.body}
            underline="none"
          >
            Звіти
          </Link>
          <Link
            sx={styles('/business-structure')}
            onClick={() => props.history.push('/business-structure')}
            color={(theme) => theme.colors.body}
            underline="none"
          >
            Мій Бізнес
          </Link>
          <Link
            sx={styles('/cashiers')}
            onClick={() => props.history.push('/cashiers')}
            color={(theme) => theme.colors.body}
            underline="none"
          >
            Касири
          </Link>
          <Link
            sx={styles('/products')}
            onClick={() => props.history.push('/products')}
            color={(theme) => theme.colors.body}
            underline="none"
          >
            Товари
          </Link>
          <Link
            sx={styles('/channels')}
            onClick={() => props.history.push('/channels')}
            color={(theme) => theme.colors.body}
            underline="none"
          >
            Канали
          </Link>
          <Link
            sx={styles('/settings')}
            onClick={() => props.history.push('/settings')}
            color={(theme) => theme.colors.body}
            underline="none"
          >
            Налаштування
          </Link>
          <Link
            sx={styles('/subscription')}
            onClick={() => props.history.push('/subscription')}
            color={(theme) => theme.colors.body}
            underline="none"
          >
            Мій Тариф
          </Link>
        </>
      );
    }

    return (
      <>
        <Link
          href='#about'
          sx={{ marginRight: '40px' }}
          underline='none'
          color={(theme) => theme.colors.body}
        >
          Що таке ФОПкаса
        </Link>
        <Link
          href='#organization'
          sx={{ marginRight: '40px' }}
          underline="none"
          color={(theme) => theme.colors.body}
        >
          Переваги
        </Link>
        <Link
          href='/blog'
          sx={{ marginRight: '40px' }}
          underline="none"
          color={(theme) => theme.colors.body}
        >
          Блог
        </Link>
      </>
    )
  };

  const renderButtons = () => {
    if (landingPage && authorized) {
      return (
        <Button
          onClick={() => {
            props.history.push('/business-structure');
            analytics().sendEvent(AnalyticsEvents.menu_login_to_myaccount);}}
          variant='contained'
          color='secondary'
        >
          Увійти в кабінет
        </Button>
      );
    }

    if (authorized) {
      return <UserMenu />;
    }

    return (
      <>
        <Button
          onClick={() => {
            props.history.push('/login');          
            analytics().sendEvent(AnalyticsEvents.hp_login);}}
          variant='contained'
          color='secondary'
        >
          Увійти в кабінет
        </Button>
      </>
    )
  }

  return (
    <AppBarMui>
      <Container sx={styles.headerContainer}>
        <Logo
          style={{ cursor: 'pointer' }}
          onClick={() => props.history.push('/')}/>
        <Box sx={styles.linksContainer}>
          {renderLinks()}
        </Box>
        <Box sx={styles.buttonsContainer}>
          {renderButtons()}
        </Box>
        <Box sx={styles.responsiveMenuContainer}>
          <ResponsiveMenu/>
        </Box>
      </Container>
    </AppBarMui>
  )
};

export default withRouter(Header);
