import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Box from '@mui/material/Box';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { actionCreators } from 'store/User';

import { ReactComponent as IconUser } from 'assets/images/icon_user.svg';

const UserMenu: React.FC = () => {
  const { fullName } = useSelector(state => state.user.data);
  const dispatch = useDispatch();
  const onChange = (e: SelectChangeEvent) => {
    switch (e.target.value) {
      case 'logout':
        return dispatch(actionCreators.logout());
  
      default:
        break;
    }
  };

  return (
    <Select
      size='small'
      sx={{
        '.MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: 'none',
          boxShadow: 'none',
        },
      }}
      value=''
      displayEmpty
      onChange={onChange}
      renderValue={() => {
        return (
          <Box display='flex' alignItems='center'>
            <Box mr={1}><IconUser /></Box>
            {fullName}
          </Box>
        );
      }}
    >
      <MenuItem value='logout' sx={{ color: (theme) => theme.colors.secondaryRed }}>
        Вийти
      </MenuItem>
    </Select>
  );
};

export default UserMenu;
