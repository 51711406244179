import * as React from 'react';
import './index.scss';

interface ButtonProps {
    className?: string;
    type?: 'button' | 'submit' | 'reset';
    onClick?: () => void;
    disabled?: boolean;
    children: React.ReactNode;
}

const Button: React.FC<ButtonProps> = ({
    className = '',
    type = 'button',
    onClick = () => undefined,
    disabled = false,
    children
}) => (
    <button
        className={`button ${className}`}
        type={type}
        onClick={onClick}
        disabled={disabled}>
        {children}
    </button>
);

export default Button;
