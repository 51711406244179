import * as React from 'react';
import {useState} from 'react';
import Rocket from '../../../../assets/images/rocket.png';
import Button from '../../../../components/Button';
import InputPhone from '../../../../components/Input/Phone';
import { PreOrderApi } from '../../../../api'
import CallModal from './components/Modal';
import './index.scss';
import analytics from 'services/analytics';
import { AnalyticsEvents } from 'shared/constants/analytics';

const api = new PreOrderApi();

const CallForm: React.FC = () => {
    const [phone, setPhone] = useState<string>('');
    const [isCall, setIsCall] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const onCloseModal = () => {
        setIsModalOpen(false);
    };

    const onChangePhone = (phone: string) => {
        if (phone.length === 12) {
            setPhone(phone);
        } else {
            setPhone('');
        }
    };

    const onCallButton = () => {
        if (phone) {
            setIsCall(true);
            setIsModalOpen(true);
            api.preOrderAddContactPost(phone)
                .then(() => setIsCall(false))
                .catch(() => setIsCall(false));
            analytics().sendEvent(AnalyticsEvents.hp_call_me_back);
        }
    };

    return (
        <section className="call-form" id="call-form">
            <img className="call-form__image" alt="rocket" src={Rocket} />
            <div className="call-form__text-wrapper">
                <h2 className="header-small call-form__header">
                    Переконайтеся самі у всіх перевагах{' '}
                    <img className="call-form__image--small" alt="rocket" src={Rocket} />
                </h2>
                <p className="text-normal call-form__text">
                    Залиште свій номер телефону і менеджер зателефонує Вам!
                </p>
            </div>
            <InputPhone className="call-form__input" onChange={onChangePhone} />
            <Button
                className="button--blue call-form__button"
                onClick={onCallButton}
                disabled={isCall}
            >
                Зателефонуйте мені
            </Button>
            <CallModal isOpen={isModalOpen} closeModal={onCloseModal} phone={phone} />
        </section>
    );
};

export default CallForm;
