import React from 'react';

interface IProps {
  children?: React.ReactNode;
  index: number | string;
  value: number | string;
}

const TabPanel: React.FC<IProps> = ({ children, value, index }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel-${index}`}
    aria-labelledby={`tab-${index}`}
  >
    {value === index && children}
  </div>
);

export default TabPanel
