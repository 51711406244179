import { useEffect } from 'react'

import analytics from 'services/analytics'

export const useAnalytics = (): void => {
  useEffect(() => {
    analytics().initialize();
  }, []);

  // useEffect(() => {
  //   const path = location.pathname + location.search;
  //   analytics().sendPageview(path);
  // }, [location]);
}

export default useAnalytics;
