import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Table from 'containers/Cashiers/Table';
import Box from '@mui/material/Box';
import CashierModal from './Cashier';
import { actionCreators } from 'store/Cashiers';
import { useDispatch } from 'react-redux';

const Cashiers: React.FC = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const dispatch = useDispatch()

  const onCloseModal = () => setIsOpenModal(false);
  const onOpenModal = () => setIsOpenModal(true);

  useEffect(() => {
    dispatch(actionCreators.getCashiers());
  }, []);

  return (
    <>
      <Box
        display='flex'
        flexDirection='row'
        justifyContent='space-between'
        alignItems='center'
      >
        <Typography variant='h4' mt={3} mb={2} component='h1'>Касири</Typography>
        {/* TODO: Temporarily annotated code */}
        {/*<Button*/}
        {/*  onClick={onOpenModal}*/}
        {/*  size='large'*/}
        {/*>*/}
        {/*  Додати касира*/}
        {/*</Button>*/}
      </Box>
      <CashierModal
        {...{ onCloseModal, isOpenModal }}
      />
      <Table {...{ onOpenModal } } />
    </>
  );
};

export default Cashiers;
