import { Action, Reducer } from 'redux';
import { ApiError } from 'types';
import { TelegramChatApi } from '../api'
import { TelegramChatResponse, CreateTelegramChatRequest, DeleteTelegramChatRequest } from '../api/models'
import { AppThunkAction } from './';
import { actionCreators as notificationActions, SetNotificationAction } from './Notification';

const telegramChatApi = new TelegramChatApi();

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ChannelState {
  channels: TelegramChatResponse[];
}

enum ChannelActionTypes {
  SET_CHANNELS = 'SET_CHANNELS'
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface SetChannelsAction { type: ChannelActionTypes.SET_CHANNELS, payload: TelegramChatResponse[] }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = SetChannelsAction | SetNotificationAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    setChannels: (payload: TelegramChatResponse[]): SetChannelsAction => ({ type: ChannelActionTypes.SET_CHANNELS, payload }),
    getChannels: (): AppThunkAction<KnownAction> => async (dispatch) => {
      try {
        const { data } = await telegramChatApi.apiTelegramChatGet();

        dispatch(actionCreators.setChannels(data));
      } catch ({ response }) {
        const { data } = response as ApiError

        dispatch(notificationActions.setNotification({ message: data.title, severity: 'error' }));
      }
    },
    addChannel: (body: CreateTelegramChatRequest, callBack?: (hasError: boolean) => void): AppThunkAction<KnownAction> => async (dispatch) => {
      try {
        await telegramChatApi.apiTelegramChatPost(body);

        if (callBack) {
          callBack(false);
        }
      } catch ({ response }) {
        const { data } = response as ApiError

        if (callBack) {
          callBack(true);
        }

        dispatch(notificationActions.setNotification({ message: data.title, severity: 'error' }));
      }
    },
    updateChannel: (body: TelegramChatResponse, callBack?: (hasError: boolean) => void): AppThunkAction<KnownAction> => async (dispatch) => {
      try {
        await telegramChatApi.apiTelegramChatPut(body);

        if (callBack) {
          callBack(false);
        }
      } catch ({ response }) {
        const { data } = response as ApiError

        if (callBack) {
          callBack(true);
        }

        dispatch(notificationActions.setNotification({ message: data.title, severity: 'error' }));
      }
    },
    deleteChannel: (body: DeleteTelegramChatRequest, callBack?: (hasError: boolean) => void): AppThunkAction<KnownAction> => async (dispatch) => {
      try {
        await telegramChatApi.apiTelegramChatDelete(body);

        if (callBack) {
          callBack(false);
        }
      } catch ({ response }) {
        const { data } = response as ApiError

        if (callBack) {
          callBack(true);
        }

        dispatch(notificationActions.setNotification({ message: data.title, severity: 'error' }));
      }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const defaultState: ChannelState = { channels: [] };

export const reducer: Reducer<ChannelState> = (state: ChannelState = defaultState, incomingAction: Action): ChannelState => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case ChannelActionTypes.SET_CHANNELS:
            return { ...state, channels: action.payload };
        default:
            return state;
    }
};
