import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

const TermsOfService: React.FC = () => {
  return (
    <>
      <Typography component='h1' fontWeight={700} fontSize={{ xs: 32, md: 48 }} mt={4} mb={2}>УМОВИ КОНФІДЕНЦІЙНОСТІ</Typography>
      <Typography mb={1} fontWeight='lighter'>Правовласник гарантує, що Застосунок FOPKASA забезпечує дотримання принципу конфіденційності щодо інформації про Користувачів.</Typography>
      <Typography mb={1} fontWeight='lighter'>Для надання повного та якісного обсягу послуг при завантаженні застосунку (при користуванні застосунком) Користувачі надають наступну інформацію:</Typography>
      <Box component='ul' ml={3} mb={1}>
        <Box component='li'><Typography fontWeight='lighter'>прізвище, ім`я, по батькові (за наявності) фізичної особи, адреса електронної пошти, номер телефону;</Typography></Box>
        <Box component='li'><Typography fontWeight='lighter'>інша додаткова інформація, необхідна для використання застосунку.</Typography></Box>
      </Box>
      <Typography mb={1} fontWeight='lighter'>У процесі користування застосунком здійснюється автоматичне збирання наступної інформації:</Typography>
      <Box component='ul' ml={3} mb={1}>
        <Box component='li'><Typography fontWeight='lighter'>технічна інформація про пристрій, на який встановлено застосунок;</Typography></Box>
        <Box component='li'><Typography fontWeight='lighter'>дані користувача, які користувач самостійно вніс в застосунок;</Typography></Box>
        <Box component='li'><Typography fontWeight='lighter'>статистика використання застосунку та інші дані, до яких користувач дозволив доступ застосунку (геолокація і т д).</Typography></Box>
      </Box>
      <Typography mb={1} fontWeight='lighter'>Застосунок може збирати і іншу інформацію Користувача та про Користувача відповідно до Публічної оферти (Умов користування), на яку погодився Користувач, скачавши Застосунок.</Typography>
      <Typography mb={1} fontWeight='lighter'>Правовласник використовує особисту інформацію Користувачів для:</Typography>
      <Box component='ul' ml={3} mb={1}>
        <Box component='li'><Typography fontWeight='lighter'>вдосконалення застосунку;</Typography></Box>
        <Box component='li'><Typography fontWeight='lighter'>виявлення та запобігання шахрайству, спаму, зловживанням, інцидентам безпеки та іншій шкідливій діяльності під час використання застосунку;</Typography></Box>
      </Box>
      <Typography mb={1} fontWeight='lighter'>Користувач несе відповідальність за правильність введення особистої інформації.</Typography>
      <Typography mb={1} fontWeight='lighter'>Використовуючи застосунок (мобільний додаток), Користувач надає згоду на доступ, обробку, поширення та використання персональних даних (інформації) про себе, які він/вона надає Правовласнику під час завантаження (та/або використання) застосунку.</Typography>
      <Typography mb={1} fontWeight='lighter'>Персональні дані Користувачів обробляються виключно в рамках вимог Закону України «Про захист персональних даних». Доступ третім особам до персональних даних надається Правовласником лише у випадках, прямо передбачених чинним законодавством України.</Typography>
      <Typography mb={1} fontWeight='lighter'>Вся інформація від Користувачів, яка збирається Правовласником, захищена технічними засобами і процедурами забезпечення безпеки з метою запобігання несанкціонованому доступу або використання даних.</Typography>
      <Typography mb={1} fontWeight='lighter'>Вся зібрана застосунком інформація від користувачів може охоронятися як комерційна таємниця в порядку, визначеному законом.</Typography>
      <Typography mb={5} fontWeight='lighter'>
        Інші відносини між Користувачем та Правовласником врегульовані Публічною офертою (Умовами користування Застосунком FOPKASA), яка міститься на веб-сайті <Link target='_blank' underline='none' href='https://fopkasa.com/'>https://fopkasa.com/</Link> та/або у Застосунку FOPKASA. Всі поняття, які вживаються в Умовах конфіденційності, та умови використання Застосунку вказані в умовах Публічної оферти.
      </Typography>
    </>
  )
}

export default TermsOfService;
