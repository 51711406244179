import * as React from 'react';
import Phones from 'assets/images/phones.png';
import Typography from '@mui/material/Typography';

import './index.scss';

const organizationList: Array<string> = [
  'Надсилати чеки клієнтам у повідомленнях;',
  'Відстежувати статистику продажів за різні періоди;',
  'Контролювати продажі та ефективність продавців;',
  'Автоматизувати облік товарів.'
];

const Organization: React.FC = () => (
    <div className="organization" id='organization'>
        <div className="organization__text-wrapper">
            <h2 className="header-normal organization__header">Витончено керуйте бізнесом із сучасним ПРРО</h2>
            <Typography mb={2} fontSize={18}>
              ФОПкаса — зрозумілий інструмент для організації вашого бізнесу та мінімізації паперового обліку. Потрібен лише смартфон, щоб:
            </Typography>
            <ul className="organization__list">
                {organizationList.map(item => (
                    <li key={item} className="text-normal organization__item">{item}</li>
                ))}
            </ul>
        </div>
        <img className="organization__image" alt="Phone" src={Phones} />
    </div>
);

export default Organization;
