import Redux, { AnyAction } from 'redux';
import { ApplicationState } from 'store';

export default class ReduxHelper {
  private static store: Redux.Store

  static getState(): ApplicationState {
    return this.store.getState();
  }
  
  static setStore(store: Redux.Store): void {
    this.store = store
  }

  // eslint-disable-next-line
  static dispatch(f: any): AnyAction {
    return this.store.dispatch(f);
  }
}
