import React, { FC, useContext } from 'react';
import { DialogContent, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import Link from '@mui/material/Link';
import { CashierContext, Steps } from 'contexts/CashierContext';

const Edit: FC = () => {
  const emptyValue = 'Немає інформації';
  const { setStep } = useContext(CashierContext);
  const {
    fullName,
    phone,
    keyId,
    tin,
  } = useSelector(state => state.cashiers.cashier);

  return (
    <DialogContent dividers>
      <Typography
        mb={1.7}
        color={({ colors }) => colors.secondaryGrey}
        fontSize='0.8571428571428571rem'>
        ІМ&apos;Я КАСИРА:
      </Typography>
      <Typography fontWeight={300}>{fullName || emptyValue}</Typography>
      <Typography
        mt={2}
        mb={1.7}
        color={({ colors }) => colors.secondaryGrey}
        fontSize='0.8571428571428571rem'>
        ЄДРПОУ:
      </Typography>
      <Typography fontWeight={300}>{tin || emptyValue}</Typography>
      <Typography
        mt={2}
        mb={1.7}
        color={({ colors }) => colors.secondaryGrey}
        fontSize='0.8571428571428571rem'>
        ІДЕНТИФІКАТОР КЛЮЧА:
      </Typography>
      <Typography fontWeight={300}>
        {keyId || emptyValue}<Link onClick={() => setStep(Steps.uploadDocument)} ml={.5} fontWeight={500}>Змінити</Link>
      </Typography>
      <Typography
        mt={2}
        mb={1.7}
        color={({ colors }) => colors.secondaryGrey}
        fontSize='0.8571428571428571rem'>
        ТЕЛЕФОН КАСИРА:
      </Typography>
      <Typography fontWeight={300}>
        +{phone} <Link onClick={() => setStep(Steps.phoneNumber)} fontWeight={500}>Змінити</Link>
      </Typography>
    </DialogContent>
  );
};

export default Edit;
