import React, { FC, useContext } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import dayjs from 'dayjs';

import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';

import CardActions from 'containers/Authorization/components/Card/CardActions';
import CardHeader from 'containers/Authorization/components/Card/CardHeader';

import { RegistrationContext, Steps } from './Registration';

import { ApplicationState } from 'store';
import { DigitalSignInfoResponse } from 'api/models';
import { actionCreators } from 'store/Registration';
import analytics from 'services/analytics';
import { AnalyticsEvents } from 'shared/constants/analytics';

interface ReduxProps {
    info: DigitalSignInfoResponse,
    register: (callBack: () => void) => void
}

const KeysData: FC<ReduxProps> = props => {
    const { name, ipn, validFromUtc, validToUtc, hasSfsData } = props.info;
    const { setStep } = useContext(RegistrationContext);
    const history = useHistory();

    const registration = () => {
        if (hasSfsData) {
            props.register(() => history.push('/business-structure'));
            analytics().sendEvent(AnalyticsEvents.registration_key_data_register_company);
        } else {
            setStep(Steps.createCompany);
            analytics().sendEvent(AnalyticsEvents.registration_key_data_create_company);
        }
    };

    return (
        <>
            <CardHeader title='Дані ключа' />
            <CardContent>
                <Typography
                    mb={.5}
                    color={({ colors }) => colors.secondaryGrey}
                    fontSize='0.8571428571428571rem'>
                    ІМ&apos;Я:
                </Typography>
                <Typography>{name}</Typography>
                <Typography
                    mt={1.5}
                    mb={.5}
                    color={({ colors }) => colors.secondaryGrey}
                    fontSize='0.8571428571428571rem'>
                    ЄДРПОУ/ІПН:
                </Typography>
                <Typography>{ipn}</Typography>
                <Typography
                    mt={1.5}
                    mb={.5}
                    color={({ colors }) => colors.secondaryGrey}
                    fontSize='0.8571428571428571rem'>
                    ТЕРМІН ДІЇ:
                </Typography>
                <Typography>
                    {dayjs(validFromUtc).format('DD.MM.YYYY')} - {dayjs(validToUtc).format('DD.MM.YYYY')}
                </Typography>
            </CardContent>
            <CardActions sx={{ justifyContent: 'space-between' }}>
                <Button
                    onClick={() => {
                        setStep(Steps.uploadDocument);
                        analytics().sendEvent(AnalyticsEvents.registration_key_data_go_back);
                    }}
                    size='large'
                    variant='contained'
                    color='inherit'>
                    Назад
                </Button>
                <Button
                    onClick={registration}
                    sx={{ ml: 2 }}
                    size='large'
                    variant='contained'
                >
                    {hasSfsData ?  'Завершити' : 'Продовжити' }
                </Button>
            </CardActions>
        </>
    );
};

const mapStateToProps = ( { digitalSign: { info } }: ApplicationState) => ({ info });
const mapDispatchToProps = { register: actionCreators.register };

export default connect(mapStateToProps, mapDispatchToProps)(KeysData);
