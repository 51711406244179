import React, { FC, useContext, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';

import CardActions from 'containers/Authorization/components/Card/CardActions';
import CardHeader from 'containers/Authorization/components/Card/CardHeader';

import {RegistrationApi} from 'api'
import { RegistrationContext, Steps } from './Registration';
import analytics from 'services/analytics';
import { AnalyticsEvents } from 'shared/constants/analytics';

const registrationApi = new RegistrationApi();

const Terms: FC = () => {
    const { setStep } = useContext(RegistrationContext)
    const [termsOfService, setTermsOfService] = useState('');

    useEffect(() => {
        (async () => {
            const { data } = await registrationApi.apiRegistrationTermsOfServiceGet();
            setTermsOfService(data.termsOfService)
        })();
    }, []);

    return (
        <>
            <CardHeader title='Реєстрація' />
            <CardContent>
                <Typography
                    height={332}
                    overflow='scroll'
                    dangerouslySetInnerHTML={{ __html: termsOfService }}
                />
            </CardContent>
            <CardActions sx={{ justifyContent: 'space-between' }}>
                <Button
                    onClick={() => {
                        setStep(Steps.verificationCode);
                        analytics().sendEvent(AnalyticsEvents.registration_terms_of_services_go_back);
                    }}
                    size='large'
                    variant='contained'
                    color='inherit'>
                    Назад
                </Button>
                <Box>
                    <Button
                        onClick={() => {
                            setStep(Steps.verificationCode);
                            analytics().sendEvent(AnalyticsEvents.registration_terms_of_services_reject);
                        }}
                        size='large'
                        variant='contained'
                        color='inherit'>
                        Не погоджуюся
                    </Button>
                    <Button
                        onClick={() => {
                            setStep(Steps.uploadDocument);
                            analytics().sendEvent(AnalyticsEvents.registration_terms_of_services_accept);
                        }}
                        sx={{ ml: 2 }}
                        size='large'
                        variant='contained'
                    >
                        Погоджуюся
                    </Button>
                </Box>
            </CardActions>
        </>
    );
};

export default Terms;
