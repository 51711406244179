import React, { FC, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import { FormInput, InputEvent } from './index';
import { getFormattedMobileNumber, getNumbersFormString } from 'shared/functions';

import { TextInputProps } from './types';

const FormPhoneInput: FC<TextInputProps>= (props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { setValue } = useFormContext();

  const onChange = ({ target }: InputEvent) => {
    let value = target.value;
    const input = inputRef.current;
    if (input && input.selectionEnd && input.selectionEnd < 5) {
      const focusedValue = value[input.selectionEnd - 1]
      const validatedValue = value.replace(focusedValue, '');

      value = `${validatedValue}${focusedValue}`;
    }
    const filteredValue = getNumbersFormString(value).substring(0, 12);
    setValue(props.name, `+${getFormattedMobileNumber(filteredValue)}`);
  };

  return (
    <FormInput
      defaultValue='+380'
      type='tel'
      inputRef={inputRef}
      rules={{
        minLength: {
          value: 17,
          message: ''
        },
        onChange,
      }}
      { ...props}
    />
  )
};

export default FormPhoneInput;
